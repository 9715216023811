
import React from 'react'
import '../../../../App.css'
function Configurationdata() {
  return (
    <div className="ConfigurationProgramming">
  
    <h4 style ={{ color: "black", textAlign: "center" }}>  Configuration Programming</h4>
    <p style={{fontSize: "medium", textAlign: "justify" }}>In this orbit, the user can configure the system and architectural components such as      programming language, persistence mechanism, UI libraries, etc.
</p>
    <img style={{ "height": "400px","width":"500px"}} src={require('../../../../assets/images/Config1.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 4 Orbit :</p>
<img style={{ "height": "300px","width":"400px"}} src={require('../../../../assets/images/Config2.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>The invention is an online platform that automatically creates software in 7 steps.

</p><p style={{fontSize: "medium", textAlign: "left" }}>Level 4 Spin:</p>
<img style={{ "height": "200px","width":"850px"}} src={require('../../../../assets/images/Config3.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>A seven step automation across all stages of software development such as requirements gathering, systems specifications, standardization, concept design, modelling system & component architecture, coding & integration, quality assurance, build, deploy, operate, maintenance, enhancement and further redeployment, operations and maintenance.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Software requirements are usually provided in document formats such as scope of work (SOW) or Request For Proposal (RFP) or Business Requirements Specification (BRS) or Requirements document etc.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
In the first step, the aiscipro online platform User can upload any of the above-mentioned documents or provide requirements inputs directly by typing or entering the features into the aiscipro system .
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The document is processed with an artificial intelligence based natural language processor. In this step the identification of required software features is automated. The identified features are then categorized into four groups based on the complexity & effort needed for development of the features.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the second configuration the user can select and configure the system parameters such as programming language, database operating system and any other desired tools and frame works .
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the third step system level software architecture and code level detailed design could be customized on the automatically generated system design.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the customized design is resubmitted then, based on the features, an estimate is provided to the aiscipro platform user. </p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A total cost estimate is presented to the User based on the number of features and the complexity. The User can also view the detailed break up of the costs if so desired.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The User is offered to go ahead with automatic building (development or creation) of software or to drop out at this stage.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The fourth step is initiated when the User opts to go ahead and build the software. In this step, based on the features required, software is automatically designed, architected, built automatically with systems approach, domain driven design, normalized systems concepts, model driven development & automatic programming tools.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the software is built, a preview is provided to the User to check if all the features as expected by the User are available in the software created automatically on the platform.
Fifth step to preview the auto generated software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The sixth step is provided to the user, in case the user wants any changes or customizations required, of the developed code and which will also be provisioned to the user for manual change.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The seventh step is initiated to deploy on opting to download the software. In this step the User is redirected to the payments gateway page and on successful payment the user is provided with the software (including the source code, design document, architecture, user manual, installation guide, quality assurance reports, etc.). 
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The platform may also offer the user to host the auto-built software online at additional charge.
Users are further provided an option to drop out at this stage by discarding the software or pay for the software and download the software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Thus in 7 steps a user can have a software developed for their requirements.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
    </div>
  )
}

export default Configurationdata;