export function PricingSummary() {
    const boxStyle = {
      border: '1px solid #ccc',
      width: '100%',
      maxWidth: '1110px',
      margin: '0 auto',
      boxShadow: '-1px 2px 2px 2px grey',
      overflowX: 'auto',
    };
  
    const tableStyle = {
      width: '100%',
      borderCollapse: 'collapse',
    };
  
    const cellStyle = {
      borderRight: '1px solid black',
      padding: '8px',
      textAlign: 'center',
    };
  
    return (
      <div className="title-pages">
        <h3>Pricing Summary</h3>
        <div style={boxStyle}>
          <table style={tableStyle}>
            <tr>
              <td style={{ ...cellStyle, width: '10px', backgroundColor: 'lightsalmon' }}>S.No</td>
              <td style={{ ...cellStyle, width: '30px', backgroundColor: 'lightgreen' }}><b>Service</b></td>
              <td style={{ ...cellStyle, width: '45px', backgroundColor: 'lightblue' }}><b>Deliverables</b></td>
              <td style={{ ...cellStyle, width: '10px', backgroundColor: 'lightseagreen' }}><b>Starter</b></td>
              <td style={{ ...cellStyle, width: '10px', backgroundColor: 'lightslategray' }}><b>Basic</b></td>
              <td style={{ ...cellStyle, width: '10px', backgroundColor: 'lightcoral' }}><b>Standard</b></td>
              <td style={{ ...cellStyle, width: '10px', backgroundColor: 'lavender' }}><b>Premium</b></td>
              <td style={{ ...cellStyle, width: '20px', backgroundColor: 'orange' }}><b>Enterprise</b></td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>1</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Analysis</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>Feature Categorization</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>2</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Modelling</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>Specifications</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>3</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Architecture</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>Architecture document</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>4</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Generation</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>Unit Test Report</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>5</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Hosting</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>-</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>6</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Assurance</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>Performance Test Reports</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>7</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Documentation</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>All Mentioned Documents</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>8</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Deployment</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>Super Admin Login</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>9</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Support</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>User Manual</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>10</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>OEM Softwares</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>Api documentation</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
            <tr>
              <td style={{ ...cellStyle, backgroundColor: 'lightsalmon' }}>11</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightgreen' }}>Management</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightblue' }}>User data storage</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightseagreen' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightslategray' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lightcoral' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'lavender' }}>Subscription</td>
              <td style={{ ...cellStyle, backgroundColor: 'orange' }}>Subscription</td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
  