
import React from 'react'
import '../../../../App.css'
function Cicd() {
  return (
    <div className=" CICDProgramming">
   
    <h4 style ={{ color: "black", textAlign: "center" }}> CI CD Programming</h4>
    <p style={{fontSize: "medium", textAlign: "justify" }}>This level provides for cloud configuration, integration with external systems, and deployment for operations.
</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 6 Orbit .:</p>
    <img style={{ "height": "400px","width":"500px"}} src={require('../../../../assets/images/Visuallowimage.jpg')}class="center" alt="..."/>
    <p style={{fontSize: "medium", textAlign: "left" }}>Level 6 Spin:</p>
<img style={{ "height": "300px","width":"350px"}} src={require('../../../../assets/images/Visuallow2.png')}class="center" alt="..."/>



<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
    </div>
  )
}

export default Cicd