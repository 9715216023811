



import React, { useRef, useEffect } from 'react';
import { useLocation, Routes, Route} from 'react-router-dom';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


import Home from './views/Home';
import Certifications from './components/sections/Certifications';
import Testimonial from './components/sections/Testimonial';
import Clients from './components/sections/Clients';
import Onedata from './components/layout/headerLink/Usecase/One';
import Orbital from './components/layout/headerLink/Usecase/Orbital';
import Star from './components/layout/headerLink/Usecase/Star';
import Industry from './components/layout/headerLink/Industry';
import Timeless from './components/layout/headerLink/Usecase/Timeless';
import AutomaticProgramming from './components/layout/headerLink/Usecase/AutomaticProgramming';
import AssistedautomaticProgramming from './components/layout/headerLink/Usecase/AssistedautomaticProgramming';
import VisualNocodeProgramming from './components/layout/headerLink/Usecase/VisualNocodeProgramming';
import VisualLowcodeProgramming from './components/layout/headerLink/Usecase/VisualLowcodeProgramming';
import Cicd from './components/layout/headerLink/Usecase/Cicd';
import Configurationdata from './components/layout/headerLink/Usecase/Configuration';
import SchoolmanagementSystem from './components/layout/headerLink/Industry/SchoolmanagementSystem';
import LibrarymanagementSystem from './components/layout/headerLink/Industry/LibrarymanagementSystem';
import AdmissionManagementSystem from './components/layout/headerLink/Industry/AdmissionManagementSystem';
import LearningManagementSystem from './components/layout/headerLink/Industry/LearningManagementSystem';
import OnlineExaminationSystem from './components/layout/headerLink/Industry/OnlineExaminationSystem';
import OnlineSurveySystem from './components/layout/headerLink/Industry/OnlineSurveySystem';
import Parentcommunication from './components/layout/headerLink/Industry/Parentcommunication';
import CustomerRelationshipManagementSystem from './components/layout/headerLink/Industry/CustomerRelationshipManagementSystem';
import MarketingAutomationSystem from './components/layout/headerLink/Industry/MarketingAutomationSystem';
import BusinessIntelligenceSystem from './components/layout/headerLink/Industry/BusinessIntelligenceSystem';
import CustomerManagementSystem from './components/layout/headerLink/Industry/CustomerManagementSystem';
import PaymentsInvoicingSystem from './components/layout/headerLink/Industry/PaymentsInvoicingSystem';
import LoanManagementSystem from './components/layout/headerLink/Industry/LoanManagementSystem';
import InvestmentManagementSystem from './components/layout/headerLink/Industry/InvestmentManagementSystem';
import BudgetingTrackingManagementSystem from './components/layout/headerLink/Industry/BudgetingTrackingManagementSystem';
import FinancialReportingSystem from './components/layout/headerLink/Industry/FinancialReportingSystem';
import StockTradingManagementSystem from './components/layout/headerLink/Industry/StockTradingManagementSystem';
import ATMMonitoringSystem from './components/layout/headerLink/Industry/ATMMonitoringSystem';
import AssetTrackingSystem from './components/layout/headerLink/Industry/AssetTrackingSystem';
import FileTrackingSystem from './components/layout/headerLink/Industry/FileTrackingSystem';
import EmployeeTrackingSystem from './components/layout/headerLink/Industry/EmployeeTrackingSystem';
import AccessControlSystem from './components/layout/headerLink/Industry/AccessControlSystem';
import FinancialManagementSystem from './components/layout/headerLink/Industry/FinancialManagementSystem';
import MedicalBillingSystem from './components/layout/headerLink/Industry/MedicalBillingSystem';
import ElectronicMedicalRecordsSystem from './components/layout/headerLink/Industry/ElectronicMedicalRecordsSystem';
import PracticeManagementSystem from './components/layout/headerLink/Industry/PracticeManagementSystem';
import AppointmentSystem from './components/layout/headerLink/Industry/AppointmentSystem';
import CalenderSchedulingSystem from './components/layout/headerLink/Industry/CalenderSchedulingSystem';
import PatientEngagementSystem from './components/layout/headerLink/Industry/PatientEngagementSystem';
import ImagingandVisualizationSystem from './components/layout/headerLink/Industry/ImagingandVisualizationSystem';
import InventoryTrackingSystem from './components/layout/headerLink/Industry/InventoryTrackingSystem';
import SupplierManagementSystem from './components/layout/headerLink/Industry/SupplierManagementSystem';
import OrganizationManagementSystem from './components/layout/headerLink/Industry/OrganizationManagementSystem';
import SupplyChainManagementSystem from './components/layout/headerLink/Industry/SupplyChainManagementSystem';
import WarehouseDispatchManagementSystem from './components/layout/headerLink/Industry/WarehouseDispatchManagementSystem';
import FleetManagementSystem from './components/layout/headerLink/Industry/FleetManagementSystem';
import OrderManagementSystem from './components/layout/headerLink/Industry/OrderManagementSystem';
import FieldServiceManagementSystem from './components/layout/headerLink/Industry/FieldServiceManagementSystem';
import DocumentationManagementSystem from './components/layout/headerLink/Industry/DocumentationManagementSystem';
import DistributionManagementSoftware from './components/layout/headerLink/Industry/DistributionManagementSoftware';
import GPSFleetTrackingSystem from './components/layout/headerLink/Industry/GPSFleetTrackingSystem';
import ContainerTrackingSystem from './components/layout/headerLink/Industry/ContainerTrackingSystem';
import YardManagementSystem from './components/layout/headerLink/Industry/YardManagementSystem';
import Containercargofleetmanagement from './components/layout/headerLink/Industry/Containercargofleetmanagement';
import DockManagementSystem from './components/layout/headerLink/Industry/DockManagementSystem';
import FastagbasedParkingMangementSystem from './components/layout/headerLink/Industry/FastagbasedParkingMangementSystem';
import AccessControlSystemtoemployees from './components/layout/headerLink/Industry/AccessControlSystemtoemployees';
import TimeAttendanceSystemtoemployees from './components/layout/headerLink/Industry/TimeAttendanceSystemtoemployees';
import WagonTrackingSystem from './components/layout/headerLink/Industry/WagonTrackingSystem';
import TrainTrackingSystem from './components/layout/headerLink/Industry/TrainTrackingSystem';
import ParkingManagementSystems from './components/layout/headerLink/Industry/ParkingManagementSystems';
import CanteenManagementSystems from './components/layout/headerLink/Industry/CanteenManagementSystems';
import OFCWirelessNetworking from './components/layout/headerLink/Industry/OFCWirelessNetworking';
import ParkingManagementSystemss from './components/layout/headerLink/Industry/ParkingManagementSystemss';
import TimeAttendanceSystemtoemployees1 from './components/layout/headerLink/Industry/TimeAttendanceSystemtoemployees1';
import Blogs from './components/layout/headerLink/Resources/Blogs';
import { Blog1 } from './components/layout/headerLink/Resources/BlogsDetails/blog1';
import Blog2 from './components/layout/headerLink/Resources/BlogsDetails/blog2';
import Blog3 from './components/layout/headerLink/Resources/BlogsDetails/blog3';
import Blog4 from './components/layout/headerLink/Resources/BlogsDetails/blog4';
import Blog5 from './components/layout/headerLink/Resources/BlogsDetails/blog5';
import Blog6 from './components/layout/headerLink/Resources/BlogsDetails/blog6';
import Blog15 from './components/layout/headerLink/Resources/BlogsDetails/blog15';
import Blog14 from './components/layout/headerLink/Resources/BlogsDetails/blog14';
import Blog13 from './components/layout/headerLink/Resources/BlogsDetails/blog13';
import Blog12 from './components/layout/headerLink/Resources/BlogsDetails/blog12';
import Blog11 from './components/layout/headerLink/Resources/BlogsDetails/blog11';
import Blog10 from './components/layout/headerLink/Resources/BlogsDetails/blog10';
import Blog9 from './components/layout/headerLink/Resources/BlogsDetails/blog9';
import Blog8 from './components/layout/headerLink/Resources/BlogsDetails/blog8';
import Blog7 from './components/layout/headerLink/Resources/BlogsDetails/blog7';
import Casestudies from './components/layout/headerLink/Resources/Casestudies';
import Downloads from './components/layout/headerLink/Resources/Downloads';
import FAQ from './components/layout/headerLink/Resources/FAQ';
import Articles from './components/layout/headerLink/Resources/Articles';
import Accordion from './components/layout/headerLink/Resources/Accordion';
import Videos from './components/layout/headerLink/Resources/Videos';
import Whitepapers from './components/layout/headerLink/Resources/Whitepapers';
import Pricing from './components/layout/headerLink/Pricing';
import AboutUs from './components/layout/partials/footerLink/AboutUs';
import Contact from './components/layout/partials/footerLink/Contact';
import Privacy from './components/layout/partials/footerLink/Privacy';
import Support from './components/layout/partials/footerLink/Support';
import Termsofuse from './components/layout/partials/footerLink/Termsofuse';
import { PricingSummary } from './components/layout/headerLink/Pricing/PricingSummary';
import { PricingOffers } from './components/layout/headerLink/Pricing/Pricingoffers';
import { FeaturePricing } from './components/layout/headerLink/Pricing/FeaturePricing';
import Hero from './components/sections/Hero';
import Header from './components/layout/Header';






// const TRACKING_ID = "UA-253567111-1";
// ReactGA.initialize(TRACKING_ID);

// const trackPage = (page) => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

// const App = () => {
//   const childRef = useRef();
//   let location = useLocation();
//   useEffect(() => {
//     const page = location.pathname;
//     document.body.classList.add('is-loaded');
    
//     // Check if childRef.current is defined before accessing init()
//     if (childRef.current && typeof childRef.current.init === 'function') {
//         childRef.current.init();
//     }

//     trackPage(page);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [location]);

//   useEffect(() => {
//     ReactGA.pageview(window.location.pathname + window.location.search);
//   }, []);

//   return (
//     <div>
//      {/* <ScrollReveal ref={childRef} children={() => (
//         <Routes>
//           <Route
//             path="/"
//             element={<LayoutDefault><Home /></LayoutDefault>}
            

//           />
//           <Route path='/Clients' element={<LayoutDefault><Clients/></LayoutDefault>}></Route>
//           <Route
//             path="/Certifications"
//             element={<LayoutDefault><Certifications /></LayoutDefault>}
//           />
//           <Route
//             path="/Testimonial"
//             element={<LayoutDefault><Testimonial /></LayoutDefault>}
//           />
//           <Route path='/Orbital' element={<LayoutDefault><Orbital/></LayoutDefault>}/>
//        <Route path='/One' element={<LayoutDefault><Onedata/></LayoutDefault>}/>


//        <Route path='/Star' element={<LayoutDefault><Star/></LayoutDefault>}/>
//        <Route path='/Industry' element={<LayoutDefault><Industry/></LayoutDefault>}/>
//        <Route path='/Timeless' element={<LayoutDefault><Timeless/></LayoutDefault>}/>
//        <Route path='/AutomaticProgramming' element={<LayoutDefault><AutomaticProgramming/></LayoutDefault>}/>



//        <Route path='/AssistedautomaticProgramming' element={<LayoutDefault><AssistedautomaticProgramming/></LayoutDefault>}/>
//        <Route path='/VisualNocodeProgramming' element={<LayoutDefault><VisualNocodeProgramming/></LayoutDefault>}/>
//        <Route path='/VisualLowcodeProgramming' element={<LayoutDefault><VisualLowcodeProgramming/></LayoutDefault>}/>
//        <Route path='configuration' element={<LayoutDefault><Configurationdata/></LayoutDefault>}/>

//       <Route path='/Cicd' element={<LayoutDefault><Cicd/></LayoutDefault>}/>
// <Route path='/SchoolmanagementSystem' element={<LayoutDefault><SchoolmanagementSystem/></LayoutDefault>}/>
// <Route path='/LibrarymanagementSystem' element={<LayoutDefault><LibrarymanagementSystem/></LayoutDefault>}/>
// <Route path='/AdmissionManagementSystem' element={<LayoutDefault><AdmissionManagementSystem/></LayoutDefault>}/>
// <Route path='/LearningManagementSystem' element={<LayoutDefault><LearningManagementSystem/></LayoutDefault>}/>
// <Route path='/OnlineExaminationSystem' element={<LayoutDefault><OnlineExaminationSystem/></LayoutDefault>}/>
// <Route path='/5' element={<LayoutDefault><OnlineSurveySystem/></LayoutDefault>}/>
// <Route path='/Parentcommunication'element={<LayoutDefault><Parentcommunication/></LayoutDefault>}/>
// <Route path='/CustomerRelationshipManagementSystem' element={<LayoutDefault><CustomerRelationshipManagementSystem/></LayoutDefault>}/>
// <Route path='/MarketingAutomationSystem' element={<LayoutDefault><MarketingAutomationSystem/></LayoutDefault>}/>
// <Route path='/BusinessIntelligenceSystem' element={<LayoutDefault><BusinessIntelligenceSystem/></LayoutDefault>}/>
// <Route path='/CustomerManagementSystem' element={<LayoutDefault><CustomerManagementSystem/></LayoutDefault>}/>
// <Route path='/Payments&InvoicingSystem' element={<LayoutDefault><PaymentsInvoicingSystem/></LayoutDefault>}/>
// <Route path='/LoanManagementSystem' element={<LayoutDefault><LoanManagementSystem/></LayoutDefault>}/>
// <Route path='/InvestmentManagementSystem' element={<LayoutDefault><InvestmentManagementSystem/></LayoutDefault>}/>
// <Route path='/BudgetingTrackingManagementSystem' element={<LayoutDefault><BudgetingTrackingManagementSystem/></LayoutDefault>}/>
// <Route path='/FinancialReportingSystem' element={<LayoutDefault><FinancialReportingSystem/></LayoutDefault>}/>
// <Route path='/StockTradingManagementSystem' element={<LayoutDefault><StockTradingManagementSystem/></LayoutDefault>}/>
// <Route path='/ATMMonitoringSystem' element={<LayoutDefault><ATMMonitoringSystem/></LayoutDefault>}/>
// <Route path='/AssetTrackingSystem' element={<LayoutDefault><AssetTrackingSystem/></LayoutDefault>}/>
// <Route path='/FileTrackingSystem' element={<LayoutDefault><FileTrackingSystem/></LayoutDefault>}/>
// <Route path='/EmployeeTrackingSystem' element={<LayoutDefault><EmployeeTrackingSystem/></LayoutDefault>}/>
// <Route path='/AccessControlSystem' element={<LayoutDefault><AccessControlSystem/></LayoutDefault>}/>
// <Route path='/MedicalBillingSystem' element={<LayoutDefault><MedicalBillingSystem/></LayoutDefault>}/>
// <Route path='/ElectronicMedicalRecordsSystem' element={<LayoutDefault><ElectronicMedicalRecordsSystem/></LayoutDefault>}/>
// <Route path='/PracticeManagementSystem' element={<LayoutDefault><PracticeManagementSystem/></LayoutDefault>}/>
// <Route path='/AppointmentSystem' element={<LayoutDefault><AppointmentSystem/></LayoutDefault>}/>
// <Route path='/CalenderSchedulingSystem' element={<LayoutDefault><CalenderSchedulingSystem/></LayoutDefault>}/>
// <Route path='/PatientEngagementSystem' element={<LayoutDefault><PatientEngagementSystem/></LayoutDefault>}/>
// <Route path='/ImagingandVisualizationSystem' element={<LayoutDefault><ImagingandVisualizationSystem/></LayoutDefault>}/>
// <Route path='/InventoryTrackingSystem' element={<LayoutDefault><InventoryTrackingSystem/></LayoutDefault>}/>
// <Route path='/SupplyChainManagementSystem' element={<LayoutDefault><SupplyChainManagementSystem/></LayoutDefault>}/>

// <Route path='/OrganizationManagementSystem' element={<LayoutDefault><OrganizationManagementSystem/></LayoutDefault>}/>
// <Route path='/SupplierManagementSystem' element={<LayoutDefault><SupplierManagementSystem/></LayoutDefault>}/>

// <Route path='/WarehouseDispatchManagementSystem' element={<LayoutDefault><WarehouseDispatchManagementSystem/></LayoutDefault>}/>
// <Route path='/FleetManagementSystem' element={<LayoutDefault><FleetManagementSystem/></LayoutDefault>}/>
// <Route path='/OrderManagementSystem' element={<LayoutDefault><OrderManagementSystem/></LayoutDefault>}/>
// <Route path='/FieldServiceManagementSystem' element={<LayoutDefault><FieldServiceManagementSystem/></LayoutDefault>}/>
// <Route path='/DocumentationManagementSystem' element={<LayoutDefault><DocumentationManagementSystem/></LayoutDefault>}/>
// <Route path='/DistributionManagementSoftware' element={<LayoutDefault><DistributionManagementSoftware/></LayoutDefault>}/>
// <Route path='/GPSFleetTrackingSystem' element={<LayoutDefault><GPSFleetTrackingSystem/></LayoutDefault>}/>
// <Route path='/ContainerTrackingSystem' element={<LayoutDefault><ContainerTrackingSystem/></LayoutDefault>}/>
// <Route path='/YardManagementSystem' element={<LayoutDefault><YardManagementSystem/></LayoutDefault>}/>
// <Route path='/Containercargofleetmanagement' element={<LayoutDefault><Containercargofleetmanagement/></LayoutDefault>}/>
// <Route path='/DockManagementSystem' element={<LayoutDefault><DockManagementSystem/></LayoutDefault>}/>
// <Route path='/FastagbasedParkingMangementSystem' element={<LayoutDefault><FastagbasedParkingMangementSystem/></LayoutDefault>}/>
// <Route path='/AccessControlSystemtoemployees' element={<LayoutDefault><AccessControlSystemtoemployees/></LayoutDefault>}/>
// <Route path='/TimeAttendanceSystemtoemployees' element={<LayoutDefault><TimeAttendanceSystemtoemployees/></LayoutDefault>}/>
// <Route path='/WagonTrackingSystem' element={<LayoutDefault><WagonTrackingSystem/></LayoutDefault>}/>
// <Route path='/TrainTrackingSystem' element={<LayoutDefault><TrainTrackingSystem/></LayoutDefault>}/>
// <Route path='/ParkingManagementSystems' element={<LayoutDefault><ParkingManagementSystems/></LayoutDefault>}/>
// <Route path='/CanteenManagementSystems' element={<LayoutDefault><CanteenManagementSystems/></LayoutDefault>}/>
// <Route path='/OFCWirelessNetworking' element={<LayoutDefault><OFCWirelessNetworking/></LayoutDefault>}/>
// <Route path='/ParkingManagementSystemss' element={<LayoutDefault><ParkingManagementSystemss/></LayoutDefault>}/>
// <Route path='/TimeAttendanceSystemtoemployees1' element={<LayoutDefault><TimeAttendanceSystemtoemployees1/></LayoutDefault>}/>
// <Route path='/Blogs' element={<LayoutDefault><Blogs/></LayoutDefault>}/>
// <Route path='/blog1' element={<LayoutDefault><Blog1/></LayoutDefault>}/>
// <Route path='/blog2' element={<LayoutDefault><Blog2/></LayoutDefault>}/>
// <Route path='/blog3' element={<LayoutDefault><Blog3/></LayoutDefault>}/>
// <Route path='/blog4' element={<LayoutDefault><Blog4/></LayoutDefault>}/>
// <Route path='/blog5' element={<LayoutDefault><Blog5/></LayoutDefault>}/>
// <Route path='/blog6' element={<LayoutDefault><Blog6/></LayoutDefault>}/>
// <Route path='/blog7' element={<LayoutDefault><Blog7/></LayoutDefault>}/>
// <Route path='/blog8' element={<LayoutDefault><Blog8/></LayoutDefault>}/>
// <Route path='/blog9' element={<LayoutDefault><Blog9/></LayoutDefault>}/>
// <Route path='/blog10' element={<LayoutDefault><Blog10/></LayoutDefault>}/>
// <Route path='/blog11' element={<LayoutDefault><Blog11/></LayoutDefault>}/>
// <Route path='/blog12' element={<LayoutDefault><Blog12/></LayoutDefault>}/>
// <Route path='/blog13' element={<LayoutDefault><Blog13/></LayoutDefault>}/>
// <Route path='/blog14' element={<LayoutDefault><Blog14/></LayoutDefault>}/>
// <Route path='/blog15' element={<LayoutDefault><Blog15/></LayoutDefault>}/>
// <Route path='/Casestudies' element={<LayoutDefault><Casestudies/></LayoutDefault>}/>
// <Route path='/Downloads' element={<LayoutDefault><Downloads/></LayoutDefault>}/>
// <Route path='/FAQ' element={<LayoutDefault><FAQ/></LayoutDefault>}/>
// <Route path='/Articles' element={<LayoutDefault><Articles/></LayoutDefault>}/>
// <Route path='/Accordion' element={<LayoutDefault><Accordion/></LayoutDefault>}/>
// <Route path='/Videos' element={<LayoutDefault><Videos/></LayoutDefault>}/>
// <Route path='/Whitepapers' element={<LayoutDefault><Whitepapers/></LayoutDefault>}/>
// <Route path='/Pricing' element={<LayoutDefault><Pricing/></LayoutDefault>}/>
// <Route path='/aboutus' element={<LayoutDefault><AboutUs/></LayoutDefault>}/>
// <Route path='/contact' element={<LayoutDefault><Contact/></LayoutDefault>}/>
// <Route path='/privacy' element={<LayoutDefault><Privacy/></LayoutDefault>}/>
// <Route path='/support' element={<LayoutDefault><Support/></LayoutDefault>}/>
// <Route path='/termsofuse' element={<LayoutDefault><Termsofuse/></LayoutDefault>}/>
//       <Route path='/PricingSummary' element={<LayoutDefault><PricingSummary/></LayoutDefault>}/>
//       <Route path='/PricingOffers' element={<LayoutDefault><PricingOffers/></LayoutDefault>}/>
//       <Route path='/FeaturePricing' element={<LayoutDefault><FeaturePricing/></LayoutDefault>}/>
//         </Routes>
//       )} /> */}
    
//     <Routes>
//           <Route
//             path="/"
//             element={<Home />}
            

//           />
        
//          <Route path='/Certifications' element={<Certifications/>}/>
//          <Route path='/Clients ' element={<Clients/>}/>

//           <Route
//             path="/Certifications"
//             element={<Certifications />}
//           />
//            <Route path='/Clients' element={<Clients/>}></Route>
//           <Route
//             path="/Testimonial"
//             element={<Testimonial />}
//           />
//           <Route path='/Orbital' element={<Orbital/>}/>
//        <Route path='/One' element={<Onedata/>}/>


//        <Route path='/Star' element={<Star/>}/>
//        <Route path='/Industry' element={<Industry/>}/>
//        <Route path='/Timeless' element={<Timeless/>}/>
//        <Route path='/AutomaticProgramming' element={<AutomaticProgramming/>}/>



//        <Route path='/AssistedautomaticProgramming' element={<AssistedautomaticProgramming/>}/>
//        <Route path='/VisualNocodeProgramming' element={<VisualNocodeProgramming/>}/>
//        <Route path='/VisualLowcodeProgramming' element={<VisualLowcodeProgramming/>}/>
//        <Route path='configuration' element={<Configurationdata/>}/>

//       <Route path='/Cicd' element={<Cicd/>}/>
// <Route path='/SchoolmanagementSystem' element={<SchoolmanagementSystem/>}/>
// <Route path='/LibrarymanagementSystem' element={<LibrarymanagementSystem/>}/>
// <Route path='/AdmissionManagementSystem' element={<AdmissionManagementSystem/>}/>
// <Route path='/LearningManagementSystem' element={<LearningManagementSystem/>}/>
// <Route path='/OnlineExaminationSystem' element={<OnlineExaminationSystem/>}/>
// <Route path='/5' element={<OnlineSurveySystem/>}/>
// <Route path='/Parentcommunication'element={<Parentcommunication/>}/>
// <Route path='/CustomerRelationshipManagementSystem' element={<CustomerRelationshipManagementSystem/>}/>
// <Route path='/MarketingAutomationSystem' element={<MarketingAutomationSystem/>}/>
// <Route path='/BusinessIntelligenceSystem' element={<BusinessIntelligenceSystem/>}/>
// <Route path='/CustomerManagementSystem' element={<CustomerManagementSystem/>}/>
// <Route path='/Payments&InvoicingSystem' element={<PaymentsInvoicingSystem/>}/>
// <Route path='/LoanManagementSystem' element={<LoanManagementSystem/>}/>
// <Route path='/InvestmentManagementSystem' element={<InvestmentManagementSystem/>}/>
// <Route path='/BudgetingTrackingManagementSystem' element={<BudgetingTrackingManagementSystem/>}/>
// <Route path='/FinancialReportingSystem' element={<FinancialReportingSystem/>}/>
// <Route path='/StockTradingManagementSystem' element={<StockTradingManagementSystem/>}/>
// <Route path='/ATMMonitoringSystem' element={<ATMMonitoringSystem/>}/>
// <Route path='/AssetTrackingSystem' element={<AssetTrackingSystem/>}/>
// <Route path='/FileTrackingSystem' element={<FileTrackingSystem/>}/>
// <Route path='/EmployeeTrackingSystem' element={<EmployeeTrackingSystem/>}/>
// <Route path='/AccessControlSystem' element={<AccessControlSystem/>}/>
// <Route path='/MedicalBillingSystem' element={<MedicalBillingSystem/>}/>
// <Route path='/ElectronicMedicalRecordsSystem' element={<ElectronicMedicalRecordsSystem/>}/>
// <Route path='/PracticeManagementSystem' element={<PracticeManagementSystem/>}/>
// <Route path='/AppointmentSystem' element={<AppointmentSystem/>}/>
// <Route path='/CalenderSchedulingSystem' element={<CalenderSchedulingSystem/>}/>
// <Route path='/PatientEngagementSystem' element={<PatientEngagementSystem/>}/>
// <Route path='/ImagingandVisualizationSystem' element={<ImagingandVisualizationSystem/>}/>
// <Route path='/InventoryTrackingSystem' element={<InventoryTrackingSystem/>}/>
// <Route path='/SupplyChainManagementSystem' element={<SupplyChainManagementSystem/>}/>

// <Route path='/OrganizationManagementSystem' element={<OrganizationManagementSystem/>}/>
// <Route path='/SupplierManagementSystem' element={<SupplierManagementSystem/>}/>

// <Route path='/WarehouseDispatchManagementSystem' element={<WarehouseDispatchManagementSystem/>}/>
// <Route path='/FleetManagementSystem' element={<FleetManagementSystem/>}/>
// <Route path='/OrderManagementSystem' element={<OrderManagementSystem/>}/>
// <Route path='/FieldServiceManagementSystem' element={<FieldServiceManagementSystem/>}/>
// <Route path='/DocumentationManagementSystem' element={<DocumentationManagementSystem/>}/>
// <Route path='/DistributionManagementSoftware' element={<DistributionManagementSoftware/>}/>
// <Route path='/GPSFleetTrackingSystem' element={<GPSFleetTrackingSystem/>}/>
// <Route path='/ContainerTrackingSystem' element={<ContainerTrackingSystem/>}/>
// <Route path='/YardManagementSystem' element={<YardManagementSystem/>}/>
// <Route path='/Containercargofleetmanagement' element={<Containercargofleetmanagement/>}/>
// <Route path='/DockManagementSystem' element={<DockManagementSystem/>}/>
// <Route path='/FastagbasedParkingMangementSystem' element={<FastagbasedParkingMangementSystem/>}/>
// <Route path='/AccessControlSystemtoemployees' element={<AccessControlSystemtoemployees/>}/>
// <Route path='/TimeAttendanceSystemtoemployees' element={<TimeAttendanceSystemtoemployees/>}/>
// <Route path='/WagonTrackingSystem' element={<WagonTrackingSystem/>}/>
// <Route path='/TrainTrackingSystem' element={<TrainTrackingSystem/>}/>
// <Route path='/ParkingManagementSystems' element={<ParkingManagementSystems/>}/>
// <Route path='/CanteenManagementSystems' element={<CanteenManagementSystems/>}/>
// <Route path='/OFCWirelessNetworking' element={<OFCWirelessNetworking/>}/>
// <Route path='/ParkingManagementSystemss' element={<ParkingManagementSystemss/>}/>
// <Route path='/TimeAttendanceSystemtoemployees1' element={<TimeAttendanceSystemtoemployees1/>}/>
// <Route path='/Blogs' element={<Blogs/>}/>
// <Route path='/blog1' element={<Blog1/>}/>
// <Route path='/blog2' element={<Blog2/>}/>
// <Route path='/blog3' element={<Blog3/>}/>
// <Route path='/blog4' element={<Blog4/>}/>
// <Route path='/blog5' element={<Blog5/>}/>
// <Route path='/blog6' element={<Blog6/>}/>
// <Route path='/blog7' element={<Blog7/>}/>
// <Route path='/blog8' element={<Blog8/>}/>
// <Route path='/blog9' element={<Blog9/>}/>
// <Route path='/blog10' element={<Blog10/>}/>
// <Route path='/blog11' element={<Blog11/>}/>
// <Route path='/blog12' element={<Blog12/>}/>

// <Route path='/blog13' element={<Blog13/>}/>
// <Route path='/blog14' element={<Blog14/>}/>
// <Route path='/blog15' element={<Blog15/>}/>
// <Route path='/Casestudies' element={<Casestudies/>}/>
// <Route path='/Downloads' element={<Downloads/>}/>
// <Route path='/FAQ' element={<LayoutDefault><FAQ/></LayoutDefault>}/>
// <Route path='/Articles' element={<Articles/>}/>
// <Route path='/Accordion' element={<Accordion/>}/>
// <Route path='/Videos' element={<Videos/>}/>
// <Route path='/Whitepapers' element={<Whitepapers/>}/>
// <Route path='/Pricing' element={<Pricing/>}/>
// <Route path='/aboutus' element={<AboutUs/>}/>
// <Route path='/contact' element={<Contact/>}/>
// <Route path='/privacy' element={<Privacy/>}/>
// <Route path='/support' element={<Support/>}/>
// <Route path='/termsofuse' element={<Termsofuse/>}/>
//       <Route path='/PricingSummary' element={<PricingSummary/>}/>
//       <Route path='/PricingOffers' element={<PricingOffers/>}/>
//       <Route path='/FeaturePricing' element={<FeaturePricing/>}/>
//         </Routes>
//     </div>
//   );
// };

// export default App;




const TRACKING_ID = "UA-253567111-1";
ReactGA.initialize(TRACKING_ID);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App=()=>{




  const childRef = useRef();
  let location = useLocation();
  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded');
    
    // Check if childRef.current is defined before accessing init()
    if (childRef.current && typeof childRef.current.init === 'function') {
        childRef.current.init();
    }

    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [location]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return(
    <>
     <Header/>
    <ScrollReveal ref={childRef} children={() => (
      
       
   <Routes>
   
    <Route path='/' element={<Home/>}/>
    <Route path='/Certifications' element={<Certifications/>}/>
      <Route path='/Clients ' element={<Clients/>}/>
   <Route path='/aisciprosection' element={<Hero/>}/>
         <Route path="/Certification" element={<Certifications/>}/>
           <Route path='/Clients' element={<Clients/>}></Route>
           <Route
             path="/Testimonial"
            element={<Testimonial />}
          />
          <Route path='/Orbital' element={<Orbital/>}/>
          <Route path='/One' element={<Onedata/>}/>
          
       <Route path='/Star' element={<Star/>}/>
       <Route path='/Industry' element={<Industry/>}/>
       <Route path='/Timeless' element={<Timeless/>}/>
       <Route path='/AutomaticProgramming' element={<AutomaticProgramming/>}/>

        <Route path='/FinancialReportingSystem' element={<FinancialReportingSystem/>}/>
        <Route path='/Casestudies' element={<Casestudies/>}/>
 <Route path='/Downloads' element={<Downloads/>}/>
 <Route path='/FAQ' element={<FAQ/>}/>
            <Route path='/AssistedautomaticProgramming' element={<AssistedautomaticProgramming/>}/>
       <Route path='/VisualNocodeProgramming' element={<VisualNocodeProgramming/>}/>
        <Route path='/VisualLowcodeProgramming' element={<VisualLowcodeProgramming/>}/>
        <Route path='configuration' element={<Configurationdata/>}/>

      <Route path='/Cicd' element={<Cicd/>}/>
 <Route path='/SchoolmanagementSystem' element={<SchoolmanagementSystem/>}/>

       <Route path='/AssistedautomaticProgramming' element={<AssistedautomaticProgramming/>}/>
       <Route path='/VisualNocodeProgramming' element={<VisualNocodeProgramming />} />
<Route path='/VisualLowcodeProgramming' element={<VisualLowcodeProgramming />} />
<Route path='/configuration' element={<Configurationdata />} />
<Route path='/Cicd' element={<Cicd />} />
<Route path='/FinancialmanagementSystem' element={<FinancialManagementSystem/>}/>
<Route path='/SchoolmanagementSystem' element={<SchoolmanagementSystem />} />
<Route path='/LibrarymanagementSystem' element={<LibrarymanagementSystem />} />
<Route path='/AdmissionManagementSystem' element={<AdmissionManagementSystem />} />
<Route path='/LearningManagementSystem' element={<LearningManagementSystem />} />
<Route path='/OnlineExaminationSystem' element={<OnlineExaminationSystem />} />
<Route path='/5' element={<OnlineSurveySystem />} />
<Route path='/Parentcommunication' element={<Parentcommunication />} />
<Route path='/CustomerRelationshipManagementSystem' element={<CustomerRelationshipManagementSystem />} />
<Route path='/MarketingAutomationSystem' element={<MarketingAutomationSystem />} />
<Route path='/BusinessIntelligenceSystem' element={<BusinessIntelligenceSystem />} />
<Route path='/CustomerManagementSystem' element={<CustomerManagementSystem />} />
<Route path='/Payments&InvoicingSystem' element={<PaymentsInvoicingSystem />} />
<Route path='/LoanManagementSystem' element={<LoanManagementSystem />} />
<Route path='/InvestmentManagementSystem' element={<InvestmentManagementSystem />} />
<Route path='/BudgetingTrackingManagementSystem' element={<BudgetingTrackingManagementSystem />} />
<Route path='/FinancialReportingSystem' element={<FinancialReportingSystem />} />
<Route path='/StockTradingManagementSystem' element={<StockTradingManagementSystem />} />
<Route path='/ATMMonitoringSystem' element={<ATMMonitoringSystem />} />
<Route path='/AssetTrackingSystem' element={<AssetTrackingSystem />} />
<Route path='/FileTrackingSystem' element={<FileTrackingSystem />} />
<Route path='/EmployeeTrackingSystem' element={<EmployeeTrackingSystem />} />
<Route path='/AccessControlSystem' element={<AccessControlSystem />} />
<Route path='/MedicalBillingSystem' element={<MedicalBillingSystem />} />
<Route path='/ElectronicMedicalRecordsSystem' element={<ElectronicMedicalRecordsSystem />} />
<Route path='/PracticeManagementSystem' element={<PracticeManagementSystem />} />
<Route path='/AppointmentSystem' element={<AppointmentSystem />} />
<Route path='/CalenderSchedulingSystem' element={<CalenderSchedulingSystem />} />
<Route path='/PatientEngagementSystem' element={<PatientEngagementSystem />} />
<Route path='/ImagingandVisualizationSystem' element={<ImagingandVisualizationSystem />} />
<Route path='/InventoryTrackingSystem' element={<InventoryTrackingSystem />} />
<Route path='/SupplyChainManagementSystem' element={<SupplyChainManagementSystem />} />

<Route path='/OrganizationManagementSystem' element={<OrganizationManagementSystem />} />
<Route path='/SupplierManagementSystem' element={<SupplierManagementSystem />} />

<Route path='/WarehouseDispatchManagementSystem' element={<WarehouseDispatchManagementSystem />} />
<Route path='/FleetManagementSystem' element={<FleetManagementSystem />} />
<Route path='/OrderManagementSystem' element={<OrderManagementSystem />} />
<Route path='/FieldServiceManagementSystem' element={<FieldServiceManagementSystem />} />
<Route path='/DocumentationManagementSystem' element={<DocumentationManagementSystem />} />
<Route path='/DistributionManagementSoftware' element={<DistributionManagementSoftware />} />
<Route path='/GPSFleetTrackingSystem' element={<GPSFleetTrackingSystem />} />
<Route path='/ContainerTrackingSystem' element={<ContainerTrackingSystem />} />
<Route path='/YardManagementSystem' element={<YardManagementSystem />} />
<Route path='/Containercargofleetmanagement' element={<Containercargofleetmanagement />} />
<Route path='/DockManagementSystem' element={<DockManagementSystem />} />
<Route path='/FastagbasedParkingMangementSystem' element={<FastagbasedParkingMangementSystem />} />
<Route path='/AccessControlSystemtoemployees' element={<AccessControlSystemtoemployees />} />
<Route path='/TimeAttendanceSystemtoemployees' element={<TimeAttendanceSystemtoemployees />} />
<Route path='/WagonTrackingSystem' element={<WagonTrackingSystem />} />
<Route path='/TrainTrackingSystem' element={<TrainTrackingSystem />} />
<Route path='/ParkingManagementSystems' element={<ParkingManagementSystems />} />
<Route path='/CanteenManagementSystems' element={<CanteenManagementSystems />} />
<Route path='/OFCWirelessNetworking' element={<OFCWirelessNetworking />} />
<Route path='/ParkingManagementSystemss' element={<ParkingManagementSystemss />} />
<Route path='/TimeAttendanceSystemtoemployees1' element={<TimeAttendanceSystemtoemployees1 />} />
 <Route path='/Articles' element={<Articles/>}/>
 <Route path='/Accordion' element={<Accordion/>}/>
<Route path='/Videos' element={<Videos/>}/>
 <Route path='/Whitepapers' element={<Whitepapers/>}/>
<Route path='/Pricing' element={<Pricing/>}/>
 <Route path='/aboutus' element={<AboutUs/>}/>
 <Route path='/contact' element={<Contact/>}/>
 <Route path='/privacy' element={<Privacy/>}/>
 <Route path='/support' element={<Support/>}/>
 <Route path='/termsofuse' element={<Termsofuse/>}/>
 <Route path='/EmployeeTrackingSystem' element={<EmployeeTrackingSystem/>}/>
<Route path='/AccessControlSystem' element={<AccessControlSystem/>}/>
<Route path='/MedicalBillingSystem' element={<MedicalBillingSystem/>}/>
<Route path='/ElectronicMedicalRecordsSystem' element={<ElectronicMedicalRecordsSystem/>}/>
<Route path='/PracticeManagementSystem' element={<PracticeManagementSystem/>}/>
<Route path='/AppointmentSystem' element={<AppointmentSystem/>}/>
<Route path='/CalenderSchedulingSystem' element={<CalenderSchedulingSystem/>}/>
<Route path='/PatientEngagementSystem' element={<PatientEngagementSystem/>}/>
<Route path='/ImagingandVisualizationSystem' element={<ImagingandVisualizationSystem/>}/>
<Route path='/InventoryTrackingSystem' element={<InventoryTrackingSystem/>}/>
<Route path='/SupplyChainManagementSystem' element={<SupplyChainManagementSystem/>}/>

<Route path='/OrganizationManagementSystem' element={<OrganizationManagementSystem/>}/>
<Route path='/SupplierManagementSystem' element={<SupplierManagementSystem/>}/>

<Route path='/WarehouseDispatchManagementSystem' element={<WarehouseDispatchManagementSystem/>}/>
<Route path='/FleetManagementSystem' element={<FleetManagementSystem/>}/>
<Route path='/OrderManagementSystem' element={<OrderManagementSystem/>}/>
<Route path='/FieldServiceManagementSystem' element={<FieldServiceManagementSystem/>}/>
<Route path='/DocumentationManagementSystem' element={<DocumentationManagementSystem/>}/>
<Route path='/DistributionManagementSoftware' element={<DistributionManagementSoftware/>}/>
<Route path='/GPSFleetTrackingSystem' element={<GPSFleetTrackingSystem/>}/>
<Route path='/ContainerTrackingSystem' element={<ContainerTrackingSystem/>}/>
<Route path='/YardManagementSystem' element={<YardManagementSystem/>}/>
<Route path='/Containercargofleetmanagement' element={<Containercargofleetmanagement/>}/>
<Route path='/DockManagementSystem' element={<DockManagementSystem/>}/>
<Route path='/FastagbasedParkingMangementSystem' element={<FastagbasedParkingMangementSystem/>}/>
<Route path='/AccessControlSystemtoemployees' element={<AccessControlSystemtoemployees/>}/>
<Route path='/TimeAttendanceSystemtoemployees' element={<TimeAttendanceSystemtoemployees/>}/>
<Route path='/WagonTrackingSystem' element={<WagonTrackingSystem/>}/>
<Route path='/TrainTrackingSystem' element={<TrainTrackingSystem/>}/>
<Route path='/ParkingManagementSystems' element={<ParkingManagementSystems/>}/>
<Route path='/CanteenManagementSystems' element={<CanteenManagementSystems/>}/>
<Route path='/OFCWirelessNetworking' element={<OFCWirelessNetworking/>}/>
<Route path='/ParkingManagementSystemss' element={<ParkingManagementSystemss/>}/>
<Route path='/TimeAttendanceSystemtoemployees1' element={<TimeAttendanceSystemtoemployees1/>}/>
<Route path='/Blogs' element={<Blogs/>}/>
<Route path='/blog1' element={<Blog1/>}/>
<Route path='/blog2' element={<Blog2/>}/>
<Route path='/blog3' element={<Blog3/>}/>
<Route path='/blog4' element={<Blog4/>}/>
<Route path='/blog5' element={<Blog5/>}/>
<Route path='/blog6' element={<Blog6/>}/>
<Route path='/blog7' element={<Blog7/>}/>
<Route path='/blog8' element={<Blog8/>}/>
<Route path='/blog9' element={<Blog9/>}/>
<Route path='/blog10' element={<Blog10/>}/>
<Route path='/blog11' element={<Blog11/>}/>
<Route path='/blog12' element={<Blog12/>}/>
<Route path='/blog14' element={<Blog14/>}/>
<Route path='/blog15' element={<Blog15/>}/>
<Route path='/blog13' element={<Blog13/>}/>
      <Route path='/PricingSummary' element={<PricingSummary/>}/>
      <Route path='/PricingOffers' element={<PricingOffers/>}/> 
            <Route path='/FeaturePricing' element={<FeaturePricing/>}/> 
   </Routes>

  )} />
    </>
  )
}
export default App;