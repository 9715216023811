import React from 'react';
//import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
//import SectionHeader from './partials/SectionHeader';
import testi1 from '../../../src/assets/images/testi1.jfif'
//import testi2 from '../../assets/images/CCL.jpg'
import testi3 from '../../assets/images/Testi3.jpg'
//import testi4 from '../../assets/images/SMPK.jpg'


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  // const outerClasses = classNames(
  //   'testimonial section',
  //   topOuterDivider && 'has-top-divider',
  //   bottomOuterDivider && 'has-bottom-divider',
  //   hasBgColor && 'has-bg-color',
  //   invertColor && 'invert-color',
  //   className
  // );

  // const innerClasses = classNames(
  //   'testimonial-inner section-inner',
  //   topDivider && 'has-top-divider',
  //   bottomDivider && 'has-bottom-divider'
  // );

  // const tilesClasses = classNames(
  //   'tiles-wrap',
  //   pushLeft && 'push-left'
  // );

  // const sectionHeader = {
  //   title: 'Customer testimonials',
  //   paragraph: 'Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.'
  // };

  return (
//     <section
//       {...props}
//       className={outerClasses}
//     >
//       <div className="container">
//         <div className={innerClasses}>
//           <SectionHeader data={sectionHeader} className="center-content" />
//           <div className={tilesClasses}>

         
// <div id="carouselMultiItemExample" className="carousel slide carousel-dark text-center" data-mdb-ride="carousel">
 
//   <div className="d-flex justify-content-center mb-4">
//     <button className="carousel-control-prev position-relative" type="button"
//       data-mdb-target="#carouselMultiItemExample" data-mdb-slide="prev">
//       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//       <span className="visually-hidden">Previous</span>
//     </button>
//     <button className="carousel-control-next position-relative" type="button"
//       data-mdb-target="#carouselMultiItemExample" data-mdb-slide="next">
//       <span className="carousel-control-next-icon" aria-hidden="true"></span>
//       <span className="visually-hidden">Next</span>
//     </button>
//   </div>
 
//   <div className="carousel-inner py-4">
   
//     <div className="carousel-item active">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-4">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">Anna Deynah</h5>
//             <p>UX Designer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id
//               officiis hic tenetur quae quaerat ad velit ab hic tenetur.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//             <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//             </ul>
//           </div>

//           <div className="col-lg-4 d-none d-lg-block">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">John Doe</h5>
//             <p>Web Developer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
//               suscipit laboriosam, nisi ut aliquid commodi.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li>
//                 <i className="fas fa-star-half-alt fa-sm"></i>
//               </li>
//             </ul>
//           </div>

//           <div className="col-lg-4 d-none d-lg-block">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">Maria Kate</h5>
//             <p>Photographer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
//               praesentium voluptatum deleniti atque corrupti.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="far fa-star fa-sm"></i></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>

  
//     <div className="carousel-item">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-4">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">John Doe</h5>
//             <p>UX Designer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id
//               officiis hic tenetur quae quaerat ad velit ab hic tenetur.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//             <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="far fa-star fa-sm"></i></li>
//             </ul>
//           </div>

//           <div className="col-lg-4 d-none d-lg-block">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(4).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">Alex Rey</h5>
//             <p>Web Developer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
//               suscipit laboriosam, nisi ut aliquid commodi.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//             <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="far fa-star fa-sm"></i></li>
//             </ul>
//           </div>

//           <div className="col-lg-4 d-none d-lg-block">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(5).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">Maria Kate</h5>
//             <p>Photographer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
//               praesentium voluptatum deleniti atque corrupti.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//             <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="far fa-star fa-sm"></i></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>

  
//     <div className="carousel-item">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-4">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">Anna Deynah</h5>
//             <p>UX Designer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id
//               officiis hic tenetur quae quaerat ad velit ab hic tenetur.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//             <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="far fa-star fa-sm"></i></li>
//             </ul>
//           </div>

//           <div className="col-lg-4 d-none d-lg-block">
//             {/* <img class="rounded-circle shadow-1-strong mb-4"
//               src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(8).webp" alt="avatar"
//               style="width: 150px;" /> */}
//             <h5 className="mb-3">John Doe</h5>
//             <p>Web Developer</p>
//             <p className="text-muted">
//               <i className="fas fa-quote-left pe-2"></i>
//               Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
//               suscipit laboriosam, nisi ut aliquid commodi.
//             </p>
//             <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
//             <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="fas fa-star fa-sm"></i></li>
//               <li><i className="far fa-star fa-sm"></i></li>
//             </ul>
//           </div>
// <i class="fa-solid fa-chevron-left"></i>
//<i class="fa-solid fa-chevron-right"></i>
//<a class="left carousel-control" href="#testimonial4" role="button" data-slide="prev">
  
//<span class="left carousel-control-icon"></span>
//</a>
//<a class="right carousel-control" href="#testimonial4" role="button" data-slide="next">

//<span class="right carousel-control-icon"></span>
//</a>
<div id="testimonial">

<div id="testimonial4" class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x" data-ride="carousel" data-pause="hover" data-interval="5000" data-duration="2000">
  <div class="testimonial4_header">
    <h4 style ={{ color:'black'}}>Testimonials</h4>
  </div>
  <ol class="carousel-indicators">
    <li data-target="#testimonial4" data-slide-to="0" class="active"></li>
    <li data-target="#testimonial4" data-slide-to="1"></li>
    {/* <li data-target="#testimonial4" data-slide-to="2"></li> */}
    {/* <li data-target="#testimonial4" data-slide-to="3"></li> */}
  </ol>
  <div class="carousel-inner" role="listbox">
    <div class="carousel-item active">
      <div class="testimonial4_slide">
     
      <img src={testi1} style={{width:"150px",height:"150px"}} class="img-circle img-responsive" alt='' />
        
        
        <p>"aiscipro, the AI led automatic programming, is the future of software development industry"</p>
        
        <h5 style ={{ color:'black'}}>-Vishwajeet Singh Thakur, CEO, Deepelement.ai</h5>
      </div>
    </div>
    {/* <div class="carousel-item">
      <div class="testimonial4_slide">
      
        
        <img src={testi2} class="img-circle img-responsive" />
        <p>As aiscipro gradually delivers on its vision of automatic software programming, solution design, coding, and maintenance will be increasingly outdated. It is very exciting to be part of this disruptive journey!"</p>
        <h5 style ={{ color:'black'}}> - Ram Prasad, Delivery Lead, Harsco Corporation.</h5>
      </div>
    </div> */}
    <div class="carousel-item">
      <div class="testimonial4_slide">
        <img src={testi3} style={{width:"150px",height:"150px"}} alt='' class="img-circle img-responsive" />
        <p>aiscipro is a great usecase of how AI is revolutionizing the world like never before. By enabling every industry to re-imagine application development it is utilising AI in one of the best possible ways.

</p>
        <h5 style ={{ color:'black'}}>Pankaj Singh, Founder, Smartwealth.ai</h5>
      </div>
    </div>
    {/* <div class="carousel-item">
      <div class="testimonial4_slide">
        <img src={testi4} class="img-circle img-responsive" />
        <p>Lorem ipsum dolor sit amet adipiscing elit am nibh unc varius facilisis eros ed erat in in velit quis arcu ornare laoreet urabitur.</p>
        <h4>Ben Hanna</h4>
      </div>
    </div> */}
  </div>
  <a   style={{backgroundColor: "black"}} class="carousel-control-prev" href="#testimonial4" role="button" data-slide="prev">
            <span style={{color:"black"}} class="carousel-control-prev-icon"></span>
            <span  class="sr-only">Previous</span>
        </a>

        <a  style={{backgroundColor: "black"}}  class="carousel-control-next" href="#testimonial4" role="button" data-slide="next">
            <span   class="carousel-control-next-icon" ></span>
            <span  class="sr-only">Next</span>
        </a>
</div>
</div>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;