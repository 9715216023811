 
import React from 'react'
import '../../../../App.css'
import Accordion from './Accordion';
import { accordionData } from './content'
import { useState,useEffect} from 'react';
import ReactGA from 'react-ga';

import './FAQs.css'
import './FAQs.scss'

import ReactPaginate from "react-paginate";
 

 
const FAQ = () => {
  const [data, setData] = useState(accordionData);
  const[searchTerm,setSearchTerm]=useState('');
  const [pageNumber, setPageNumber] = useState(0);
//pageview google analytics interactive

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  },[]);
//.end of pageview google analytics
  const postsPerPage = 8;
  const pagesVisited = pageNumber * postsPerPage;
  const pageCount = Math.ceil(
    data.filter((val) => {
      if (searchTerm === "") {
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      }
      return false;
    }).length / postsPerPage
  );
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };


  
 
 
 
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h4 style={{textAlign:"center", color:"black"}}>FAQs</h4>
      <form class="example" action="">
      <p style={{ color: "blue", textAlign: "left",float:"right" }}>
        {/* <input type="text" placeholder="search"  onChange={event=>{setSearchTerm(event.target.value)}} /> */}
        <input
            type="search"
            className="search"
            placeholder="Search"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handlePageChange({ selected: 0 });
            }}
          />
        </p>&nbsp;
      </form>
      <br></br>
      <br></br>
     
      <div>
      {data.filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (
                  val.title.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return val;
                }
                return false;
              }).slice(pagesVisited, pagesVisited + postsPerPage)
                .map((e) => {
                  return (
                    <>
 
<div className="accordion" >
     
          <Accordion title={e.title} content={e.content} />
     
      </div>
          </>
        )
       })}
     
 
</div>
 
      <br></br>
     
      <ReactPaginate
                previousLabel={"«"}
                nextLabel={"»"}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"} />
     
    </div>
 
  );
        };
       
     
export default FAQ;
 
 


 



