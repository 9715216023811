import React from 'react'

function Blog14() {
  return (
    <div className='blog'>

<p style ={{ color: "blue", textAlign: "center" }}> Why is Agile Methodology whipping the horse harder?</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Agile methodology has delivered more effectively than other models of software development
process however at substantial efficiency cost.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>At aiscipro, we think that agile software development has run its due course. 8 out of 10
business stakeholders are unhappy with the additional burden of regular sprints apart from their
regular job. 7 out of 10 software developers are frustrated with the agile way of sweating out
prototype-like features every other day. “Agile burnout” is a popular industry term today.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>To begin with, Agile manifesto was drafted by a group of seventeen software industry veterans
frustrated by the documentation driven heavyweight software development that, majority of the
time, does not deliver to the explicit as well as expected requirements.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>However, unfortunately, the 12 principles that Agile Manifesto puts forth for ideal software
deliveries, that meets customer expectations, misses the forest for the trees.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>It only makes software development more effective in the most inefficient way by demanding
that every stakeholder get involved in each feature of software development indefinitely.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Further, Agile methodology does not stipulate any kind of software development automation.
Agile methodology based software development does not make the development process any
more efficient than other methodologies.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Moreover, Agile does not guarantee timely delivery of software. In fact, it may create delays
such as, participation by all stakeholders for each requirement under implementation which in
practice causes much delay to get everyone on board for every meeting.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Firstly, we feel calling Agile Manifesto as principles is a misnomer. The 12 principles are actually
a wishlist of frustrated experienced IT veterans unhappy with their jobs as the deliveries are not
meeting the requirements or expectations of the clients.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>We will justify our above claim by listing each principle and stating our point of view on the
same.</p>

<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog14table1.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'200px'}} src={require('../Blogimages/blog14table2.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'250px'}} src={require('../Blogimages/blog14table3.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog14table4.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'180px'}} src={require('../Blogimages/blog14table5.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog14table6.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog14table7.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'190px'}} src={require('../Blogimages/blog14table8.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog14table9.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog14table10.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'280px'}} src={require('../Blogimages/blog14table11.png')}class="center" alt="..."/><br></br>
<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog14table12.png')}class="center" alt="..."/><br></br>



<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}>What we understand is Agile principles are trying to</p>
<ul>
    <li><p style={{fontSize: "medium", textAlign: "left" }}>create an approach where the software development process is visible to all
stakeholders</p></li>
<li><p style={{fontSize: "medium", textAlign: "left" }}>take information &amp; agreement of all on each feature under implementation</p></li>
</ul>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>

    </div>
  )
}

export default Blog14