import React from 'react'
import "../../../../../App.css"
function Blog8() {
  return (
    <div className='blog8'>   
      
      <p style ={{ color: "blue", textAlign: "center" }}> Reducing code complexity by separating Cross Cutting Concerns(CCC) </p>
       <p style={{fontSize: "medium", textAlign: "left" }}>What are cross cutting concerns (CCC)?</p>
       <p style={{fontSize: "medium", textAlign: "justify" }}>  Aspects of a program that are not focused on the functionality or primary objective of the program are called cross cutting concerns. They impact other concerns/aspects of a program and cut across the program wherever the primary functionality is coded.</p>
       <p style={{fontSize: "medium", textAlign: "justify" }}>  
For example, logging code is a concern that is different from functional code of a program. Similarly, exception handling is separate from domain logic which is the primary objective of the software.
</p>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog8image.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Example 1: Sum.program
</p>

  <pre style={{fontSize: "medium", textAlign: "justify",color:"black",backgroundColor:"white"}}>
Sum (a, b) &#123;<br></br>
			logger.info(“Value of a & b are:”, a,b);<br></br>
				C = a+b<br></br>
			logger.info(“Value of C is:”, C);<br></br>
				return C<br></br>
        &#125;
        </pre>


        <p style={{fontSize: "medium", textAlign: "left" }}>Sum.program without CCC</p>
<pre style={{fontSize: "medium", textAlign: "justify",color:"black",backgroundColor:"white"}}>

Sum (a, b) &#123;<br></br>
				C = a+b<br></br>
				return C<br></br>
        &#125;<br></br>


  </pre>


  <p style={{fontSize: "medium", textAlign: "left" }}> Example 2: Division.program</p>

  <pre style={{fontSize: "medium", textAlign: "justify",color:"black",backgroundColor:"white"}}> 
  Divide (a, b)  &#123;<br></br>
			try  &#123;<br></br>
				C = a/b<br></br>
        &#125; catch(DivisionbyZeroException e)	 &#123; logger.error(“Division by Zero”, e)  &#125; <br></br>
Return C<br></br>
&#125;
</pre>
<p style={{fontSize: "medium", textAlign: "left" }}> Division.program without CCC</p>
<pre style={{fontSize: "medium", textAlign: "justify",color:"black",backgroundColor:"white"}}> 
Divide (a, b) &#123; <br></br>
				C = a/b <br></br>
				return C <br></br>
        &#125; <br></br>

</pre>
<p style={{fontSize: "medium", textAlign: "left" }}> Example 3: APICall.program</p>
<pre style={{fontSize: "medium", textAlign: "justify",color:"black",backgroundColor:"white"}}> 
APIcall (api) &#123; <br></br>

time_start = time();<br></br>
async swagger.get(api);<br></br>
time_end = time(); <br></br>
write(performance_tracer.file,“Starttime”,time_start(),”Endtime”,time_end());<br></br>
&#125; <br></br>
</pre>

<p style={{fontSize: "medium", textAlign: "left" }}>APICall.program without CCC</p>
<pre style={{fontSize: "medium", textAlign: "justify",color:"black",backgroundColor:"white"}}>

		
		APIcall (api) <br></br>
     &#123; <br></br>
swagger.get(api);<br></br>
&#125;
</pre>
<p style={{fontSize: "medium", textAlign: "left" }}>2.Why are they called cross cutting concerns?</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>  These concerns cut across multiple layers in the architecture, vertically as well as horizontally. For instance, logging or exception handling is required at business logic, application logic, infrastructure, presentation, model, and even at view layer.

</p>
<img style={{ "height": "250px","width":"335px"}} src={require('../Blogimages/blog8image.png')}class="center" alt="..."/>

<p style={{fontSize: "medium", textAlign: "left" }}>3.What are the examples of cross cutting concerns? </p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Software code is highly complex today as many of the concerns which are cross cutting in nature i.e. are CCCs are all coded in one place. The following program parts are all CCC.
</p>


<img style={{ "height": "300px","width":"565px"}} src={require('../Blogimages/blog8image2.png')}class="center" alt="..."/>


<p style={{fontSize: "medium", textAlign: "left" }}>4. Why are CCCs an issue?</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Cross cutting concerns implementation along with functional logic leads to code entanglement and code repetition. For instance, logging code gets entangled with business logic. Logging code needs to be repeated across multiple locations of the program.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
	Cross cutting concerns violate Single Responsibility Principle (SRP) principle of SOLID design principles. Further, it violates Don't Repeat Yourself (DRY) principle of clean coding.
  </p>
  <p style={{fontSize: "medium", textAlign: "left" }}>5. How to implement CCC without SRP & DRY violation?</p>
  <p style={{fontSize: "medium", textAlign: "justify" }}>Aspect Oriented Programming (AOP) paradigm was developed to overcome SRP & DRY violations by CCC. However, there are other ways to overcome CCC as well including
Interception and Dynamic Proxy <br></br>
Decorator Pattern<br></br>
Annotations-Attributes & IoC<br></br>
Filters<br></br>
Proxy Classes<br></br>
Following C# libraries/frameworks support CCC separation<br></br>
Microsoft Enterprise Library - Unity Interception<br></br>
Autofac<br></br>
Spring.Net <br></br>
PostSharp,<br></br>
KingAOP, <br></br>
AspectInjector<br></br>
NConcern<br></br>
etc<br></br>
</p>


      
    </div>
   
  )
}
export default Blog8
