import { faBold } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Collapse } from 'react-bootstrap'
import "../../../../../App.css"


function Blog5() {
  return (
<>
<div className="blog5">

<p style ={{ color: "blue", textAlign: "center" }}>Value Objects vs Composite Attributes vs Attribute Set</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Attributes of an Entity can be either simple or composite.
Simple attribute of an Employee entity is Name. It is a single field with a single data type such
as a string.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Similarly, date of birth (8 character string) or address (text box) when these attributes are
defined as a single unit with single data type.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>However the same attributes could be designed in composite form such as
</p>
<p style={{fontSize: "medium", textAlign: "justify",fontWeight: 'bold'  }}>Name</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
&#8226; &nbsp;First Name<br></br>
&#8226; &nbsp;Middle Name<br></br>
&#8226; &nbsp;Last Name</p>
<p style={{fontSize: "medium", textAlign: "justify",fontWeight: 'bold'  }}>Date of Birth</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
&#8226; &nbsp;Day<br></br>
&#8226; &nbsp;Month<br></br>
&#8226; &nbsp;Year</p>


<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog5final.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify", fontWeight: 'bold' }}>Address</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
            &#8226; &nbsp;House<br></br>
            &#8226; &nbsp;Street<br></br>
            &#8226; &nbsp;City<br></br>
            &#8226; &nbsp;State</p>
          
<p style={{fontSize: "medium", textAlign: "justify" }}>It is a group of simple attributes that acts as one set.

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Value Objects are the same as composite attributes of an Entity.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>When an Entity has an attribute which in turn has multiple fields then it is defined as a Value
Object.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Creating composite attributes not only provides higher normalisation but also enables them to
identify &amp; act on them as a single unified set.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Value Object attribute sets are also more convenient to reuse compared to when the attributes
are separate and independent.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>For instance, an Employee entity may have 3 different attributes of First Name, Middle Name,
and Last Name or one Value Object attribute called Name (with First Name, Middle Name, and
Last Name).

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The latter value object/attribute set is reusable compared to the former.
Value Object = Composite Attribute = Attribute Set</p>

<p style={{fontSize: "medium", textAlign: "justify" }}>
Value Object = Composite Attribute = Attribute Set</p>
</div>
</>

)
  
}

  


export default Blog5
