import React from 'react'
import GDPR from '../../assets/images/certifications/GDPR Logo.png'
import ISO27001 from '../../assets/images/certifications/ISO 27001.png'
import SOC2 from '../../assets/images/certifications/SOC 2.png'
import iso9001 from '../../assets/images/certifications/iso9001.png'

const Certifications = () => {
  return (
    <div>

      <br></br>
      <br></br>


      <div id="certification" class="container" >
        <div>
          <center><h3 style={{ color: 'black' }}>Certifications</h3></center>
        </div>
        <br></br>
        <div id="certifications" class="certifications">

          <div class="row no-gutters clients-wrap clearfix">

            <div class="col-lg-3 col-md-4 col-6">
              <div class="ct-logo">
                <img src={GDPR} class="img-fluid" alt="" />
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <div class="ct-logo">
                <img src={ISO27001} class="img-fluid" alt="" />
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <div class="ct-logo">
                <img src={SOC2} class="img-fluid" alt="" />
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-6">
              <div class="ct-logo">
                <img src={iso9001} class="img-fluid" alt="" />
              </div>
            </div>

          </div>
        </div>
      </div>
      <br></br>

      <div>
        <center><h3 style={{ color: 'black' }}>Awards and Recognitions</h3></center>
        <br></br>
        <br></br>
      </div>
    </div>
  )
}

export default Certifications
