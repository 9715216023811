import React from 'react'
import "../../../../../App.css"


function Blog6() {
  return (
<>
<div className="blog6">

<p style ={{ color: "blue", textAlign: "center" }}>OBJECT view vs AGGREGATE view</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Part of Object Oriented Design (OOD) vs Domain Driven Design (DDD) series.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Unlike OOD where the world is seen from the point of view of objects and their interactions,
DDD-Aggregate takes one step beyond to recognise the fact that all objects are not at the same
level or grade.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>DDD-Aggregate takes into account the fact that some objects are more closely bound to each
other than to others.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Further, among these closely bonded objects one of them has a primary role to play while the
rest play a secondary and more subdued role.</p>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog6imagefinal.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>Moreover, other than in the context of this primary root object, the sub objects do not have any
meaningful role to play in the system.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>For instance, if we consider a Family aggregate with sub entities Members. Unless Family
Aggregate exists, Members individually are out of context and not meaningful.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Similarly, if we consider the oft cited Order and OrderItem example, OrderItem exists in the
context of Order only.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>OrderItem, if treated on its own individually without the Order Aggregate, could lead to
corruption of the business logic or inconsistent behaviour of the software.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Therefore, unlike OOD view of the world which is in terms of Object, DDD views in terms of
Aggregates.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>So it is, Object entities vs Object aggregate world.</p>

</div>

</>
    )
  
}

  


export default Blog6
