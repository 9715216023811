

import React from 'react'
import '../../../../App.css'
function Timeless() {
  return (
    <div className="Timeless"  >
      <h4 style ={{ color: "black", textAlign: "center" }}> Timeless Model</h4>
      <p style={{color:"black",fontSize:"16px",textAlign:"center"}}>Coming Soon....</p>
  <div style={{fontSize:"14.5px"}}>
   <p >
The timeless model is for the automatic development of software. The computer-implemented system includes simultaneous working of each stage. In an embodiment, the computer-implemented system works in real-time.

</p>
<p>The computer-implemented system includes a server for hosting a UI infrastructure on a device such as server, laptop and computer.
</p>
<p >The user is asked to upload input into a requirement document. The user input is processed through artificial intelligence based Natural Language Processing (NLP) to convert into a blueprint of the required software.
</p>
<p >The requirements and the input are categorized into a plurality of feature groups or domain design terms. The requirements are categorized into the plurality of feature groups through an artificial intelligence based Natural Language Processing (NLP) method. In an embodiment, the user may manually re-categorize the plurality of feature groups.
</p>
<p >In an embodiment, the plurality of feature groups is categorized as soon as the user inputs the requirement. In an embodiment, any changes or modifications made in the input will be reflected in the categorization of the plurality of feature groups in real-time.
The software is designed in parallel with the requirement document and feature categorization. In an embodiment, the software is designed in real-time. In an embodiment, any changes or modifications made in the input will be reflected in the software design. In another embodiment, any changes made in the feature categorization will be reflected in the software design.

</p>
<p >A plurality of parameters such as, but not limited to, programming language, persistence mechanism, UI libraries, database, deployment server port and IP address are configured to develop the automated software. In an embodiment, the plurality of parameters is configured in real-time. In an embodiment, any changes or modifications made in the input will be reflected in the configuration. In another embodiment, any changes made in the feature categorization will be reflected in the configuration.
</p>
<p >Any changes made in any of the window will correspondingly update the respective content on the other window. In that sense it is a timeless process as all the changes are getting reflected instantaneously. 

</p>
<p >The user can preview the developing software in real-time. In an embodiment, any changes or modifications made in the input will be reflected in the preview. In another embodiment, any changes made in the feature categorization will be reflected in the preview.

</p>
<p >The system allows the user to view the changes made in each stage at the same time. While the disclosure has been presented with respect to certain specific embodiments, it will be appreciated that many modifications and changes may be made by those skilled in the art without departing from the spirit and scope of the disclosure. It is intended, therefore, by the appended claims to cover all such modifications and changes as fall within the true spirit and scope of the disclosure.
</p>
<p >Any changes to any aspect will correspondingly update aspects.

</p>
<p >Once requirements are uploaded, all stages work simultaneously. It is instantaneous with no concept of time or traversal. Everything happens at the same time. 
</p>
</div>
   <img  src={require('../../../../assets/images/Timelessimage.png')}class="center" alt="..."/>
    </div>
  )
}

export default Timeless