import React from 'react'
import "../../../../../App.css"
function Blog13() {
  return (
    <div className="blog7">
    <div>
    <p style ={{ color: "blue", textAlign: "center" }}>Levels of abstraction in automatic programming</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>Modeling elements in automatic programming needs to be generic enough so as to model all
types of software requirements from varied industries.</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>A high level of abstraction is needed so that the abstract elements are able to capture structure,
function, and behavior models of as many applications as possible in any domain.</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>In aiscipro automatic programming platform, 3 levels of hierarchical abstraction enables
ontology capturing and system modeling of a wide range of software applications.</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>The three levels of abstraction are,<br></br>
1. Root Level (RL) <br></br>
2. Generic Level (GL) <br></br>
3. Specific Level (SL)</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>A single root Element is the highest level of abstraction. The RL abstraction is captured by a
single element which can be derived to give rise to basic elements of any generic modeling
concept.</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>From the root Element, next level abstractions such as Entity, Task, Event, Actor, Activity,
Connector, etc are derived which form the Generic Level abstraction. GL elements can model
form and function of various domains and applications. More than one type of generic level
abstraction can be derived from the root Element.</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>From the Generic Level abstract elements, more domain specific elements, for example,
Employee, Leave, Role, Designation, etc, Specific Level elements are derived. SL elements
models are specific to a domain and application. They capture the terms and terminologies i.e.
the language along with behavior of the domain.</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>Specific Level elements design is the starting model as per the conventional manual software
development process.</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>The final non-abstracted level is the data level which are instances of the Specific Level
elements that modeled the domain. For example, Alan Watts (Employee), Admin (Role),
Vacation (Leave), and so on.</p>
    <p style={{fontSize: "medium", textAlign: "justify" }}>Therefore, there is level 3 abstraction in aiscipro automatic programming.</p>
    </div>
    </div>
  )
}

export default Blog13