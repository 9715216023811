

export  const Blogsjson = [
  // {
  //   "title": "Why is Agile Methodology whipping the horse harder?",
  //   "body": "Agile methodology has delivered more effectively than other models of software development process however at substantial efficiency cost.",
  //   "author": "",
  //   "keywords": "",
  //   "Category":"",
  //   "Month":"July 2022",
  //   "Date":"July 13,2022",
  //   "blognumber":"blog14",
  //   "image":require(""),
  //   "id": 14
  // },
  {
    "title": "Why is the term “architecture” such a confounding word in the software industry?",
    "body": "The “architecture” of a software system is one of the most confounding terms in the software industry. The term architecture evokes an organized diagram of component layout in the mind of most. This image is borrowed from the Civil Engineering field where it has significantly contributed for clarity on structural aspects.",
    "author": "Vara Prasad",
    "keywords": " ",
    "Category":"",
    "Month":"November 2022",
    "Date":"November 13,2022",
    "blognumber":"blog15",
    "image":require('./Blogimages/Image1.jpg'),
    "id": 15
  },
  {
    "title": "Why is Agile Methodology whipping the horse harder?",
    "body": "Agile methodology has delivered more effectively than other models of software development process however at substantial efficiency cost.",
    "author": "Shiva Pandi ",
    "keywords": " ",
    "Category":"Software Engineering",
    "Month":"July 2022",
    "Date":"July 13,2022",
    "blognumber":"blog14",
    "image":require("./Blogimages/blog14im.jpg"),
    "id": 14
  },

  {
    "title": "How is aiscipro platform enabling a true Agile process of software development?",
    "body": "Agile methodology of software development was mainstreamed by the Manifesto of Agile Software Development drafted by a group of seventeen software industry veterans pained by the documentation driven heavyweight software development that, majority of the times, does not deliver to the explicit nor expected requirements.",
    "author": "Sridev Nair ",
    "keywords": "",
    "Category":"Software Engineering",
    "Month":"March 2022",
    "Date":"March 2,2022",
    "blognumber":"blog12",
    "image":require("./Blogimages/blog12image.jpg"),
    "id": 13
  },
  {
    "title": "How the understanding of Process vs Data dichotomy helps in developing optimal software?",
    "body": "One of the root causes for complexity in software is due to Process vs Data dichotomy. It is fundamental to the software world.",
    "author": "Sridev Nair",
    "keywords": "Process vs Data dichotomy,Process dichotomy,Data dichotomy,Software Engineering ",
    "Category":"Software Engineering",
    "Month":"December 2021",
    "Date":"December 22,2021",
    "blognumber":"blog11",
    "image":require("./Blogimages/blogimage11.jpg"),
    "id": 12
  },
    {
        "title": "What are the ways to design Notifications in software?",
        "body": " What is a Notification? By Notifications here we mean information messages sent through alternate channels such as Email, SMS gateway, Whatsapp chat, etc. Such Notifications are information signals that may be of interest to the user of the software or external agent.",
        "author": "Shiva Pandi",
        "keywords": "Notifications,Notifications in software",
        "Category":"",
        "Month":"September 2021",
        "Date":"September 3,2021",
        "blognumber":"/blog9",
        "image":require("./Blogimages/blog9image.png"),
        "id": 1,
       "fullblog":"What are the ways to design Notifications in software?  What is a Notification?/new By Notifications here we mean information messages sent through alternate channels such as Email, SMS gateway, Whatsapp chat, etc. Such Notifications are information signals that may be of interest to the user of the software or external agent./new  These kinds of Notifications go beyond the software generating it and are intended for the software user or a third party person or program. Further, it is through a medium beyond th application generating the message. Thus, the significance of “alternate” channels./new For instance, successful enrollment of an Employee notification may be of interest to the HR Manager and may want to receive an email on it. Arrival to consignment at the destination is of interest for the customer so a notification needs to be sent to customer mobile.Within the software, Notifications are part of the application and therefore will be modeled as an"
      
    
      },
      {
        "title": "Ways to design DDD-Aggregate in Object Oriented Programming (OOP)",
        "body": "From OOP point of view, a DDD-Aggregate is a structural and behavioral design pattern wherein a primary root object owns, contains, and is responsible for other objects under it.",
        "author": "Varaprasad Aripaka",
        "keywords": "DDD, AGGREGATE, OOP, OBJECT ORIENTED PROGRAMMING",
        "Category":"Domain Driven Design (DDD)",
        "Month":"July 2021",
        "Date":"July 7,2021",
        "blognumber":"blog7",
        "image":require('./Blogimages/blog7final.jpg'),
        "id": 2
       
      },
      {
        "title": "OBJECT view vs AGGREGATE view",
        "body": "Part of Object Oriented Design (OOD) vs Domain Driven Design (DDD) series \n Unlike OOD where the world is seen from the point of view of objects and their interactions, DDD-Aggregate takes one step beyond to recognise the fact that all objects are not at the same level or grade.",
        "author": "Farhana Aziz",
        "keywords": "Object view, Aggregate view, Object design, Domain design, Domain Driven Design (DDD), Object Oriented Design (OOD) ",
        "Category":"Software Modeling",
        "Month":"May 2021",
        "Date":"May 7,2021",
        "blognumber":"blog6",
        "image":require('./Blogimages/blog6imagefinal.jpg'),
        "id": 6
      },
      {
        "title": "Value Objects vs Composite Attributes vs Attribute Set",
        "body": "Attributes of an Entity can be either simple or composite. Simple attribute of an Employee entity is Name. It is a single field with a single data type such as a string.",
        "author": "Shanmugha Priya",
        "keywords": "Attributes, Modeling, Attributes of Entity, Value Objects, Composite Attributes, Attribute Set",
        "Category":"Attributes of Entity",
        "Month":"March 2021",
        "Date":"March 18,2021",
        "blognumber":"blog5",
        "image":require('./Blogimages/blog5final.jpg'),
        "id": 3
      },
      {
        "title": "Why are Business Rules not Cross Cutting Concerns?",
        "body": "I have come across many instances of implementation of business rules as a cross cutting concern (CCC) with Aspect Oriented Programming (AOP) frameworks.",
        "author": "Varaprasad Aripaka",
        "keywords": "Aspect, Oriented, Programming, Business, Rules, Business Rules, Aspect Oriented Programming, Cross cutting concern",
        "Category":"Aspect Oriented Programming",
        "Date":"August 10,2020",
        "blognumber":"blog2",
        "Month":"August 2020",
        "image":require('./Blogimages/blog2final.jfif'),
        "id": 4
      },
      {
        "title": "Reducing code complexity by separating Cross Cutting Concerns(CCC)",
        "body": "What are cross cutting concerns (CCC)?Aspects of a program that are not focused on the functionality or primary objective of the program are called cross cutting concerns. They impact other concerns/aspects of a program and cut across the program wherever the primary functionality is coded.",
        "author": "Ayesha Jabeen",
        "keywords": " Cross Cutting Concerns, Aspect Oriented Programming, Code Complexity, Simple Code, Code Entanglement, Code Repetition, SOLID Principles",
        "Category":"Aspect Oriented Programming",
        "Month":"July 2020",
        "Date":"July 17,2020",
        "blognumber":"blog8",
        "image":require('./Blogimages/blog8image.jpg'),
        "id": 5
      },
      
      {
        "title": "Levels of abstraction in automatic programming",
        "body": "Modeling elements in automatic programming needs to be generic enough so as to model all types of software requirements from varied industries.",
        "author": "Sumanth G ",
        "keywords": "Automatic, Programming, Automatic Programming,abstraction,abstract,Root Level,Generic Level,Specific Level",
        "Category":"Automatic Programming",
        "Month":"October 2020",
        "Date":"October 15,2020",
        "blognumber":"blog13",
        "image":require("./Blogimages/BLOG1.jpg"),
        "id": 7
      },
      {
        "title": "Why is ‘Category’ my second most favorite word?",
        "body": "I like the word “category” across different domains at multiple levels within a domain and for various reasons. \n In Marketing, we are trying to create a new “category” of automatic programming that is fundamentally upending the software development process.",
        "author": "Sridev Nair",
        "keywords": "",
        "Category":"",
        "Month":"April 2020",
        "Date":"April 7,2020",
        "blognumber":"blog10",
        "image":require('./Blogimages/blog6imagefinal.jpg'),
        "id": 8
      },
      {
        "title": "Role of an ENTITY in domain modelling in aiscipro platform",
        "body": "An ENTITY in aiscipro is a first class citizen for domain modelling.",
        "author": "Sumanth G",
        "keywords": "Fundamentals, Modelling, Entity, Fundamentals of Modelling",
        "Category":"Software Modeling",
        "Month":"December 2019",
        "Date":"December 30,2019",
        "blognumber":"blog4",
        "image":require("./Blogimages/blog4image.png"),
        "id": 9
      },
      {
        "title": "Why Lowcode-nocode is NOT Automatic Programming?",
        "body": "Lowcode-nocode is creation of software by higher level modeling.",
        "author": "Shiva Pandi",
        "keywords": " High-Level Modelling, Lowcode-Nocode, Automatic Programming",
        "Category":"Automatic Programming",
        "Month":"November 2019",
        "Date":"November 12,2019",
        "blognumber":"blog3",
        "image":require("./Blogimages/blog3imagefinal.jpg"),
        "id": 10
      },
      {
        "title": "Is Automatic Programming ever possible?",
        "body": "If software can be generated by a computer when requirements are provided in human natural language then it is truly automatic programming.",
        "author": "Sridev Nair",
        "keywords": "Automatic, Programming, Automatic Programming ",
        "Category":"Automatic Programming",
        "Month":"October 2019",
        "Date":"October 25,2019",
        "blognumber":"blog1",
        "image":require("./Blogimages/BLOG1.jpg"),
        "id": 11
      }
     
     
 
];