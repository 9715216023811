import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import './Clients.css';
// import { stubTrue } from 'lodash';
//Owl Carousel Settings
// const options = {
//   margin: 30,
//   responsiveClass: true,
//   nav: true,
 
//   navText: ["Prev", "Next"],
//   smartSpeed: 1000,
//   responsive: {
//       0: {
//           items: 1,
//       },
//       400: {
//           items: 1,
//       },
//       600: {
//           items: 2,
//       },
//       700: {
//           items: 3,
//       },
//       1000: {
//           items: 5,
//       }
//   },
// };

const Clients = () => {

  const state= {
    responsive:{
      0: {
        items: 1,
    },
    400: {
        items: 1,
    },
    600: {
        items: 2,
    },
    700: {
        items: 3,
    },
    1000: {
        items: 5,
    }
    },
}
  return (
    <div>

<div>
    <div class="container-fluid">

<div id="clients" >
<center><h3 style ={{ color:'black'}}>Clients & Partners</h3></center>
</div>
<br></br>
<div  id="clients" class="clients">
<div class="row no-gutters clients-wrap clearfix">

{/* <div  id="clients" class="clients">
<div class="row no-gutters clients-wrap clearfix">
<div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/TATA.png')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/WIPRO.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/LandT1.jfif')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Honeywellhd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/SAP.png')} class="img-fluid" alt=""/>
            </div></div>
           

</div>
<div class="row no-gutters clients-wrap clearfix">
            <div class="col"> <div class="client-logo">
              <img style={{ "height": "100px","width":"100px"}}  src={require('./../../assets/images/clients/42_Chowrange.png')}class="img-fluid"  alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Accenture.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Azurehd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/BHEL.png')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/CDAC.png')} class="img-fluid" alt=""/>
            </div></div>

</div>
<div class="row no-gutters clients-wrap clearfix">
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/xerox.jpg')}class="img-fluid"  alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/CISCO.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img  style={{ "height": "180px","width":"150px"}} src={require('./../../assets/images/clients/Punj-Lloydhd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Novartis_logo.png')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/ISB.jpg')} class="img-fluid" alt=""/>
            </div></div>

</div>
<div class="row no-gutters clients-wrap clearfix">
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/HAL.png')}class="img-fluid"  alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Continental.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/HIDhd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img style={{ "height": "180px","width":"150px"}}  src={require('./../../assets/images/clients/trimblehd.svg')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/HP.png')} class="img-fluid" alt=""/>
            </div></div>

</div>

</div>
    </div>
    </div> */}
    
    <OwlCarousel className='owl-theme' loop={true} margin={5} autoplay={true} autoplaySpeed={1000} items={6}
       autoplayHoverPause={false} autoplayTimeout={3000} dots={false} nav={true} responsiveClass={true}   responsive={state.responsive}>
    
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/WIPRO.png')} class="img-fluid" alt=""/>
            </div></div>
    </div>
      <div class='item'>
      <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/LandT1.jfif')}class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col"><div class="client-logo">
              <img src={require('./../../assets/images/clients/xerox.jpg')}class="img-fluid"  alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col">   <div class="client-logo">
              <img style={{"height": "120px","width":"280px"}}  src={require('./../../assets/images/clients/TATAhd.png')}class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class="col">   <div class='item'>
    <div class="client-logo">
              <img style={{"height": "135px","width":"300px"}}  src={require('./../../assets/images/clients/BHELhd.png')}class="img-fluid" alt=""/>
            </div></div>
    </div>
   <div class='item'>
   <div class="col"><div class="client-logo">
              <img src={require('./../../assets/images/clients/SAP_logo.png')} class="img-fluid" alt=""/>
            </div></div>
    </div>
    {/* <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/xerox.jpg')}class="img-fluid"  alt=""/>
            </div></div>
    </div> */}
    <div class='item'>
    <div class="col">  <div class="client-logo">
              <img src={require('./../../assets/images/clients/accenturehd.png')} class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col">  <div class="client-logo">
              <img src={require('./../../assets/images/clients/Azurehd.png')} class="img-fluid" alt=""/>
            </div></div>
    </div>
    {/* <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/BHEL.png')}class="img-fluid" alt=""/>
            </div></div>
    </div> */}
   
    <div class='item'>
    <div class="client-logo">
              <img style={{"height": "100px","width":"200px"}} src={require('./../../assets/images/clients/CDACnew.png')} class="img-fluid" alt=""/>
            </div>
    </div>
    
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/CISCOhd.png')} class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col"><div class="client-logo">
              <img  style={{ "height": "180px","width":"180px"}} src={require('./../../assets/images/clients/Punj-Lloydhd.png')} class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Novartis_logo.png')}class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/ISB.jpg')} class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/HAL.png')}class="img-fluid"  alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Continental-hd.jpg')} class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="client-logo">
              <img style={{"height": "110px","width":"170px"}} src={require('./../../assets/images/clients/HIDhd.jpg')} class="img-fluid" alt=""/>
            </div>
    </div>
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img style={{"height": "300px","width":"250px"}}  src={require('./../../assets/images/clients/trimblehd.svg')}class="img-fluid" alt=""/>
            </div></div>
    </div>
    <div class='item'>
    <div class="col"> <div class="client-logo">
              <img style={{"height": "150px","width":"200px"}}  src={require('./../../assets/images/clients/HPhd.jpg')} class="img-fluid" alt=""/>
            </div></div>
    </div>
   
</OwlCarousel>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Clients;