import React from 'react'
import '../../../../App.css'
function Star() {
  return (
   
<div className="Star" style={{fontSize:"15px"}}>
<h4 style ={{ color: "black", textAlign: "center" }}>Star Model</h4>
<p style={{color:"black",fontSize:"16px",textAlign:"center"}}>Coming Soon....</p>
<p >In Star model, all paths lead to software preview as shown below. A user can upload a doc and view the auto developed software or a user can design software and preview it with auto generated software. From the preview stage, a user can travel to Feature Categorization and back. Update config and preview. Customize code and preview. Integrate with other applications and preview. Enhance and preview.
</p>
<p >Star Model: </p>
<img  src={require('../../../../assets/images/starmodelimage.png')}class="center" alt="..."/>
<p >In Star development model, users can jump from one level to another. Reverse traversal is possible</p>
<p>The star based approach for the automatic development of software. The star based approach includes that a user can preview the auto-developed software and make changes as per his needs and requirements. In an embodiment, the star based approach is a computer-implemented system for the automatic development of software.</p>
<p >The computer-implemented system includes a server for hosting a UI infrastructure on a device such as server, laptop and computer.</p>
<p >The user is asked to upload input into a document. The user input is processed through artificial intelligence based Natural Language Processing (NLP) to convert into a blueprint of the required software. The software features of the input will be automatically programmed into software. The user may preview the developed software and go back to the input document. The user may then update/modify the input document as per the needs and requirements. The process may be repeated until all the desired features are incorporated into the software or the user may go to next arm of the star.
</p>
<p >The requirements and the input are categorized into a plurality of feature groups or domain design terms. The requirements are categorized into the plurality of feature groups through an artificial intelligence based Natural Language Processing (NLP) method. The software features of the input will be automatically programmed into software. The user may preview the developed software and go back to the input document. The user may manually re-categorize the features. The process is repeated until all the desired features are incorporated into the software software or the user may go to next arm of the star.
</p>
<p >The user may integrate the computer-implemented system with a variety of other applications. The user may preview the system and check the working and operations. In an embodiment, the user may make the changes in the developed software and the process may be repeated until the system is as per the needs and requirements of the user or the user may go to next arm of the star.
</p>
<p >The user may view the custom libraries, packages and code of the developed software. The user may then make the changes and the process may be repeated until the developed software is as per the needs and requirements of the user. In an embodiment, the changes may be made with minimal coding. 
</p>
<p >A plurality of parameters such as, but not limited to, programming language, persistence mechanism, UI libraries, database, deployment server port and IP address are configured to develop the automated software. The user may preview the developed software and go back to modify the configuration settings. The process may be repeated until the system is configured as per the needs of the user and then the user may go to next arm of the star.
</p>

<p>The computer-implemented system may further include an enhancement and redeployment stage. 
</p>
<p >While the disclosure has been presented with respect to certain specific embodiments, it will be appreciated that many modifications and changes may be made by those skilled in the art without departing from the spirit and scope of the disclosure. It is intended, therefore, by the appended claims to cover all such modifications and changes as fall within the true spirit and scope of the disclosure.

</p>

    </div>
   
  )
}

export default Star