import React from 'react';


export function PricingOffers() {
  return (

    <div className="title-pages">
      <h3>Pricing Offers</h3>
      <table style={{ margin: "0 auto", borderTop: "1px solid black", borderRight: "1px solid black", borderLeft: "1px solid black", width: "1100px", fontWeight: "normal" }}>
        <tr >
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>-</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}><b>Starter</b></td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} ><b>Basic</b></td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}><b>Standard</b></td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}><b>Premium</b></td>
          <td style={{ width: "20px", borderRight: "1px solid black", backgroundColor: "lightpink" }}><b>Enterprise</b></td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Subscription Price
          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>$29</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >$99</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>$199</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>$299</td>
          <td style={{ width: "20px", borderRight: "1px solid black", backgroundColor: "lightpink" }}  >CUSTOM
          </td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Number of User Accounts

          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>1</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >2</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>5</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>10</td>
          <td style={{ width: "20px", borderRight: "1px solid black", backgroundColor: "lightpink" }} rowSpan={21}>For any enterprise custom packages please contact sales@aiscipro.com




















          </td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Feature Pricing Discount*


          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>10%</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >10%</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>10%</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>10%</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>AI Software Development



          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>

        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>App Preview




          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom Feature Recategorization





          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>



        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom System Configuration</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>

        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom Model Designer

          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>N</td>
        </tr>

        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custome Workflow Designer


          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>-</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >-</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>-</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>-</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom Reports & Dashboards Designer



          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom Form Designer




          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>-</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >-</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>-</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>-</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom UI Designer




          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom API Builder





          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Custom Code Editor
          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Cloud Hosting


          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Customization



          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}><b>-</b></td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} ><b>-</b></td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}><b>-</b></td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}><b>-</b></td>
        </tr>

        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Email Support for App Development



          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>

        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Email Support for App Development



          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}> Development



          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}> Development




          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>Maintenance



          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
        <tr>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "aqua" }}>
            Maintenance




          </td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsalmon' }}>N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightskyblue" }} >N</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: "lightgreen" }}>Y</td>
          <td style={{ width: "10px", borderRight: "1px solid black", backgroundColor: 'lightsteelblue' }}>Y</td>
        </tr>
      </table>


    </div>
  );
};