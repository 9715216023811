 
import React from 'react'
//import Iframe from 'react-iframe'
import './blog.css'
import {Blogsjson} from './Blogsjson'
import ReactGA from 'react-ga';

import '../../../../App.css'
import { Link } from 'react-router-dom';
// import ReactDom from 'react-dom';
// import Data from "./mockdata.json"
// import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'


// import samplimg from '../../../../assets/images/agreement.jpg'
// import { blue } from '@material-ui/core/colors';

import { useState,useEffect} from 'react';
// import Pagination from './Pagination';
// import { result } from 'lodash'
import ReactPaginate from "react-paginate";
// import { Label } from '@material-ui/icons';

let PageSize = 6;
console.log(PageSize)
const Blogs = () => {
  //google web analytics (interactive)
  // ReactGA.event({category:Blogsjson.title,
  //   action:"test action",
  //   Label:"test label",
  //   value:Blogsjson.blognumber,
  // });
 
 //end of event
  const [prev, setPrev] = useState(false);
 
 
  const [data, setData] = useState(Blogsjson);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortfilterValue, setSortFilterValue] = useState();
 console.log(currentPage)
 console.log(sortfilterValue)
 console.log(setSortFilterValue)
 //pageview google analytics interactive

 useEffect(()=>{
  ReactGA.pageview(window.location.pathname);
},[]);
//.end of pageview google analytics
 
  const paginate = (pageNumber) => setCurrentPage(pageNumber)
 console.log(paginate)
  // const currentTableData = useMemo(() => {
 
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return data.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);
 
  const [searchTerm, setSearchTerm] = useState('')
  const [pageNumber, setPageNumber] = useState(0);
 
  const postsPerPage = 6;
  const pagesVisited = pageNumber * postsPerPage;
  const pageCount = Math.ceil(
    data.filter((blog) => {
      if (searchTerm === "") {
        return blog;
      } else if (
        blog.keywords.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return blog;
      }
      return false;
    }).length / postsPerPage
  );
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
 
  // const handleBlog =(el)=>{
  //   const selectBlog = data.filter((element)=>{
     
  //      return  element.id===el
  //   })
  //   console.log(selectBlog);
  // }
 
  const filterResult = (catItem) => {
    const result = Blogsjson.filter((curData) => {
      return curData.Category === catItem;
    });
 
 
    setData(result);
  }
  const filterDate = (dateItem) => {
    const resul = Blogsjson.filter((currData) => {
 
      return currData.Month === dateItem;
    });
 
 
    setData(resul);
  }
 
 
 
  return (
    <div className="title-pages">
 
      <div className="container-fluid mx-2" style={{ display: "inline" }}>
        <span className="row mt-5">
          <h3 style={{ color: "black" }}>Blogs</h3>
          <span id="display" className="col-xs-6 col-md-9" >
 
            <div className="row mt-5 mx-2" >
              {/* <div >
                <div>
                  {
                    data.map((e)=>{
                      console.log(e);
                      if(e){
                        return(
                          <div className="col-lg-6 mb-4 d-flex" key={e.id}>
                          <div class="card" id="here">
                            <img style={{ "height": "350px", "width": "553px" }} src={e.image} class="" alt="..." />
   
                            <div className="card-body">
   
                              <h5 style={{ textAlign: "left" }} class="card-title">
                              <div>{e.title}</div>
                              </h5> <p style={{ textAlign: "left" }} class="card-text"><small style={{ fontSize: "medium", textAlign: "left" }} class="text-muted">{e.author},{Date}</small></p>
                              <p style={{ textAlign: "left" }} class="card-text">{e.body}
   
                              </p>
                              <p style={{ textAlign: "left" }} >  <Link target="_top" to={e.blognumber} style={{ textAlign: "left", fontSize: "medium", color: "blue" }}> »read more</Link></p>
                            </div>
                          </div>
 
                          <div class="card" id="here">
                            <img style={{ "height": "350px", "width": "553px" }} src={e.image} class="" alt="..." />
   
                            <div className="card-body">
   
                              <h5 style={{ textAlign: "left" }} class="card-title">
                              <div>{e.title}</div>
                              </h5> <p style={{ textAlign: "left" }} class="card-text"><small style={{ fontSize: "medium", textAlign: "left" }} class="text-muted">{e.author},{Date}</small></p>
                              <p style={{ textAlign: "left" }} class="card-text">{e.body}
   
                              </p>
                              <p style={{ textAlign: "left" }} >  <Link target="_top" to={e.blognumber} style={{ textAlign: "left", fontSize: "medium", color: "blue" }}> »read more</Link></p>
                            </div>
                          </div>
   
                        </div>
                         
                        )
                      }
                    })
                  }
                </div>
              </div> */}
 
 
              {/* {data.filter(val => {
                if (searchTerm === "") {
                  //if query is empty
                  return val;
                } else if (val.keywords.toLowerCase().includes(searchTerm.toLowerCase())) {
                  //returns filtered array
                  return val;
                }
                 }).map((values) => {
                const { id, title, body, author, image, keywords, Category, Date, blognumber } = values; */}
              {data.filter((blog) => {
                if (searchTerm === "") {
                  return blog;
                } else if (
                  blog.keywords.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return blog;
                }
                return false;
              }).slice(pagesVisited, pagesVisited + postsPerPage)
                .map((e) => {
                  const { id, title, body, author, image,  Date, blognumber } = e;
                  return (
                    <>
 
                      <div className="col-lg-6 mb-4" key={id}>
                        <div class="card" id="here">
                          {/* <Link to={blognumber} target="_top"> */}
                          <Link to={blognumber} >
                            <img style={{ "height": "350px", "width": "553px" }} src={image} class="" alt="..." />
                          </Link>

                          {/* <Link to="/Blognineone" target="_blank"  rel="noopener noreferrer">
                            <img style={{ "height": "350px", "width": "553px" }} src={e.image} class="" alt="..." />
                          </Link> */}
 
                          {/* <a target="_blank" href="/blognine" rel="noopener noreferrer">
                          <img src="/static/media/blog9image.12c69d84.png" class="" alt="..." style="height: 350px; width: 553px;"/>
                            </a> */}
 
                          <div className="card-body">
 
                            <h5 style={{ textAlign: "left" }} class="card-title">
                              <Link name="sam" style={{ textAlign: "left", color: "black" }} to=
              {blognumber} >
                                {title}
                                </Link>
                            </h5>
                            <p style={{ textAlign: "left" }} class="card-text"><small style={{ fontSize: "medium", textAlign: "left" }} class="text-muted">{author},{Date}</small></p>
                            <p style={{ textAlign: "left" }} class="card-text">{body}
 
                            </p>
                            <p style={{ textAlign: "left",fontSize: "medium", color: "blue" }} > 
                             <Link to={blognumber} style={{ textAlign: "left", fontSize: "medium", color: "blue" }} > 
                               »read more
                               </Link>
                               </p>
                          </div>
                        </div>
 
                      </div>
 
 
 
                    </>
                  )
 
                })
 
 
 
 
              }
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"} />
              {/* <Pagination
                className="pagination-bar"
                // totalPost={data.length}
                // paginate={paginate}
                currentPage={currentPage}
                totalCount={data.length}
                onPageChange={page => setCurrentPage(page)}
              /> */}
            </div>
 
          </span>
          <div class="col-xs-6 col-md-3">
            <div className="row mt-5 ">
 
 
 
 
              <div>
                <b><p style={{ color: "black", paddingLeft: "10px", textAlign: "left", fontSize: "large" }}>Search by Category</p></b>
                {/* <p style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }} onClick={getpgm}>Automatic Programming</p> */}
                <p onClick={() => filterResult('Automatic Programming')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}> Automatic Programming</Link></p>
                <p onClick={() => filterResult('Domain Driven Design (DDD)')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>Domain Driven Design (DDD)</Link></p>
                <p onClick={() => filterResult('Aspect Oriented Programming')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>Aspect Oriented Programming</Link></p>
                <p onClick={() => filterResult('Software Modeling')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>Software Modelling</Link></p>
                <p onClick={() => filterResult('Software Engineering')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>Software Engineering</Link></p>
 
 
                <p onClick={() => filterResult('Attributes of Entity')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>Attributes of Entity</Link></p>
              </div>
              <div>
                <b><p style={{ color: "black", paddingLeft: "10px", textAlign: "left", fontSize: "large" }}>Search by Date</p></b>
 
                <p onClick={() => filterDate('November 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >November 2022</Link></p>
                <p onClick={() => filterDate('October 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >October 2022</Link></p>
                <p onClick={() => filterDate('September 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >September 2022</Link></p>
                <p onClick={() => filterDate('August 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >August 2022</Link></p>
                <p onClick={() => filterDate('July 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >July 2022</Link></p>
                <p onClick={() => filterDate('June 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >June 2022</Link></p>
                <p onClick={() => filterDate('May 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >May 2022</Link></p>
                <p onClick={() => filterDate('April 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >April 2022</Link></p>
                <p onClick={() => filterDate('March 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >March 2022</Link></p>
                <p onClick={() => filterDate('February 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >February 2022</Link></p>
                <p onClick={() => filterDate('January 2022')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >January 2022</Link></p>
                <p onClick={() => filterDate('December 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >December 2021</Link></p>
 
 
 
 
 
 
                <p onClick={() => setPrev(!prev)} style={{ textAlign: "left" }}> <Link style={{ textAlign: "left", fontSize: "medium", color: "blue" }}> &laquo; Previous</Link></p>
                {prev && <div>
                  <p onClick={() => filterDate('November 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>November 2021</Link></p>
                  <p onClick={() => filterDate('October 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>October 2021</Link></p>
                  <p onClick={() => filterDate('September 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>September 2021</Link></p>
                  <p onClick={() => filterDate('August 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>August 2021</Link></p>
                  <p onClick={() => filterDate('July 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>July 2021</Link></p>
                  <p onClick={() => filterDate('June 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>June 2021</Link></p>
                  <p onClick={() => filterDate('May 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>May 2021</Link></p>
                  <p onClick={() => filterDate('April 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>April 2021</Link></p>
                  <p onClick={() => filterDate('March 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>March 2021</Link></p>
                  <p onClick={() => filterDate('February 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>February 2021</Link></p>
                  <p onClick={() => filterDate('January 2021')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>January 2021</Link></p>
                  <p onClick={() => filterDate('December 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>December 2020</Link></p>
                  <p onClick={() => filterDate('November 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>November 2020</Link></p>
                  <p onClick={() => filterDate('October 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>October 2020</Link></p>
                  <p onClick={() => filterDate('September 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>September 2020</Link></p>
                  <p onClick={() => filterDate('August 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>August 2020</Link></p>
                  <p onClick={() => filterDate('July 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>July 2020</Link></p>
                  <p onClick={() => filterDate('June 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >June 2020</Link></p>
                  <p onClick={() => filterDate('May 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>May 2020</Link></p>
                  <p onClick={() => filterDate('April 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>April 2020</Link></p>
                  <p onClick={() => filterDate('March 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>March 2020</Link></p>
                  <p onClick={() => filterDate('February 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>February 2020</Link></p>
                  <p onClick={() => filterDate('January 2020')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>January 2020</Link></p>
                  <p onClick={() => filterDate('December 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>December 2019</Link></p>
                  <p onClick={() => filterDate('November 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>November 2019</Link></p>
                  <p onClick={() => filterDate('October 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>October 2019</Link></p>
                  <p onClick={() => filterDate('September 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }} >September 2019</Link></p>
                  <p onClick={() => filterDate('August 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>August 2019</Link></p>
                  <p onClick={() => filterDate('July 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>July 2019</Link></p>
                  <p onClick={() => filterDate('June 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>June 2019</Link></p>
                  <p onClick={() => filterDate('May 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>May 2019</Link></p>
                  <p onClick={() => filterDate('April 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>April 2019</Link></p>
                  <p onClick={() => filterDate('March 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>March 2019</Link></p>
                  <p onClick={() => filterDate('February 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>February 2019</Link></p>
                  <p onClick={() => filterDate('January 2019')} style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "medium" }}><Link style={{ color: "black" }}>January 2019</Link></p></div>}
              </div>
 
              <div>
                <b> <p style={{ color: "black", textAlign: "left", paddingLeft: "10px", fontSize: "large" }}>Search</p></b>
                <p style={{ color: "black", paddingLeft: "10px", textAlign: "left" }}>
                  {/* <input type="text" placeholder="search" onChange={event => setSearchTerm(event.target.value)}></input> */}
                  <input
            type="search"
            className="search"
            placeholder="search"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handlePageChange({ selected: 0 });
            }}
          />
                  </p>
 
              </div>
              <div>
                <b><p style={{ color: "black", paddingLeft: "10px", textAlign: "left", fontSize: "large" }}>Subscribe here</p></b>
                <p style={{ color: "black", paddingLeft: "10px", textAlign: "left" }}><input type="text" placeholder="email"></input> <button type="button" class="btn btn-primary"> <a href="http://platform.aiscipro.com/#/register">Sign up</a></button></p>
                <div >
 
                  <b><p style={{ color: "black", paddingLeft: "10px", textAlign: "left", fontSize: "large" }}>follow us</p></b>
                  <div style={{ paddingLeft: "20px", textAlign: "left", fontSize: "large" }}>
                    <span><a style={{ color: "blue", textAlign: "left" }} href="https://www.facebook.com/"><FontAwesomeIcon icon={faFacebook} />&nbsp;&nbsp;</a></span>
 
                    <span><a style={{ color: "#1DA1F2" }} href="https://www.twitter.com/"> <FontAwesomeIcon icon={faTwitter} />&nbsp;&nbsp;</a></span>
 
                    <span><a style={{ color: "#8a3ab9" }} href="https://www.instagram.com/"><FontAwesomeIcon icon={faInstagram} />&nbsp;&nbsp;</a></span>
 
                    <a style={{ color: "#FF0000" }} href="https://www.youtube.com/">  <FontAwesomeIcon icon={faYoutube} /></a>
 
 
                  </div>
 
                </div> <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
 
 
              </div>
            </div>
          </div>
        </span>
      </div>
 
    </div>
 
  )
}
 
export default Blogs
 


