
import classNames from 'classnames';




const AboutUs = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
  }) => {
    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
        
      );
   
  return (
   

    <div>
      
           
      <div className="container">
      {/* <img style={{ "height": "200px","width":"300px"}} src={require('../../../../assets/images/automated.webp')}class="" alt="..."/> */}
            <div  className={innerClasses}>
         
       <div className="container mt-3">
  
        <h3 style={{color:"black",textAlign:"center"}} >ABOUT US</h3>
  <div className="">
  <p>We, Coresonant Systems, is the leading AI & IOT solution provider in India with over 200+ successful project implementations across 100+ clients. Our client base spans Shipping, Mines,Infrastructure, Government, & Defense sectors.
  </p>
  <p>Our platform aiscipro is an AI powered automatic programming platform. Aiscipro is a realization of 7th generation automatic programming which has been the vision of pioneers in the software industry.</p>
  </div>
</div>
       
                
            </div>
        </div>
   
    </div>
  )
  
}

export default AboutUs