import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import { HashLink as Link} from 'react-router-hash-link';




import Logo from './partials/Logo';
import '../../App.css'
// import { Dropdown } from 'react-bootstrap';
//import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import { noAuto } from '@fortawesome/fontawesome-svg-core';
import'./Header.css';




/* eslint-disable */








const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}




const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}




const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const ref = useRef(null);
 
  const [isActive, setIsactive] = useState(false);
  const [enableDropdown, setenableDropdown] = useState(false)




  const nav = useRef(null);
  const hamburger = useRef(null);




  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });




  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }




  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }




  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }




  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }




  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );
  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };
  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container"style={{color:"white"}}>
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
         
          <Logo />
         
         
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div style={{backgroundColor:"white"} }id="menu" className="header-nav-inner">
                  <ul  className={
                    classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>




                    {/* <Navbar  id="nav-bar"  variant="" bg="" expand="lg"> */}
                      {/* <Container fluid id="nav-bar" > */}
                        {/* <Navbar.Toggle id="navbar-d" aria-controls="navbar-dark-example" />
                        <Navbar.Collapse id="navbar-dark-example"> */}
                          {/* <Link to="/" onClick={closeMenu} style={{color:"black"}} >Home </Link> */}
                          <Nav  style={{color:"black",paddingLeft:"0px",marginLeft:"10px"}} >
                         <NavDropdown
                              id="nav-dropdown-dark-example"
                               title="Home"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item  className='navd'> <Link smooth to="/#aisciprosection" onClick={closeMenu}  style={{ fontSize: "small", color:"black",paddingLeft:"0px" }}>aiscipro</Link></NavDropdown.Item>




                              <NavDropdown.Item  className='navd'> <Link smooth to="/#clients" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Client Partners</Link></NavDropdown.Item>
                              <NavDropdown.Item  className='navd'> <Link smooth to="/#certification" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Certifications</Link></NavDropdown.Item>
                              <NavDropdown.Item className='navd'> <Link smooth to="/#Testimonial" onClick={closeMenu}  style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Testimonials</Link></NavDropdown.Item>
                             
                            </NavDropdown>
                          </Nav>
                          <Nav >
                         <NavDropdown  
                              id="nav-dropdown-dark-example"
                              title="Usecase"
                              menuVariant=""
                            >
                          




                             
                              
                              
                              <NavDropdown.Item> <Link to="/One" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"}}>STOS</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Timeless" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"}}>Timeless</Link></NavDropdown.Item>
                              <NavDropdown.Item > <Link to="/Star" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"}}>Star</Link></NavDropdown.Item>
                              <NavDropdown.Item > <Link to="/Orbital" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"}}>Orbital</Link></NavDropdown.Item>

                              {/* <Nav style={{color:"black",paddingLeft:"0px"}} >
                         <NavDropdown  style={{fontSize: "small"}}
                              id="nav-dropdown-dark-example"
                               title="Orbital"
                              menuVariant=""
                            > */}
                             
                              {/* <NavDropdown.Item  className='navd'> <Link to="/AutomaticProgramming" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px" }}>Automatic Programming</Link></NavDropdown.Item> */}
                              {/* <NavDropdown.Item  className='navd'> <Link to="/AssistedautomaticProgramming" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Assisted automatic Programming</Link></NavDropdown.Item>
                              <NavDropdown.Item  className='navd'> <Link to="/VisualNocodeProgramming" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Visual No-code Programming</Link></NavDropdown.Item>
                              <NavDropdown.Item  className='navd'> <Link to="/VisualLowcodeProgramming" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Visual Low-code Programming</Link></NavDropdown.Item>
                              <NavDropdown.Item className='navd'> <Link to="/Configuration" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Configuration Programming</Link></NavDropdown.Item>
                              <NavDropdown.Item  className='navd'> <Link to="/Cicd" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>CI/CD Programming</Link></NavDropdown.Item>
                            */}
                            {/* </NavDropdown>
                          </Nav> */}
                            </NavDropdown>
                          </Nav>
                          <Nav id="nav-bar" >
                         <NavDropdown   style={{color:"white"}}
                              id="nav-dropdown-dark-example"
                              title="Industry"
                              menuVariant=""
                            >
                              <Nav  style={{color:"black",paddingLeft:"10px"}} >
                         <NavDropdown   style={{fontSize: "small",color:"black"}}
                              id="nav-dropdown-dark-example"
                              title="Education & Research"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/SchoolmanagementSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black" ,paddingLeft:"0px"}}>School management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/LibrarymanagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Library management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FinancialManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Financial Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/AdmissionManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Admission Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/LearningManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Learning Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/OnlineExaminationSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Online Examination System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/5" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Online Survey System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Parentcommunication" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Parent communication</Link></NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                          <NavDropdown   style={{fontSize: "small",paddingLeft:"10px"}}
                              id="nav-dropdown-dark-example"
                              title="Financial Sector"
                              menuVariant=""
                            >
                           




                            <NavDropdown.Item> <Link to="/CustomerRelationshipManagementSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px"}}>Customer Relationship Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/MarketingAutomationSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Marketing Automation System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/BusinessIntelligenceSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Business Intelligence (BI) System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/CustomerManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Customer Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Payments&InvoicingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Payments & Invoicing System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/LoanManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Loan Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/InvestmentManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Investment Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/BudgetingTrackingManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Budgeting & Tracking Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FinancialReportingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Financial Reporting System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/StockTradingManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Stock Trading Management System</Link></NavDropdown.Item>
                            </NavDropdown>
                         
                           
                         <NavDropdown  style={{fontSize: "small",color:"white",paddingLeft:"10px"}}
                              id="nav-dropdown-dark-example"
                              title="Bank"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/ATMMonitoringSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black" ,paddingLeft:"0px"}}>ATM Monitoring System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/AssetTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Asset Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FileTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>File Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/EmployeeTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Employee Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/AccessControlSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Access Control System</Link></NavDropdown.Item>




                            </NavDropdown>
                       
           




                          <Nav style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown  style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                              title="Healthcare Sector"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/MedicalBillingSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px" }}>Medical Billing System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/ElectronicMedicalRecordsSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Electronic Medical Records System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/PracticeManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Practice Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/AppointmentSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Appointment System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/CalenderSchedulingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Calender & Scheduling System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/PatientEngagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Patient Engagement System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/ImagingandVisualizationSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Imaging and Visualization System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/InventoryTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Inventory Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/SupplierManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Supplier Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/OrganizationManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Organization Management System</Link></NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                          <Nav  style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown  style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                              title="Logistics Sector"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/SupplyChainManagementSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px"  }}>Supply Chain Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/WarehouseDispatchManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Warehouse & Dispatch Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FleetManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Fleet Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/OrderManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Order Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FieldServiceManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Field Service Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/DocumentationManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Documentation Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/DistributionManagementSoftware" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Distribution Management Software</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/GPSFleetTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>GPS Fleet Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/ContainerTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Container Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/YardManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Yard Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Containercargofleetmanagement" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Container cargo fleet management</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/DockManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Dock Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FastagbasedParkingMangementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Fastag based Parking Mangement System</Link></NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                     <Nav   style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown  style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                              title="Mining Industry"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/TruckGuidanceSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black" ,paddingLeft:"0px" }}>Truck Guidance System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/VehicleTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Vehicle Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/MiningEquipmentTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Minning Equipment Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/WorkersEmergencyAlertSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Workers Emergency Alert System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/MaterialTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Material Tracking System</Link></NavDropdown.Item>




                            </NavDropdown>
                          </Nav>
                          <NavDropdown.Item  style={{ fontSize: "small",color:"white" ,paddingLeft:"10px"}}> <Link to="/" style={{ fontSize: "small",color:"black"  }}>Transport Sector</Link> </NavDropdown.Item>              
                            <Nav  style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown  style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                               title="Seaport"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/GateAutomationSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black" ,paddingLeft:"0px" }}>Gate Automation System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/EquipmentMonitoringSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Equipment Monitoring System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/ContainerTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Container Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/CargoTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Cargo Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/SmartEvacuationSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Smart Evacuation System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/TruckAppointmentSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Truck Appointment System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/WeighbridgeAutomationSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Weighbridge Automation System</Link></NavDropdown.Item>
                            </NavDropdown>
                            </Nav>
                            <Nav style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown  style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                               title="Airport"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/PersonnelAccessControlSystem" onClick={closeMenu} style={{ fontSize: "small", color:"black" ,paddingLeft:"0px" }}>Personnel Access Control System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/ParkingAutomationandFarecollectionSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Parking Automation and Fare collection System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/SmartCanteenManagementSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Smart Canteen Management System</Link></NavDropdown.Item>




                            </NavDropdown>
                           </Nav>
                          <Nav style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown   style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                               title="Railways"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/AccessControlSystemtoemployees" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px"  }}>Access Control System to employees</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/TimeAttendanceSystemtoemployees" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Time & Attendance System to employees</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/WagonTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Wagon Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/TrainTrackingSystem" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Train Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/ParkingManagementSystems" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>Parking Management Systems</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/CanteenManagementSystems" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Canteen Management Systems at offices,stations etc..</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/OFCWirelessNetworking" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px" }}>OFC & Wireless Networking</Link></NavDropdown.Item>
                            </NavDropdown>
                          </Nav>
                          <Nav  style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown  style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                               title="Metro Rail"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/ParkingManagementSystemss" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px" }}>Parking Management Systems</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/TimeAttendanceSystemtoemployees1" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Time & Attendance System to employees</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Access Control System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Cargo Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>GPS Based Metro Train Tracking System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Canteen Management Systems at offices,stations etc..</Link></NavDropdown.Item>




                            </NavDropdown>
                         
                           </Nav>
                           <Nav style={{color:"white",paddingLeft:"10px"}} >
                         <NavDropdown onClick={closeMenu}  style={{fontSize: "small",color:"white"}}
                              id="nav-dropdown-dark-example"
                               title="Manufacturing Sector"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px" }}>Warehouse Management</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Work-in-Progess Tracking</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>RFID based Vehicle Tracking</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Weighbridge Automation System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Yard Management system</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Dock Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>RFID Based Asset Life Cycle Mnagemnet System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>GPS based Vehicle Tracking</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Canteen Management System</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" }}>Labour Mangement(T&A)</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px"}}>Smart Card/ Biometric based Access Control System</Link></NavDropdown.Item>




                            </NavDropdown>
                        </Nav>
                            </NavDropdown>
                          </Nav>
                         
                          <Nav style={{color:"white"}}>
                            <NavDropdown  style={{color:"white",textDecorationColor:"white"}}
                              id="nav-dropdown-dark-example"
                              title="Resources"
                              menuVariant=""
                            >
                              <NavDropdown.Item> <Link to="/Blogs" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" ,marginLeft:"-10px"}}>Blogs</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Casestudies" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px",marginLeft:"-10px" }}>Case-Studies </Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Downloads" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px",marginLeft:"-10px" }}>Downloads</Link> </NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FAQ" onClick={closeMenu} style={{ fontSize: "small" ,color:"black",paddingLeft:"0px",marginLeft:"-10px"}}>FAQs </Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Articles" onClick={closeMenu} style={{ fontSize: "small",color:"black" ,paddingLeft:"0px",marginLeft:"-10px"}}>Articles </Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/Videos" onClick={closeMenu} style={{ fontSize: "small" ,color:"black",paddingLeft:"0px",marginLeft:"-10px"}}>Videos </Link></NavDropdown.Item>
                             
                           
                            </NavDropdown>
                          </Nav>
                          {/* <Link to="/Pricing" onClick={closeMenu} style={{color:"black",right:"170px"}}>Pricing</Link> */}
                          <Nav style={{color:"black",paddingLeft:"0px",marginLeft:"10px"}} >
                         <NavDropdown onClick={closeMenu}
                              id="nav-dropdown-dark-example"
                               title="Pricing"
                              menuVariant=""
                            >
                              
                             
                             <NavDropdown.Item> <Link to="/PricingSummary" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px" ,marginLeft:"-10px"}}>Pricing Summary</Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/PricingOffers" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px",marginLeft:"-10px" }}>Pricing Offers
 </Link></NavDropdown.Item>
                              <NavDropdown.Item> <Link to="/FeaturePricing" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px",marginLeft:"-10px" }}>Feature Pricing</Link> </NavDropdown.Item>
                              
                             
                              
                             
                            </NavDropdown>




                            
                          </Nav>
                            {/* <Link to="/" onClick={closeMenu} style={{color:"black",paddingLeft:"5px"}}>Licensing</Link> */}
                            <Nav  style={{color:"black",paddingLeft:"0px",marginLeft:"10px"}} >
                         <NavDropdown
                              id="nav-dropdown-dark-example"
                               title="Licensing"
                              menuVariant=""
                            >
                             
                              <NavDropdown.Item  className='navd'> <Link to="/" onClick={closeMenu} style={{ fontSize: "small", color:"black",paddingLeft:"0px" }}>Product Licensing</Link></NavDropdown.Item>
                              
                              <NavDropdown.Item  className='navd'> <Link to="/Termsofuse" onClick={closeMenu} style={{ fontSize: "small",color:"black",paddingLeft:"0px"  }}>Platform Licensing</Link></NavDropdown.Item>
                             
                            </NavDropdown>
                          </Nav>
                        {/* </Navbar.Collapse> */}
                       
                      {/* </Container> */}
                   
                    {/* </Navbar> */}
                   
                 
                  </ul>
                 
                      {/* <li>
                        <Link to="#0" className="button button-primary button-wide-mobile button-sm" onClick={closeMenu}>Sign up</Link>
                      </li> */}
                   
                </div>
              </nav>
            </>}
        </div>
      </div>
    </header>
  );
}




Header.propTypes = propTypes;
Header.defaultProps = defaultProps;




export default Header;

















