import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';  // Use BrowserRouter as an alias for Router
import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/scss/style.scss';
//import Header from './components/layout/Header';




ReactDOM.render(
    <BrowserRouter>
    <App />
    </BrowserRouter>
 ,
  document.getElementById('root')
);

serviceWorker.unregister();
