import React from 'react'

function Blog12() {
  return (
    <div className='blog'>

<p style ={{ color: "blue", textAlign: "center" }}>How is aiscipro platform enabling a true Agile process of software development?</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Agile methodology of software development was mainstreamed by the Manifesto of Agile
Software Development drafted by a group of seventeen software industry veterans pained by
the documentation driven heavyweight software development that, majority of the times, does
not deliver to the explicit nor expected requirements.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>There are 12 principles behind agile manifesto which we will list one by one and alongside try to
establish our claim that the aiscipro platform is truly inline with the principles, and thereby
realizes the vision of Agile manifesto.</p>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 1: Our highest priority is to satisfy the customer through early and
continuous delivery of valuable software.</b></p>
<p style={{fontSize: "medium", textAlign: "left" }}>
{/* <table style={{width:"70%"}}>
  <tr>
    <th>KEY PHRASES</th>
    <th>aiscipro ENABLES by</th>
    
  </tr>
  <tr>
    <td>Early delivery</td>
    <td>automatic design & code generation

</td>
   
  </tr>
  <tr>
    <td>Continuous delivery</td>
    <td>repeated prototyping</td>
   
  </tr>
  <tr>
    <td>Valuable software</td>
    <td>software derived directly from user requirements</td>
   
  </tr>
  
</table> */}

<img  style={{width:'700px',height:'180px'}} src={require('../Blogimages/blog12table1.png')}class="center" alt="..."/>

</p>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 2: Welcome changing requirements, even late in development.
Agile processes harness change for the customer&#39;s competitive advantage.</b></p>
<img  style={{width:'700px',height:'150px'}} src={require('../Blogimages/blog12table2.png')}class="center" alt="..."/><br></br>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 3: Deliver working software frequently, from a couple of weeks to
a couple of months, with a preference to the shorter timescale.</b></p>
<img  style={{width:'700px',height:'150px'}}  src={require('../Blogimages/blog12table3.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 4: Business people and developers must work together daily
throughout the project.</b></p>
<img style={{width:'700px',height:'150px'}}  src={require('../Blogimages/blog12table4.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 5: Build projects around motivated individuals. Give them the
environment and support they need, and trust them to get the job done.</b></p>
<img style={{width:'700px',height:'180px'}}   src={require('../Blogimages/blog12table5.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 6: The most efficient and effective method of conveying
information to and within a development team is face-to-face conversation.</b></p>
<img style={{width:'700px',height:'120px'}}  src={require('../Blogimages/blog12table6.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 7: Working software is the primary measure of progress.</b></p>
<img style={{width:'700px',height:'120px'}}  src={require('../Blogimages/blog12table7.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 8: Agile processes promote sustainable development. The
sponsors, developers, and users should be able to maintain a constant pace
indefinitely.</b></p>
<img  style={{width:'700px',height:'150px'}}  src={require('../Blogimages/blog12table8.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 9: Continuous attention to technical excellence and good design
enhances agility.</b></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 10: Simplicity--the art of maximizing the amount of work not done-
-is essential.</b></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 11: The best architectures, requirements, and designs emerge
from self-organizing teams.</b></p>
<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "left" }}><b>Agile Principle 12: At regular intervals, the team reflects on how to become more
effective, then tunes and adjusts its behavior accordingly.</b></p>
<p style={{fontSize: "medium", textAlign: "left" }}>Conclusion: While aiscipro platform is aligned with true agile principles and realizes the vision of
the visionary group of seventeen, we still believe agile software development methodologies are
“whipping the horse harder”</p>
<p style={{fontSize: "medium", textAlign: "left" }}>The vision of aiscipro platform is to be humane by eliminating the drudgery of manual hand
coding. That is, taking out the whip by replacing the horse with an engine.</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Reference:
</p>
<p style={{fontSize: "medium", textAlign: "left" }}>http://agilemanifesto.org/</p>
    </div>
  )
}

export default Blog12