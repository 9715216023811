// import React from 'react'
// import ReactGA from 'react-ga';
// import {useEffect } from 'react';
// import Iframe from 'react-iframe'


// async function Contact() {
//   //pageview google analytics interactive
//   // extraDataValue = extraData[prop].call(null);
//   // if (typeof extraDataValue?.then === "function") {
//   //   extraDataValue = await extraDataValue;
//   // }
//   useEffect(()=>{
//     ReactGA.pageview(window.location.pathname);
//   },[]);
// //.end of pageview google analytics

//   return (
//     <div style={{color:"black",textAlign:"center"}}>

      
//       <br></br>
//       <br></br>
//       <br></br>
//   <h3 style={{color:"black",textAlign:"center"}}>  Contact Us</h3> 

//       <br></br>
//       <div class="container">
//       <div class="row">
//       {/* <i class="fa fa-mobile"></i>
//       <p>
//                                         Call P:+91 40 27803303<br/>
//                                         Call M: +91 9010251777<br/>
//                                         <span>Monday-Friday (10am-7pm)</span>
//                                     </p>
//                                     <br></br> */}
//                                     {/* <i class="fa fa-map-marker"></i>
//                                     <p>
//                                         Corporate Office Address:<br/>
//                                         Location: 303, 3rd Floor,<br/>
//                                         Navketan Complex, <br/>
//                                         Opp Clock Tower,<br/>
//                                         Secunderabad- 500 003<br/>
//                                         India<br/>

//                                     </p><br></br>
//                                     <i class="fa fa-map-marker"></i>
//       <p>
//                                         Registered Address:<br/>
//                                         Location: NO.15-27, BEC COMPLEX,<br/>
//                                         BEERAPPAGADDA, UPPAL, <br/>
//                                         HYDERABAD, AP., RANGAREDDY,<br/>
//                                         TELANGANA, 500039<br/>

//                                     </p><br></br> */}

//                                     {/* <i class="fa fa-envelope-o"></i>
//                                     <p>
//                                         Email:  info@coresonant.com<br/>

//                                     </p> */}

                                 
//                                     {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3811520109157!2d78.49547781481206!3d17.441461888046245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a3ebc0d664b%3A0x4a90ec12ae3fc78c!2sCoresonant+Systems+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1563447167417!5m2!1sen!2sin" width="100%" height="380" frameborder="0" style="border:0" allowfullscreen></iframe> */}
                                   


   
//     <div  class="col-4">

//    {/* <p ><i  class="fa fa-mobile"></i></p> 
//       <p >
//                                         Call P:+91 40 27803303<br/>
//                                         Call M: +91 9010251777<br/>
//                                         <span>Monday-Friday (10am-7pm)</span>
//                                     </p> */}
//                                     {/* <br></br> */}
//                                     <i class="fa fa-envelope-o"></i>
//                                     <p >
//                                        sales@aiscipro.com<br/>

//                                     </p>
  
//                                     <i class="fa fa-map-marker"></i>
//                                     <p >
//                                     aiscipro,<br/> 2803 Philadelphia Pike B # 4228,<br/>
//                                      Claymont,<br/> DE 19703,<br/>
//                                       United States.

//                                     </p><br></br>
//                                     <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3065.5063525371656!2d-75.46882648462288!3d39.79563237944217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6e3884a8e729f%3A0x5451c920008aadb5!2s2803%20Philadelphia%20Pike%20b%204228%2C%20Claymont%2C%20DE%2019703%2C%20USA!5e0!3m2!1sen!2sin!4v1676363138899!5m2!1sen!2sin"
//         width="400px"
//         height="300px"
//         id=""
//         className=""
//         display="block"
//         position="relative"/><br></br>
//                                     {/* <i class="fa fa-map-marker"></i>
//       <p>
//                                         Registered Address:<br/>
//                                         Location: NO.15-27, BEC COMPLEX,<br/>
//                                         BEERAPPAGADDA, UPPAL, <br/>
//                                         HYDERABAD, AP., RANGAREDDY,<br/>
//                                         TELANGANA, 500039<br/>

//                                     </p><br></br> */}

// <i class="fa fa-map-marker"></i>
//                                     <p >
//                                         Corporate Office Address:<br/>
//                                         Location: 303, 3rd Floor,<br/>
//                                         Navketan Complex, <br/>
//                                         Opp Clock Tower,<br/>
//                                         Secunderabad- 500 003<br/>
//                                         India<br/>


//                                     </p><br></br>

//                                     <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3811520109157!2d78.49547781481206!3d17.441461888046245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a3ebc0d664b%3A0x4a90ec12ae3fc78c!2sCoresonant+Systems+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1563447167417!5m2!1sen!2sin"
//         width="400px"
//         height="300px"
//         id=""
//         className=""
//         display="block"
//         position="relative"/><br></br>
//                                     </div>
//     <div class="col-8">
    
    
//     <div class="form-popup">

//           <form action="https://formspree.io/f/moqzobvp"
//   method="POST" class="form-container">
//           <label for="fname">User name:</label><br></br>
//   <input type="text" id="fname" name="username" required /><br></br>
//   <label for="fname">Company name:</label><br></br>
//   <input type="text" id="fname" name="comname" required /><br></br>
//   <label for="phone">Enter your phone number:</label><br></br>

// <input type="tel" id="phone" name="phone" pattern="[7-9]{1}[0-9]{9}" required/><br></br>


// <label for="email">Enter your email:</label><br></br>
// <input type="email" id="email" name="email" required/><br></br>

//   <button type="submit" class="btn">
//         Submit
//       </button>
//             </form>  
//   </div>                                       
        

//         </div>
//   </div>
//   </div>
//       </div>
//   )
// }

// export default Contact


import React from 'react'
import ReactGA from 'react-ga';
import {useEffect } from 'react';
import Iframe from 'react-iframe'
import './Contact.css'


function Contact() {
  //pageview google analytics interactive

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, 0);
  }, []);
  
//.end of pageview google analytics
  return (
    <div style={{color:"black",textAlign:"center"}}>


     
      <br></br>
      <br></br>
      <br></br>
  <h3 style={{color:"black",textAlign:"center"}}>  Contact Us</h3>


      <br></br>
      <div class="container">
      <div class="row">
      {/* <i class="fa fa-mobile"></i>
      <p>
                                        Call P:+91 40 27803303<br/>
                                        Call M: +91 9010251777<br/>
                                        <span>Monday-Friday (10am-7pm)</span>
                                    </p>
                                    <br></br> */}
                                    {/* <i class="fa fa-map-marker"></i>
                                    <p>
                                        Corporate Office Address:<br/>
                                        Location: 303, 3rd Floor,<br/>
                                        Navketan Complex, <br/>
                                        Opp Clock Tower,<br/>
                                        Secunderabad- 500 003<br/>
                                        India<br/>


                                    </p><br></br>
                                    <i class="fa fa-map-marker"></i>
      <p>
                                        Registered Address:<br/>
                                        Location: NO.15-27, BEC COMPLEX,<br/>
                                        BEERAPPAGADDA, UPPAL, <br/>
                                        HYDERABAD, AP., RANGAREDDY,<br/>
                                        TELANGANA, 500039<br/>


                                    </p><br></br> */}


                                    {/* <i class="fa fa-envelope-o"></i>
                                    <p>
                                        Email:  info@coresonant.com<br/>


                                    </p> */}


                                 
                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3811520109157!2d78.49547781481206!3d17.441461888046245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a3ebc0d664b%3A0x4a90ec12ae3fc78c!2sCoresonant+Systems+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1563447167417!5m2!1sen!2sin" width="100%" height="380" frameborder="0" style="border:0" allowfullscreen></iframe> */}
                                   




   
    <div  class="col-4">


   {/* <p ><i  class="fa fa-mobile"></i></p>
      <p >
                                        Call P:+91 40 27803303<br/>
                                        Call M: +91 9010251777<br/>
                                        <span>Monday-Friday (10am-7pm)</span>
                                    </p> */}
                                    {/* <br></br> */}
                                    <i class="fa fa-envelope-o"></i>
                                    <p >
                                       sales@aiscipro.com<br/>


                                    </p>
    {/* <i class="fa fa-map-marker"></i>
                                    <p >
                                        Corporate Office Address:<br/>
                                        Location: 303, 3rd Floor,<br/>
                                        Navketan Complex, <br/>
                                        Opp Clock Tower,<br/>
                                        Secunderabad- 500 003<br/>
                                        India<br/>


                                    </p><br></br> */}
                                    <i class="fa fa-map-marker"></i>
                                    <p >
                                    aiscipro,<br/> 2803 Philadelphia Pike B # 4228,<br/>
                                     Claymont,<br/> DE 19703,<br/>
                                      United States.


                                    </p><br></br>
                                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3065.5063525371656!2d-75.46882648462288!3d39.79563237944217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6e3884a8e729f%3A0x5451c920008aadb5!2s2803%20Philadelphia%20Pike%20b%204228%2C%20Claymont%2C%20DE%2019703%2C%20USA!5e0!3m2!1sen!2sin!4v1676363138899!5m2!1sen!2sin"
        width="400px"
        height="300px"
        id=""
        className=""
        display="block"
        position="relative"/><br></br>


                                    <i class="fa fa-map-marker"></i>
      <p>
                                        Registered Address:<br/>
                                        Location: NO.15-27, BEC COMPLEX,<br/>
                                        BEERAPPAGADDA, UPPAL, <br/>
                                        HYDERABAD, AP., RANGAREDDY,<br/>
                                        TELANGANA, 500039<br/>


                                    </p><br></br>
                                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3811520109157!2d78.49547781481206!3d17.441461888046245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a3ebc0d664b%3A0x4a90ec12ae3fc78c!2sCoresonant+Systems+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1563447167417!5m2!1sen!2sin"
        width="400px"
        height="300px"
        id=""
        className=""
        display="block"
        position="relative"/><br></br>


                                    </div>
                                    <div class="col-2"></div>
                                    
    <div class="col-6">
   
    <div className="form-popup">
  {/* <form onSubmit={handleSubmit}> */}
        <form action="https://formspree.io/f/moqzobvp"
  method="POST" className="form-container" id="myForm">
          <label for="fname">Name:</label><br></br>
  <input type="text" id="fname" name="username"  size="30"/><br></br>
  <label for="fname">Company Name:</label><br></br>
  <input type="text" id="fname" name="comname"  size="30"/><br></br>
  <label for="phone">Phone number*:</label><br></br>
{/* <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"/><br></br> */}
<input type="tel" id="phone" name="phone" pattern="[7-9]{1}[0-9]{9}" required/><br></br>




<label for="email">Email Address*:</label><br></br>
<input type="email" id="email"  name="email" required/><br></br>
<p><label for="w3review">Message:</label></p>
<textarea id="message" type="textarea" name="textarea" rows="4" cols="31">

</textarea>
  {/* <input type="submit" value="Submit"/> */}
  <button type="submit" class="btn">
        Submit
      </button>
            </form>  
           
  </div>


        </div>
  </div>
  </div>
 
      </div>
  )
}


export default Contact



