
import React from 'react'
import '../../../../App.css'
import MyVideo from "../../../../assets/videos/animation.mp4"
import aisciproVideo1 from '../../../../assets/videos/aiscipro_animation_video.mp4'
function Videos() {
  return (
    <div className='container'>
    <div className="title-pages ">
   <h3 style={{textAlign:"center"}} >Videos</h3> 
   <center>
   <video width="80%" height="100%"  controls>
  <source src={aisciproVideo1} type="video/mp4"/>
  Your browser does not support HTML5 video.
</video>
</center>
    </div>
    </div>
  )
}

export default Videos