import React from 'react'
import "../../../../../App.css"

function Blog3() {
  return (
<>
<div className="blog3">

<div>
  <p style ={{ color: "blue", textAlign: "center" }}>Why Lowcode-nocode is NOT Automatic Programming?</p>
        <p style={{fontSize: "medium", textAlign: "justify" }}>Lowcode-nocode is creation of software by higher level modeling.
It is orders of magnitude higher in abstraction compared to lower level programming skill
needed (i.e. for coding).</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
Lowcode-nocode platforms provide highly configurable softwares that eliminates the need to
write any programming code, either entirely or to a significant extent.
The modeling &amp; configuration could be using visual tools such as drag and drop or textual such
as excel or JSON with data entry.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
In lowcode-nocode the user has to design and provide inputs using specific tools or as per a
particular specifications schema.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog3imagefinal.jpg')}class="center" alt="..."/>
There is still human effort to learn and provide input as per the specifications or language so
that the platform (or computer) can understand and convert to lower level programming
language to create the desired software.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
This need of Lowcode-nocode platforms that expects humans to learn and align itself to
computers specifications or model schema is what makes Lowcode-nocode to be not an
automatic programming platform.
Therefore, Lowcode-nocode is NOT automatic programming.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
Fundamentally, Automatic programming implies that humans should not need any effort on their
part to make computer understand and build programs or create desired software.
Humans should be able to provide inputs in their natural way in their natural language or any
other natural media.</p><p style={{fontSize: "medium", textAlign: "justify" }}>
Computers should understand the human requirements on their own and produce the expected
results as a single output or a choice of outputs.
That is TRUE automatic programming.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
Computers should not need any effort from humans to help them in programming.
If the input in natural language is ambiguous, incorrect or inconsistent the resulting software
may be so.</p></div>
        </div>
</>
    )
  
}

  


export default Blog3
