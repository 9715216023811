import React, {   useState } from "react";
import classNames from "classnames";
// import { SectionProps } from "../../utils/SectionProps";
// import ButtonGroup from "../elements/ButtonGroup";
// import Button from "../elements/Button";
// import Image from "../elements/Image";
// import Modal from "../elements/Modal";
// import aiscipro_logo from "../../assets/images/aiscipro_logo.png";
// import buttonimage from "../../assets/images/buttonimage.png";
import "./hero.css";
// import { Link, useNavigate } from "react-router-dom";
import FOS from "../../assets/videos/Food Ordering App.gif";
import LMS from "../../assets/videos/Library Management App.gif";
import CBFS from "../../assets/videos/Facility Management App.gif";

// const propTypes = {
//   ...SectionProps.types,
// };

// const defaultProps = {
//   ...SectionProps.defaults,
// };

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);
console.log(videoModalActive)
  // useEffect(() => {
  //   document.getElementById('video1').play();
  //   document.getElementById('video2').play();
  //   document.getElementById('video3').play();
  //   document.getElementById('video4').play();
  // }, [])

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };
 console.log(openModal)
  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };
console.log(closeModal)
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <p className="m-0">
            <img
              className="img_center"
              src={require("../../assets/images/Aiscipro_webLogo1.png")}
              alt="Open"
            />
          </p>
          <h4
            style={{ color: "black", textAlign: "center", fontFamily: "Arial" }}
            className="mt-0 mb-16 reveal-from-bottom"
            data-reveal-delay="200"
          >
            <br></br>
            The Automatic Programming Platform
            {/* <span className="text-color-primary">startups</span>  */}
          </h4>
          <br></br>

          <div
            id="carouselVideoExample"
            className="carousel slide carousel-fade gif-carousal"
            data-bs-ride="carousel"
          >
          
          <div
              className="carousel-indicators"
              // style={{ background: "red", color: "black" }}
            >
              <button
                type="button"
                data-bs-target="#carouselVideoExample"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselVideoExample"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselVideoExample"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              {/* <button
                type="button"
                data-bs-target="#carouselVideoExample"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button> */}
            </div>

            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={FOS} alt="" />

                <div className="carousel-caption d-none d-md-block"></div>
              </div>

              <div className="carousel-item">
                <img src={LMS} alt="" />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>

              <div className="carousel-item">
                <img src={CBFS} alt="" />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
            </div>

           
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselVideoExample"
              data-bs-slide="prev"
            >
              <span
                style={{
                  backgroundColor: "black",
                  position: "absolute",
                  right: "200px",
                }}
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselVideoExample"
              data-bs-slide="next"
            >
              <span
                style={{
                  backgroundColor: "black",
                  position: "absolute",
                  left: "200px",
                }}
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div ></div>
        </div>
      </div>
      <h3 style={{ color: "black", textAlign: "center" }}>
        One Click Software Development
      </h3>
      <p >
        {" "}
        "Automatic programming that translates user requirements in natural
        <br></br> language to software application in one step" &nbsp;
        &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
      </p>
      {/* <h3 style ={{ color:'black',textAlign:"center"}}>One Click Software Development</h3>
          <p style={{textAlign:"justify",textAlign:"center"}}> "Automatic programming that translates user requirements in natural language to software application in one to nine simple steps"</p> */}

      <div className="container-xs">
        {/*   <ButtonGroup>
                  <Button className='button' tag="a" class="new" wideMobile href="http://platform.aiscipro.com/#/login">
                    GET STARTED
                  </Button>
                  </ButtonGroup> */}
        <a wideMobile href="https://stos.aiscipro.com">
          {" "}
          <button type="button" class="btn btn-dark " >
            <b>Get Started</b>
          </button>
          {" "}
        </a>
        {/*
                   <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                  </Button>
               
          <a wideMobile href="http://platform.aiscipro.com/#/login">      <img style={{ "height": "70px","width":"200px"}} src={require('../../assets/images/blackgetstarted.jpg')}  alt="..."/></a>
         */}

        <br></br>
        <br></br>
      </div>
    </section>
  );
};

// Hero.propTypes = propTypes;
// Hero.defaultProps = defaultProps;

export default Hero;
