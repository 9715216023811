import React from 'react'



function Industry() {
  return (
   <>
<div>

    <div className="container-sm">
    
   
      <div>
    
    
                <br/>
                <p class="h3">  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Industries</p>
                <br/>
                <br/>
                <div class="card-deck">
                <div class="card" style={{"width" : "18rem"}}  >
                <img style={{ "height": "230px","width":"180"}} src={require('./../../../assets/images/industryim.jpg')}class="card-img-top" alt="..."/> 
             
                
  <div class="card-body">
    <h5 class="card-title">Industry 1</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go to otherpage</a>
  </div>
</div>


<div class="card" style={{"width" : "18rem"}}  >
                <img  style={{ "height": "230px","width":"180"}}src={require('./../../../assets/images/industries2.jpg')}class="card-img-top" alt="..."/> 
             
                
  <div class="card-body">
    <h5 class="card-title">Industry</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>
</div>
                <br/>
                <br/>
                <div class="alert alert-primary" role="alert">
  A simple primary data with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
</div>

                <br/>
                <div class="alert alert-primary" role="alert">
  A simple primary data with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
</div>

                <br/>
                <div class="alert alert-primary" role="alert">
  A simple primary data with <a href="#" class="alert-link">an example link</a>. Give it a click if you like.
</div>

                <br/>
                <br/>
                
                
                <div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col">
    <div class="card h-100">
    <img style={{ "height": "230px","width":"180"}} src={require('./../../../assets/images/industries4.jfif')}class="card-img-top" alt="..."/> 
      <div class="card-body">
        <h5 class="card-title">Industry</h5>
        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
      </div>
      <div class="card-footer">
        <small class="text-muted">any muted text if any</small>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
    <img style={{ "height": "230px","width":"180"}} src={require('./../../../assets/images/industries5.jfif')}class="card-img-top" alt="..."/> 
      <div class="card-body">
        <h5 class="card-title">Industry</h5>
        <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
      </div>
      <div class="card-footer">
        <small class="text-muted">any muted text if any</small>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100">
    <img style={{ "height": "230px","width":"180"}} src={require('./../../../assets/images/industries6.jfif')}class="card-img-top" alt="..."/> 
      <div class="card-body">
        <h5 class="card-title">Industry</h5>
        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
      </div>
      <div class="card-footer">
        <small class="text-muted">any muted text if any</small>
      </div>
    </div>
  </div>
</div>      
    <br/>
    <br/>
    <div class="card text-center">
  <div class="card-header">
    Featured
  </div>
  <div class="card-body">
    <h5 class="card-title">Special title treatment</h5>
    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
  <div class="card-footer text-muted">
  
  </div>
</div>
    <br/>
    <br/>

    <div class="card text-center">
  <div class="card-header">
    Featured
  </div>
  <div class="card-body">
    <h5 class="card-title">Special title treatment</h5>
    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
  <div class="card-footer text-muted">
  
  </div>
</div>
    <br/>
    


      </div>
  

  
  


<br/>
<br/>
<br/>
           
        </div>
        </div>
        </>
    )
}

  


export default Industry;










