
import React from 'react'
import '../../../../App.css'
function AssistedautomaticProgramming() {
  return (
    <div className="AssistedAutoProgramming">

    <h4 style ={{ color: "black", textAlign: "center" }}>Assisted Auto Programming</h4>

 
    <p style={{fontSize: "medium", textAlign: "justify" }}>In Assisted Auto Programming, the requirements document that is uploaded is categorized into various feature types. User may re-categorize based on their requirement if any categorization is incorrect or less appropriate. Post this assistance in categorization, the software is automatically built and available for preview. If the software auto developed is not as per the requirements, the user at this level can iterate again and update both requirements list as well as feature categories.
</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 2 Orbit :
</p>
<img style={{ "height": "350px","width":"350px"}} src={require('../../../../assets/images/Assistedauto1.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 2 Spin :
</p>
<img style={{ "height": "350px","width":"350px"}} src={require('../../../../assets/images/Assistedauto2.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>The invention is an online platform that automatically creates software in 3 steps.
</p><img style={{ "height": "150px","width":"550px"}} src={require('../../../../assets/images/Assistedauto3.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>A three step automation across all stages of software development such as requirements gathering, systems specifications, standardization, concept design & modelling, system & component architecture, coding & integration, quality assurance, build, deploy, operate, maintenance, & enhancement.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Software requirements are usually provided in document formats such as scope of work (SOW) or Request For Proposal (RFP) or Business Requirements Specification (BRS) or Requirements document etc.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the first step, the aiscipro online platform User can upload any of the above mentioned documents.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The document is processed with an artificial intelligence based natural language processor. In this step the identification of required software features is automated. The identified features are then categorized into four groups based on the complexity & effort needed for development of the features.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A total cost estimate is presented to the User based on the number of features and the complexity. The User can also view the detailed break up of the costs if so desired.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The User is offered to go ahead with automatic building (development or creation) of software or to drop out at this stage.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The second step is initiated when the User opts to go ahead and build the software. In this step, based on the features required, software is automatically designed, architected, built automatically with systems approach, domain driven design, normalized systems concepts, model driven development & automatic programming tools.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the software is built, a preview is provided to the User to check if all the features as expected by the User are available in the software created automatically on the platform.
User is further provided an option to drop out at this stage by discarding the software or pay for the software and download the software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Third step is initiated on opting to download the software. In this step the User is redirected to the payments gateway page and on successful payment the user is provided with the software  (including the source code, design document, architecture, user manual, installation guide, quality assurance reports,   etc).
 
 </p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The platform may also offer the user to host the auto built software online at additional charge.
Thus in 3 steps a user can have a software developed for their requirements.
</p>
    </div>
  )
}

export default AssistedautomaticProgramming