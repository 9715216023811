import React from 'react';

import '../../../../App.css'
const Casestudies = () => {
  

  return (
    <>
   
     
      <div className="title-pages">
          <h3>Casestudies</h3>
        </div>
    
    </>
  )
}



export default Casestudies
