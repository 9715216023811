import React from 'react'

function Support() {
  return (
    <div style={{color:"black",textAlign:"center"}}>
    <br></br>
    
    <br></br>
    <br></br>
    
                        <div className="container">
                            <div className="footer-head">
                                 {/* <h3  style={{color:"black",textAlign:"center"}}>SALES</h3>
                                <div  style={{color:"black",textAlign:"center"}} class="footer-contacts">
                                    {/* <p>Mobile: +91 9010711411</p>
                                    <p> &nbsp;  &nbsp; Phone: +91 40 27803303</p> */}
                                    {/* <i class="fa fa-envelope-o"></i>
                                    <p> &nbsp;  &nbsp; &nbsp;help@aiscipro.com </p>
                                </div>   */}
                                <h3  style={{color:"black",textAlign:"center"}}>SUPPORT</h3>
                                <div  style={{color:"black",textAlign:"center"}} class="footer-contacts">
                                    {/* <p>Mobile: +91 9652478658</p>
                                    <p> &nbsp;  &nbsp; Phone: +91 40 27803303</p> */}
                                     <i class="fa fa-envelope-o"></i>
                                     <p>help@aiscipro.com </p>
                                     <i class="fa fa-envelope-o"></i>
                                    <p>marketing@aiscipro.com </p>
                                </div>
                            </div>
                        </div>
                    
    
    </div>
    
  )
}

export default Support