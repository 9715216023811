import React from 'react'
import "../../../../../App.css"
export default function Blog11() {
  return (
    <div className='blog'>
        <div>
        <p style={{ color: "blue", textAlign: "center" }} >How the understanding of Process vs Data dichotomy helps in developing optimal software?</p>
        </div>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>One of the root causes for complexity in software is due to Process vs Data dichotomy. It is
fundamental to the software world.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>Innumerable efforts have been expended in the field of computer science to bridge Process
and Data schism without realizing the true nature of this dichotomy.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>To meet its requirements, a software application needs to be both a process as well as data.
However, ideal software can be optimized to be one or the other, not both at the same
time.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>Firstly, we need to understand that this dichotomy arises out of the innate nature of the
universe (or our mental makeup of perception). Secondly, that it cannot be bridged so
we need to take a side clearly. Then, we will be able to create ideal software systems
and, simultaneously, save substantial time and effort in creating systems that are
suboptimal.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>So before we start any software development project, we need to understand the true nature
of the intended software in terms of whether it is a Process or Data. Based on the
essential nature we should decide the design, the structure, the programming language,
user interface, and other needed building blocks of the software.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>This elemental understanding will guide us in every step of the software development
process which is laden with trade off at every turn of the path.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>For instance, transactional applications such as communicative, collaborative, ecommerce,
financial transactions, etc are Process softwares by nature and need to be designed and
developed in a process oriented approach. For example, functional or procedural
languages such as C, F#, etc are ideal for such applications. Object oriented languages
will be suboptimal though it will do the job. Object oriented languages are ideal for
systems such as Document Management System, Survey Form, HR applications, ERPs,
etc. which are more data intensive and less process.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>Process and data are as orthogonal as waves and particles. It is the same orthogonality that
we see in state and transition dichotomy. Though we need both and cannot do one
without the other, in most of the softwares, the essential nature or theme is either
Process or Data.</p>
        <p style={{ fontSize: "medium",textAlign:"justify" }}>Identifying the underlying theme is a north star in taking right decisions on software design
and development choices. It is one significant step closer to building optimal softwares.</p>
       
        
        </div>
  )
}
