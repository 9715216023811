import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './Logo.css'

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div 
      {...props}
      className={classes}
    >
      <spacer>
      <p className="m-0">
        <Link to="/">
          <br></br>
          <img style={{float:'left'}}
            src={require('../../../../src/assets/images/Aiscipro_webLogo.png')}
            alt="Open"
            width={135}
            height={120} />
  
          <p className="first-txt" style={{color:"black",fontSize:"9px",paddingTop:"0",textAlign:"center",paddingLeft:'4px', margin: 0,
  marginBlock:0,
    border: 0,
    outline: 0}}><i>'i-see-pro'</i> 
    {/* <span style={{fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'black',
    fontSize: '13px'}}>beta</span> */}
    </p> 
        </Link>
      </p>
      </spacer>
    </div>
  );
}

export default Logo;