import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import './FeaturesTiles.css';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: "",
    // paragraph:<p style={{textAlign:"justify",textAlignLast:"left"}}> "Automatic programming that translates user requirements in natural language to software application in one to nine simple steps"</p>
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div>
<center><h3 style ={{ color:'black'}}>Innovative Features</h3></center>
</div>
      <div id="aisciprosection" className="container">
      {/* <h3 style ={{ color:'black',textAlign:"center"}}>One Click Software Development</h3> */}
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} id="centercon" className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci11.gif')}
                      alt="Features tile icon 01"
                      width={180}
                      height={180} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Instantaneous Production Ready Enterprise Software
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  Real Time automatic software design & code generation with latest AI.
                    </p>
                </div>
              </div>
            </div>


            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci7.gif')}
                      alt="Features tile icon 06"
                      width={170}
                      height={90} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Customizable Future Ready Software                   </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  Highly adaptive code design and evolvable software constructs based on latest software engineering discoveries                 </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci8.gif')}
                      alt="Features tile icon 06"
                      width={170}
                      height={90} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Smart Operations & Enhancements
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  AI self learning and enhancement recommendations               </p>
                </div>
              </div>
            </div>
           

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci10.gif')}
                      alt="Features tile icon 03"
                      width={160}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Automated Maintenance Free Deployment
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  User transparent deployment on maintenance free infrastructure                    </p>
                </div>
              </div>
            </div>

           
            

          </div>
        </div>
      </div>



      {/* salient features */}
      <div>
<center><h3 style ={{ color:'black'}}>Salient Features</h3></center>
</div>        

      <div id="aisciprosection" className="container">
      {/* <h3 style ={{ color:'black',textAlign:"center"}}>One Click Software Development</h3> */}
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} id="centercon" className="center-content" />
          <div className={tilesClasses}>

            


<div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci4.gif')}
                      alt="Features tile icon 04"
                      width={184}
                      height={184} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Autogenerated Software Artifacts
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  Downloadable SRS, System Architecture, UML Diagrams, Database Design, UI Stylesheets, Usermanual, QA reports, Troubleshooting Guide, and more  
                    </p>
                </div>
              </div>
            </div>


            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci5.gif')}
                      alt="Features tile icon 05"
                      width={242}
                      height={184} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Open Architecture & Robust Design
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  Evolvable architecture and proven design                    </p>
                </div>
              </div>
            </div>


            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci6.gif')}
                      alt="Features tile icon 06"
                      width={140}
                      height={70} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Responsive Multi Screen UI
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  Device Native dynamic UI                   </p>
                </div>
              </div>
            </div>


           


            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/aisci12.gif')}
                      alt="Features tile icon 06"
                      width={260}
                      height={200} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Extremely Agile                   </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  With stakeholder inputs, under stakeholder watch, truly agile iterative & incremental development                   </p>
                </div>
              </div>
            </div>




            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/asci2.gif')}
                      alt="Features tile icon 02"
                      width={230}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Selectable Technology Stack
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  Support for industry standard technology stacks
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/asci2.gif')}
                      alt="Features tile icon 02"
                      width={230}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4  style={{ color:'black'}} className="mt-0 mb-8">
                  Seamless Software
                    </h4>
                  <p style={{textAlign:"justify",textAlignLast:"left"}} className="m-0 text-sm">
                  Plug and Play integration with industry standard apps 
                    </p>
                </div>
              </div>
            </div>





           
            

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;