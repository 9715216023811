

import '../../../../App.css'
import React from "react";



export default function Articles() {
 
  return (
   
    <div className="title-pages">
 
    <h3>Articles</h3>
 </div>
  );
}
