

export function FeaturePricing() {

    



   
    return (
      <div className="title-pages" style={{marginTop:"20px",textAlign: "center"}}>

        <h2 style={{fontSize:"28px",textAlign:"center",color:'black'}}>Feature Pricing</h2>
  
        <table style={{ margin: "0 auto", width: "1090px", border: "1px solid black", boxShadow: "-1px 2px 2px 2px grey" }}>
          <thead>
            <tr style={{width:"10px",borderRight:"1px solid black"}}>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>S.No</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}} ><b>Type</b></td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}><b>Category</b></td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} ><b>Description</b></td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}><b>EXAMPLE
</b></td> <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}><b>COST PER FEATURE REQUIREMENT

</b></td>

            </tr>
          </thead>
          <tbody style={{width:"10px",borderRight:"1px solid black"}}>
          <tr style={{width:"10px",borderRight:"1px solid black"}}>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>1</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>BASIC</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>FUNCTIONAL</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral',paddingLeft:"10px"}}>An SoW requirement that is about capturing data, saving, viewing, modifying, deleting, are grouped under BASIC feature type
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>The HRMS app should be able to Add, Edit, Delete, Import, Export, Print, Copy, Duplicate, View employee details.
</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$1.00
</td>

            </tr>
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>2</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>Work Flow</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >                An SoW requirement that is about a process or function. That is, requirements that involve multiple steps to accomplish a task.
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Such as the leave approval process. Credit card validation process. Etc.
</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$3.00</td>

            </tr>
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>3</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>Performance</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}}>              Performance related requirements such as scalability, extendability, robustness, etc.

</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Within 2 seconds app response. Scalability to 10k concurrent connections, etc

</td> 
<td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$5.00</td>

            </tr>
            
            
          
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>4</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}} >OPTIMIZATION
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >           Requirements that are optimizations for their specific needs.


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Adaptive features e.g. on handheld, mobile, 128kbps network, etc


</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$7.00</td>

            </tr>
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>5</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>INTEGRATION
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >            Requirements that ask for integration with other third party softwares.


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Database to be Oracle, Integration with SAP, Gmail, WhatsApp


</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$10.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>6</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>INFORMATION

</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>FUNCTIONAL</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}}>           Reports, Dashboards, Website (public info access)



</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Employee leave report. Asset inventory report.



</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$3.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>7</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}} >PRIVILEGE

</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>FUNCTIONAL</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}}>        Roles, Authority, Access Control, etc



</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>HR role should be the final approval authority of leave. The Project Manager should assign members to the project.



</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$5.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>8</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>SECURITY

</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >          Encryption, Password protection, etc



</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>All data in transit needs to be encrypted. Data in the database should be obfuscated.



</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$8.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>9</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>BUSINESS CONTINUITY

</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >           Disaster Recovery, Backup, and Business Continuity requirements.


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Backup, Archival, Data Storage for 5 years, etc


</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$10.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>10</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>UXUI

</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}}>        Look, Color, Feel, Responsiveness



</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>The background should be white. Messages should be in blue. Layout should be 2 vertical frames.



</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$3.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>11</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>DOCUMENTATION


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >     Help, Manual, Documentation, Etc.




</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Help should be available along with the software for each user. The User Manual should have snaps of all screens.




</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$6.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>12</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>INFRASTRUCTURE


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >        Servers, PCs, etc Hardware to be provided along with the application



</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>The solution should run on Linux OS and on Sun Sparc servers.




</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$3.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>13</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>QUALITY ASSURANCE


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >      Quality, Testing, Certifications, other requirements on software. Etc.




</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>The software should be certified by STQC. The software should be tested with at least 250 unique test cases.



</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$7.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>14</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}} >COMPLIANCE


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>FUNCTIONAL</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}}>     Compliance to any third-party certifications, standards, etc.




</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>"The Solution must have ability to support the lnternational Organization for Standardization (lSO) 19770-2 standard for
Software ldentification (SWID) tags."


</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$12.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>15</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}} >AUDIT TRAIL


</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >     Audit trail of activities, data changes, transactions, etc.




</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>"The system should generate audit trail and audit logs reporting.

Ability of system to enable documenting and managing changes to role definition over time including audit trail of all changes."



</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$9.00</td>

            </tr>   
            <tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>16</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>COMPLIANCE

</td>
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >     Any kind of time based activity that needs to be rescheduled.





</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>"The solution should support scheduled scanning of all lP based Endpoints

The Agent should run automatically without manual intervention in Desktop, Laptop & servers

The report scheduler auto generation and distribution analyze trends, performance level, real times updates on reports, save and store customized reports"


</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$3.00
</td>

</tr>
<tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>17</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>AUDIT TRAIL

</td>
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>NON-FUNCTIONAL
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >   Audit trail of activities, data changes, transactions, etc.






</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>"The system should generate audit trail and audit logs reporting.

Ability of system to enable documenting and managing changes to role definition over time including audit trail of all changes."

</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$9.00
</td>

</tr>
<tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>18</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>MAINTENANCE


</td>
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>NON-FUNCTIONAL
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >  Post production support features such as Change Management, Ticket Raising, Autodiscovery, Reporting etc which are not part of development and needed using third-party tools.







</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>The asset management app should have ticketing and change management module for user to submit issues and change requests 

</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$6.00
</td>

</tr>
<tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>19</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>SETTINGS



</td>
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>FUNCTIONAL
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >  Model changing feature requirements. Dynamic or configurable new attributes to entities, etc. Templates,








</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>"The solution must have unlimited number of Asset Types and fields

The solution must have ability to track changing software license structures

The solution must have Model/Template for inheriting lT Asset properties/attributes & ability to modify model/templates to add additional properties / attributes"


</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$15.00
</td>

</tr>
<tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>20</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>MAINTENANCE




</td>
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} > Post production support features such as Change Management, Ticket Raising, Autodiscovery, Reporting etc which are not part of development and needed using third-party tools.









</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>The asset management app should have ticketing and change management module for user to submit issues and change requests 


</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$6.00
</td>

</tr>
<tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>21</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>ARCHITECTURE





</td>
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>Non-FUNCTIONAL
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >Any requirement sentence that speaks about the architecture feature of the proposed software.










</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>SI shall identify microservices for implementation of the ISCMS system.



</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$30.00
</td>

</tr>
<tr>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'aquamarine'}}>22</td>
              <td  style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgreen'}}>DESIGN






</td>
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightgray'}}>FUNCTIONAL
</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:'lightcoral'}} >Any requirement sentence that mentioned modular design of the proposed software.











</td>
              <td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightblue"}}>Module for Home Based NewBorn Care (HBNC),




</td> 
<td style={{width:"10px",borderRight:"1px solid black",backgroundColor:"lightsalmon"}}>$20.00
</td>

</tr>
</tbody>
      </table>
      </div>
  
   
       


     

    );
  }
  