import React from 'react'
import "../../../../../App.css"


function Blog4() {
  return (
<>
<div className="blog4">
<br/><br/>
<div>

<p style ={{ color: "blue", textAlign: "center" }}>Role of an ENTITY in domain modelling in aiscipro platform</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>An ENTITY in aiscipro is a first class citizen for domain modelling. They are fundamental to
modelling.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Domain models are built on the</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>- Nature of entities<br></br>
- Relationship between them<br></br>
- Interactions among the entities.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Entities form the foundational nodes on which the rest of the domain structure is designed.
Entities modelling are broadly of two in nature,</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>1. Solo Entity<br></br>
2. Aggregate Entity</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Solo Entity do not have any subentities. That is they are not composed of any other subentities as
part of its structure.<br></br>
Example: Employee is a standalone Solo Entity</p>
<img class="center" style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog4image.png')}alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>Aggregate Entity have one or more entities as subentities. These sub entities are invariant with
respect to Aggregate Entity<br></br>
Example: Order aggregate Entity with Order Item subentity.<br></br>
Aggregate Entity is also called as Root Entity.<br></br>
The entire groups of an Aggregate entity with root &amp; subentities are called as an Aggregate.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A subentity cannot have an independent existence. That is subentity will be deleted if aggregate
root entity is deleted in the system.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Further, subentities cannot be accessed directly by other entities outside the root. It can access
it only through root entity.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A Solo Entity can be considered as an Aggregate Root Entity with zero sub
entity. So in aiscipro two types of Entities are modelled<br></br>
1. Aggregate Root Entity<br></br>
2. Sub Entity</p>
</div>

</div>
</>
    )
  
}

  


export default Blog4
