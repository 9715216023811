import React from 'react'
import "../../../../../App.css"


 export function Blog1() {
  return (
    <>
   
    <div className="blog1">
    <div>
    
      <p style={{ color: "blue", textAlign: "center" }} >Is Automatic Programming ever possible?</p></div>
    <div>
<p style={{ fontSize: "medium",textAlign:"justify" }}>
If software can be generated by a computer when requirements are provided in human natural
language then it is truly automatic programming. Otherwise it is still manual programming or
programming with automation tools.</p>
<p style={{ fontSize: "medium",textAlign:"justify" }}>
As long as humans have to put an effort to rework their language to make it easier for the
computer to understand the human’s intentions, expectations or requirements, we are still short
of automatic programming.</p>
<p style={{ fontSize: "medium",textAlign:"justify" }}>Software creation today is automated to substantial extent with automation tools such as
reusable libraries, prebuilt frameworks, intelligent programming languages with IDEs, and so on.
However, substantial software developed today is still manpower intensive in both cost as well
as efforts.</p>
<img class="center" style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/BLOG1.jpg')}alt="..."/>
<p style={{ fontSize: "medium",textAlign:"justify" }}>
Lowcode and nocode platforms have raised the level of automation in software production
however they are still not fully automatic. The manual effort has shifted from low level coding to
a higher level of modeling, designing, or selection.</p>
<p style={{ fontSize: "medium",textAlign:"justify" }}>Automatic programming is reached when a computer can understand the requirements, model
the system, design the software, code the program, deploy the software, and further
automatically be able to enhance and redeploy the software on instruction of the humans in
natural language.</p>
<p style={{ fontSize: "medium",textAlign:"justify" }}>Automatic programming is when the computer can itself model, design, select, and function
when instructions are given as to another human being.
Automatic programming is the final frontier of software automation.
So is automatic programming truly possible?</p>
<p style={{ fontSize: "medium",textAlign:"justify" }}>
Well, like everything else in the universe, the answer is NO (in particle nature) and YES (in
wave nature).
The answer is NO more cause of the challenges with natural language which could be
ambiguous, inconsistent, missing, or incorrect. Natural language sentences are highly
susceptible to multiple interpretations. Even simple one line sentences could be ambiguous or
highly contextual.</p>
<p style={{ fontSize: "medium",textAlign:"justify" }}>
The answer is YES if the natural language drawbacks are overcome with emerging AI
technologies such as natural language understanding with machine learning. Further, new
discoveries in software engineering &amp; computer science combined with advanced code
generation, integration, and deployment tools have made it possible to program in natural
language i.e. automatic programming.</p>
<p style={{ fontSize: "medium",textAlign:"justify" }}>
It will be possible to automatically generate software for a significant number of requirements
provided in scope documents. If not entirely, then at least to a substantial degree such that it will
flip the present human cost of software development from major part to a minor part.</p></div>
</div>
    </>
  )
  
}

  


