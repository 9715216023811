import React from 'react';

// import sections
import Hero from '../components/sections/Hero';

import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Testimonial from '../components/sections/Testimonial';
import Clients from '../components/sections/Clients';
import Certifications from '../components/sections/Certifications';


const Home = () => {

  return (
    <>
      <Hero/>
      

      
      <FeaturesSplit />
      <FeaturesTiles />
      <Clients />
      <Certifications />
      <Testimonial topDivider />
    </>
  );
}

export default Home;