import React from 'react'
import "../../../../../App.css"


function Blog7() {
  return (
<>
<div className="blog7">

<div>

<p style ={{ color: "blue", textAlign: "center" }}>Ways to design DDD-Aggregate in Object Oriented Programming (OOP)</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>From OOP point of view, a DDD-Aggregate is a structural and behavioral design pattern
wherein a primary root object owns, contains, and is responsible for other objects under it.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The Aggregate comprises the primary root object and all the other sub objects. It is a kind of
compositional design pattern.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>For the external world the primary root object is the only interface and means to access and
interact with the aggregate including the inner objects.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Outside the root object context, the sub objects do not have any independent significance.
For instance, if we consider a Family Aggregate with sub entities Members. Unless Family
Aggregate exists Members individually are out of context and not meaningful.</p>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog7final.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>Similarly, if we consider the oft cited Order and OrderItem example, OrderItem exists in the
context of Order only.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>OrderItem, if treated on its own individually without the Order Aggregate, could lead to
corruption of the business logic or inconsistent behaviour of the software.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Thus, the point so far is that sub objects are well contained within the logical or semantic
boundary of the aggregate root object.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Therefore, aligning the implementation of Aggregate to from the above conclusion here ,
following ways<br></br>
1. Contain by Value<br></br>
2. Contain by Reference<br></br>
3. Contain by ID</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In Contain by Value, the inner objects are carried in whole by the aggregate root object. For
example, using private inner or nested classes in OOP.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In Contain by Reference, the reference to sub objects are carried by the aggregate root. For
example, using object references in OOP.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In Contain by IDs, the unique IDs of sub objects are carried by the aggregate root. For
example, using list or array of IDs.</p>
</div>
</div>
</>
    )
  
}

  


export default Blog7
