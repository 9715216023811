import React from 'react'
import "../../../../../App.css"
export default function Blog9() {
  return (
  <>
    <div className="blog9">
<div>
<p style ={{ color: "blue", textAlign: "center" }}>What are the ways to design Notifications in software?</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>What is a Notification?</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>By Notifications here we mean information messages sent through alternate channels such as
Email, SMS gateway, Whatsapp chat, etc. Such Notifications are information signals that may
be of interest to the user of the software or external agent.
    
</p>

<p style={{fontSize: "medium", textAlign: "justify" }}>These kinds of Notifications go beyond the software generating it and are intended for the
software user or a third party person or program. Further, it is through a medium beyond the
application generating the message. Thus, the significance of “alternate” channels.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>For instance, successful enrollment of an Employee notification may be of interest to the HR
Manager and may want to receive an email on it. Arrival to consignment at the destination is of
interest for the customer so a notification needs to be sent to customer mobile.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Within the software, Notifications are part of the application and therefore will be modeled as an
entity or class of the software. It will interact with other entities or objects of the same software.
Those notifications are not the subject of this topic.</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>Event vs Notification</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>Event is an occurrence or happening of something. Notification is communication of information
about the event. Events impact the state and therefore carries particulars of the data.
</p>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog9image.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>Every Event may not raise a notification. Notifications need not be generated by Events alone.
Further, if a Notification was generated by an Event, the Notification may or may not carry the
Event data. Even if Event data is carried by the Notification it may or may not be in full.</p>

<strong><p style={{fontSize: "medium", textAlign: "left" }}>What are the ways to design Notification?</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>There are many ways to model Notification, including:
1. Notification as an Aspect
2. Notification as an Action
3. Notification as a Object Property
4. Notification as a Module
5. Notification as a operation privilege in User Access Control
6. Combination of the above</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>Notification as an Aspect</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>It is interesting to consider Notification as a cross cutting concern and design it as a separate
aspect with aspect oriented programming.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Notification and all related configurations &amp; customizations could be housed separately and will
be available across the code. This will result in high reusability and clean demarcation of
Notification functionality.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In softwares that do not have Notification as the core part of the application, separating
Notification as an Aspect makes good sense. However, if Notification is a core part of the
functionality then it needs to be part of the process of interaction between objects (assuming
object oriented programming).</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>Notification as an Action (or Task)</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>When external Notification is one of the the core parts of the functionality of the application.
Then it needs to be designed that way. That is, Notification will be an active part of the interplay
between entities (or objects) to finally provide the intended feature of the software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Notification here is ideally designed as an independant Action (or Task) that may be called upon
in the workflow or process when an information message needs to be passed on to an alternate
channel as per the domain logic.</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>Notification as a Entity Attribute/Object Property</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>In some applications Notifications are ideally designed as the attribute of the entity or property
of the object. That is all objects of the domain have notification capability in-built within them.
This property (or function) may be called upon as desired in the context of interaction between
the objects or as desired.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Notifications in such applications usually tend to be of higher significance (or it could be
communication intensive applications). For instance, if the Notification setting is enabled in
Email, every Email object needs to call Notification with, probably, a subject brief to the
recipient.</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>Notification as a Module</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>Notification may be treated as a separate package or library with configurable rules for
information communication to third party channels. This is a conventional style that is observed
in most of the applications today.</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>Notification as a operational privilege in User Access Control</p></strong>

<p style={{fontSize: "medium", textAlign: "left" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Notification can also be modeled as an operational privilege in User Access Control. That is, in
addition to feature or data access, a user can be provided notification as a privilege. So

whenever the software feature such as a Form or Report is submitted or accessed, a notification
may be triggered to configured recipients.</p>
<strong><p style={{fontSize: "medium", textAlign: "left" }}>Some or all combination of the above</p></strong>
<p style={{fontSize: "medium", textAlign: "justify" }}>Depending on the context application and the design perspective taken into consideration,
alternate channel Notifications can be designed in more than one way in the same software
including some of the above or even all of the above.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the next blog we will cover the comparison of the above alternatives in Notification design
and the context where one approach is more preferable to the next.</p>

</div>
</div>
</>
  )
}
