import React from 'react'
import '../../../../App.css'
const Onedata = () => {

  return(
  <div className="AutomaticProgramming">

      <h4 style ={{ color: "black", textAlign: "center" }}>STOS</h4>
    <p style={{textAlign:"center",fontSize:"16px"}}>SOW (Scope of Work) to SAP (Software Automatic Programming)</p>

    <div className="container-xs">
        
        <a wideMobile href="https://stos.aiscipro.com">
          {" "}
          <button type="button"  style={{marginLeft:"260px",width:"130px",borderRadius:"25px",backgroundColor:"black"}} >
            <b style={{fontSize:"15px",color:"white"}}>Get Started</b>
          </button>
          {" "}
        </a>
       

       
      </div>
      <br/>

    <p style={{fontSize: "medium", textAlign: "justify" }}>In Auto programming level user inputs software requirements in natural language and converts into software. If the desired features of the software are not available or not as per the requirements, then the user updates the requirements document and again converts to software. The process is repeated till all the desired features are achieved on the software.
</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 1 Orbit :
</p>
<img style={{ "height": "300px","width":"350px"}} src={require('../../../../assets/images/Auto1.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 1 Spin :
</p>
<img style={{ "height": "250px","width":"300px"}} src={require('../../../../assets/images/Auto2.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>The invention is an online platform that automatically creates software in 1 step.
</p>
<img style={{ "height": "200px","width":"200px"}} src={require('../../../../assets/images/Auto3.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>A one step automation across all stages of software development such as requirements gathering, systems specifications, standardization, concept design & modelling, system & component architecture, coding & integration, quality assurance, build, deploy, operate, maintenance, & enhancement.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Software requirements are usually provided in document formats such as scope of work (SOW) or Request For Proposal (RFP) or Business Requirements Specification (BRS) or Requirements document etc.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the aiscipro online platform User can upload any of the above mentioned documents.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>On selection, the document is processed with an artificial intelligence based natural language processor. The identification of required software features is carried out. The identified features are then categorized into four groups based on the complexity & effort needed for development of the features.

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A total cost estimate is presented to the User based on the number of features and the complexity. The User can also view the detailed break up of the costs if so desired.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The User is offered to go ahead with automatic building (development or creation) of software along with hosting or to drop out at this stage .
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The single step is initiated when the User opts to go ahead to build & host the software. In this step, based on the features required, software is automatically designed, architected, built automatically with systems approach, domain driven design, normalized systems concepts, model driven development & automatic programming tools.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the software is built, the platform will host the auto built software online and a super admin view is provided to the User to start utilizing and operating all the features as expected by the User.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Along with the hosted application User is also intimated on the next date for billing of the hosted software along with the payments gateway link.


</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Thus in 1 step or single submission a user can not only have a software developed for their requirements but also have it hosted and readily available for use.</p>

<div className="container-xs">
        
        <a wideMobile href="https://stos.aiscipro.com">
          {" "}
          <button type="button"  style={{marginLeft:"260px",width:"130px",borderRadius:"25px",backgroundColor:"black"}} >
            <b style={{fontSize:"15px",color:"white"}}>Get Started</b>
          </button>
          {" "}
        </a>
       

        <br></br>
        <br></br>
      </div>
    </div>
  ) 
}

export default Onedata;