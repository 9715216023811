import React from 'react'
import "../../../../../App.css"


function Blog2() {
  return (
    <>
    <div className="blog2">

        <div>
       
        
        <p style ={{ color: "blue", textAlign: "left" }}>Why are Business Rules not Cross Cutting Concerns?</p>
            <p style={{ fontSize: "medium", textAlign: "justify" }}>
                I have come across many instances of implementation of business rules as a cross cutting
concern (CCC) with Aspect Oriented Programming (AOP) frameworks.
            
                I feel it is an inadequate understanding of the cross cutting concern concept that even business
rules which are core part of the business logic is being treated as a separate aspect and that too
a cross cutting one.</p>
             <p style={{fontSize: "medium", textAlign: "justify" }}>By definition Cross Cutting Concerns are non-functional aspects of a program that need to run
along with functional aspects for achieving the desired results as expected by the programmer
or user.
Cross cutting concerns span across different layers both vertically as well as horizontally. They
cut across boundaries and layers that is how they derive their name from.</p>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog2final.jfif')}class="center" alt="..."/>
<br></br>
          
            <p style={{ fontSize: "medium", textAlign: "justify" }}>Further, cross cutting: First and foremost, Business Rules are a functional aspect of a program so they are not CCC
from that point of view.
Secondly, Business Rules are limited to the domain layer. They do not cross application or
infrastructure or UI layers considering hexa architecture.
So Business Rules do not comply with both the basic conditions of CCC. Therefore Business
Rules are not CCC.</p>
           <strong><p style ={{ fontSize: "medium", textAlign: "left" }}>Why is this confusion of Business Rules as CCC? </p></strong> 
            <p style={{fontSize: "medium", textAlign: "justify" }}>It is a case of AOP/CCC taken too far and in too detail. Business Rules are an aspect of the
domain and therefore may seem like a potential candidate for AOP CCC implementation.
</p>
            <p style={{fontSize: "medium", textAlign: "justify" }}>Implementation of business rules as a separate aspect CCC could serve the purpose in many
cases however it could potentially complicate otherwise a simple approach.</p>
            <p style={{ fontSize: "medium", textAlign: "justify" }}></p>
            <p style={{ fontSize: "medium", textAlign: "center" }}></p>
            <p style={{ fontSize: "medium", textAlign: "center" }}></p>
        </div>
        </div>
    </>
  )
}
export default Blog2