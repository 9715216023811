import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
// import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import './Clients.css';
import OrbitalModel from '../../assets/videos/Orbital Model.gif'
import StarModel from '../../assets/videos/starmodel.png'
import TimelessModel from '../../assets/videos/Timelessmodel.png'


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  // const sectionHeader = {
  //   title: 'Interactive Visual Real- Time Development',
  //   paragraph: 'Interactive Drag & Drop software modelling designing development and deployment in real time.'
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
      {/* <h3 style ={{ color:'black',textAlign:"center"}}>One Click Software Development</h3>
          <p style={{textAlign:"justify",textAlign:"center" }}> "Automatic programming that translates user requirements in natural<br></br> language to software application in one step" &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;</p> */}
        <div className={innerClasses}>
        {/*
         <SectionHeader style={{color:"black"}} data={sectionHeader} className="center-content" />*/}
         <h3  style={{color:"black"}}  className="center-content">Interactive Visual Real- Time Development</h3>
         <br></br>
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div style={{color:"black"}} className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 style={{color:"black"}} className="mt-0 mb-12">
                  Orbital Model
                  </h3>
                <p className="m-0">
                  <ul>
                    <li>Production Ready Custom Software</li>
                    <li>AI charged agile development in 8 iterative incremental steps</li>
                    <li>Get Started</li>
                  </ul>
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={OrbitalModel}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                {/* <div style={{color:"black"}}  className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 style={{color:"black"}}  className="mt-0 mb-12">
                  Star Model
                  </h3>
                <p className="m-0">
                <ul>
                    <li>Configurable Visual Software Modelling</li>
                    <li>Highly configurable technology stack combinations for varied software development needs Easy drag & drop remodelling of auto generated software</li>
                    <li>Get Started</li>
                  </ul>
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={StarModel}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/* <div style={{color:"black"}} className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 style={{color:"black"}}  className="mt-0 mb-12">
                Timeless Model
                  </h3>
                <p className="m-0">
                <ul>
                    <li>Instantaneous Software Production</li>
                    <li>Interactive Real-Time on-the-spot software model design, code generation, and production deployment</li>
                    <li>Get Started</li>
                  </ul>
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={TimelessModel}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

           

           

          </div>
        </div>
      </div>


     
    <br></br>

{/*    
    <div>
    <div class="container-fluid">

<div id="clients" >
<center><h3 style ={{ color:'black'}}>Clients & Partners</h3></center>
</div>
<br></br>
<div  id="clients" class="clients">
<div class="row no-gutters clients-wrap clearfix">
<div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/TATA.png')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/WIPRO.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/LandT1.jfif')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Honeywellhd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/SAP.png')} class="img-fluid" alt=""/>
            </div></div>
           

</div>
<div class="row no-gutters clients-wrap clearfix">
            <div class="col"> <div class="client-logo">
              <img style={{ "height": "100px","width":"100px"}}  src={require('./../../assets/images/clients/42_Chowrange.png')}class="img-fluid"  alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Accenture.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Azurehd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/BHEL.png')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/CDAC.png')} class="img-fluid" alt=""/>
            </div></div>

</div>
<div class="row no-gutters clients-wrap clearfix">
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/xerox.jpg')}class="img-fluid"  alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/CISCO.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img  style={{ "height": "180px","width":"150px"}} src={require('./../../assets/images/clients/Punj-Lloydhd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Novartis_logo.png')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/ISB.jpg')} class="img-fluid" alt=""/>
            </div></div>

</div>
<div class="row no-gutters clients-wrap clearfix">
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/HAL.png')}class="img-fluid"  alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/Continental.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/HIDhd.png')} class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img style={{ "height": "180px","width":"150px"}}  src={require('./../../assets/images/clients/trimblehd.svg')}class="img-fluid" alt=""/>
            </div></div>
            <div class="col"> <div class="client-logo">
              <img src={require('./../../assets/images/clients/HP.png')} class="img-fluid" alt=""/>
            </div></div>

</div>

</div>
    </div>
    </div> */}
       {/* <br></br>
       <br></br>
    

      <div id="certification" class="container" >
      <div>
<center><h3 style ={{ color:'black'}}>Certifications</h3></center>
</div>
<br></br>
<div  id="certifications" class="certifications">

        <div class="row no-gutters clients-wrap clearfix">

          <div class="col-lg-3 col-md-4 col-6">
            <div class="ct-logo">
            <img src={require('./../../assets/images/certifications/GDPR.png')}class="img-fluid"  alt=""/>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="ct-logo">
            <img src={require('./../../assets/images/certifications/ISO 27001.png')}class="img-fluid"  alt=""/>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-6">
            <div class="ct-logo">
            <img src={require('./../../assets/images/certifications/SOC 2.png')}class="img-fluid"  alt=""/>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-6">
            <div class="ct-logo">
            <img src={require('./../../assets/images/certifications/iso9001.png')}class="img-fluid"  alt=""/>
            </div>
          </div>

          
       

          

       

      

         
         
          </div>


        </div>
</div>
      <br></br> */}
    </section>
    
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;