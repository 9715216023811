
import React from 'react'
import Iframe from 'react-iframe'
import '../../../../App.css'
import {useState} from "react";
import Data from "./mockdata.json"
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook,faTwitter,faInstagram,faYoutube} from '@fortawesome/free-brands-svg-icons'

function Whitepapers() {
 
  return (
    <div className="title-pages ">
      

    </div>
  )
}

export default Whitepapers