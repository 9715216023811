import React from 'react'
import {useEffect } from 'react';
import ReactGA from 'react-ga';
import '../../../../App.css'
function Orbital() {

  //pageview google analytics interactive

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  },[]);
//.end of pageview google analytics
  return (
    
    <div className="Orbital">




{/*     
//     <h4 style ={{ color: "black", textAlign: "center" }}>Orbital model</h4>
//     <p style={{fontSize: "medium", textAlign: "justify" }}>The orbital model includes the plurality of levels/orbits for the automatic development of software. The computer-implemented system is based on an orbital approach, wherein the process is run on a loop until the requirements of the user are met. 
// </p>
// <img style={{ "height": "650px","width":"580px"}} src={require('../../../../assets/images/Orbitalimage.png')}class="center" alt="..."/>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The plurality of levels/orbits include an auto programming level, an assisted auto programming level, a visual programming level, a low-code programming level, a configuration programming level and a Cloud Integration and Deployment (CICD) level.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The computer-implemented system includes a server for hosting a UI infrastructure on a device such as server, laptop and computer.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>In an embodiment, the user can go to different levels in a sequential manner. In an embodiment, the user cannot go back to a previous level. In an embodiment, the user cannot skip a level to reach the higher level.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The auto programming level requires input from the user in a natural language. The user input is processed through artificial intelligence based Natural Language Processing (NLP) to convert into a blueprint of the required software. The software features of the input will be automatically programmed into software. In cases, when the desired features are not available or not as per the requirements, then the user updates the input and again is converted into the software. The process is repeated until all the desired features are incorporated into the software. In an embodiment, the process is run in a loop until all the desired features are incorporated into the software.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The assisted auto programming level includes categorization of requirements into a plurality of feature groups or domain design terms. The requirements are categorized into the plurality of feature groups through an artificial intelligence based Natural Language Processing (NLP) method. In cases, when the categorization is incorrect or less appropriate, the user is able to re-categorize the features. Further, if the developed software is not as per the requirements of the user, the user may update/modify the input and the feature categories. The process is repeated or is run in the loop until all the desired features are incorporated into the software.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The visual programming level converts the requirements of the user into an intermediary visual representation for visual programming. The visual programming level provides visibility to design, model and UI. The user may make changes as per the requirements and the process may be repeated or may run in the loop until the developed software is as per the needs and requirements of the user.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The configuration programming level includes configuration of a plurality of parameters such as, but not limited to, programming language, persistence mechanism, UI libraries, database, deployment server port and IP address. The user may make changes as per the requirements and the process may be repeated or may run in the loop until the developed software is as per the needs and requirements of the user.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The low code programming level allows the user to make changes in the developed software. In an embodiment, the changes may be made with minimal coding. In an embodiment, the changes may include addition of custom libraries, packages and code. The user may make changes as per the requirements and the process may be repeated or run in the loop until the developed software is as per the needs and requirements of the user.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The Cloud Integration and Deployment (CICD) level provides for cloud configuration, integration with external systems, and deployment for operations. The user may make changes as per the requirements and the process may be repeated or may run in the loop until the developed software is as per the needs and requirements of the user.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>The computer-implemented system may further include an operation and maintenance level and an enhancement and redeployment level. 
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}>While the disclosure has been presented with respect to certain specific embodiments, it will be appreciated that many modifications and changes may be made by those skilled in the art without departing from the spirit and scope of the disclosure. It is intended, therefore, by the appended claims to cover all such modifications and changes as fall within the true spirit and scope of the disclosure.
// </p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
    

// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>


// <h4 style ={{ color: "blue", textAlign: "left" }}>AUTO PROGRAMMING</h4>
//     <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <h4 style ={{ color: "blue", textAlign: "left" }}>AUTO PROGRAMMING</h4>
//     <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <h4 style ={{ color: "blue", textAlign: "left" }}>AUTO PROGRAMMING</h4>
//     <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
// <p style={{fontSize: "medium", textAlign: "justify" }}></p>
    */}
     <div className="AssistedAutoProgramming" style={{marginTop:"-150px"}}>

     <h3 style ={{ color: "black", textAlign: "center" ,marginLeft:"15px"}}>Orbital</h3>
     <div className="container-xs">
        
        <a wideMobile href="https://platform.aiscipro.com/login">
          {" "}
          <button type="button"  style={{marginLeft:"260px",width:"130px",borderRadius:"25px",backgroundColor:"blue"}} >
            <b style={{fontSize:"15px",color:"white"}}>Get Started</b>
          </button>
          {" "}
        </a>
       

       
      </div>

<h4 style ={{ color: "black", textAlign: "center" }}>Assisted Auto Programming</h4>


<p style={{fontSize: "medium", textAlign: "justify" }}>In Assisted Auto Programming, the requirements document that is uploaded is categorized into various feature types. User may re-categorize based on their requirement if any categorization is incorrect or less appropriate. Post this assistance in categorization, the software is automatically built and available for preview. If the software auto developed is not as per the requirements, the user at this level can iterate again and update both requirements list as well as feature categories.
</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 2 Orbit :
</p>
<img style={{ "height": "350px","width":"350px"}} src={require('../../../../assets/images/Assistedauto1.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 2 Spin :
</p>
<img style={{ "height": "350px","width":"350px"}} src={require('../../../../assets/images/Assistedauto2.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>The invention is an online platform that automatically creates software in 3 steps.
</p><img style={{ "height": "150px","width":"550px"}} src={require('../../../../assets/images/Assistedauto3.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>A three step automation across all stages of software development such as requirements gathering, systems specifications, standardization, concept design & modelling, system & component architecture, coding & integration, quality assurance, build, deploy, operate, maintenance, & enhancement.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Software requirements are usually provided in document formats such as scope of work (SOW) or Request For Proposal (RFP) or Business Requirements Specification (BRS) or Requirements document etc.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the first step, the aiscipro online platform User can upload any of the above mentioned documents.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The document is processed with an artificial intelligence based natural language processor. In this step the identification of required software features is automated. The identified features are then categorized into four groups based on the complexity & effort needed for development of the features.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A total cost estimate is presented to the User based on the number of features and the complexity. The User can also view the detailed break up of the costs if so desired.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The User is offered to go ahead with automatic building (development or creation) of software or to drop out at this stage.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The second step is initiated when the User opts to go ahead and build the software. In this step, based on the features required, software is automatically designed, architected, built automatically with systems approach, domain driven design, normalized systems concepts, model driven development & automatic programming tools.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the software is built, a preview is provided to the User to check if all the features as expected by the User are available in the software created automatically on the platform.
User is further provided an option to drop out at this stage by discarding the software or pay for the software and download the software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Third step is initiated on opting to download the software. In this step the User is redirected to the payments gateway page and on successful payment the user is provided with the software  (including the source code, design document, architecture, user manual, installation guide, quality assurance reports,   etc).

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The platform may also offer the user to host the auto built software online at additional charge.
Thus in 3 steps a user can have a software developed for their requirements.
</p>
</div>
<div className="VisualLowcodeProgramming" style={{marginTop:"-210px"}}>
    <h4 style ={{ color: "black", textAlign: "center" }}>Visual No-code Programming</h4>
     <p style={{fontSize: "medium", textAlign: "justify" }}>At this level, the requirements are converted into an intermediary visual representation for visual programming. This level provides visibility to design, model, & UI (user interface). The user may make suitable changes as per the desired software. Then, once confirmed, the auto developed software may be previewed on the aiscipro platform.
</p>
     <img style={{ "height": "450px","width":"480px"}} src={require('../../../../assets/images/Visualno1.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 3 Orbit :</p>
<img style={{ "height": "300px","width":"400px"}} src={require('../../../../assets/images/Visualno2.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>The invention is an online platform that automatically creates software in 5 steps.
</p><p style={{fontSize: "medium", textAlign: "left" }}>Level 3 Spin:</p>
<img style={{ "height": "200px","width":"850px"}} src={require('../../../../assets/images/Visualno3.png')}class="center" alt="..."/>
 <p style={{fontSize: "medium", textAlign: "justify" }}>A five step automation across all stages of software development such as requirements gathering, systems specifications, standardization, concept design, modeling system & component architecture, coding & integration, quality assurance, build, deploy, operate, maintenance, enhancement and further redeployment, operations and maintenance .
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>Software requirements are usually provided in document formats such as scope of work (SOW) or Request For Proposal (RFP) or Business Requirements Specification (BRS) or Requirements document etc.

</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>In the first step, the aiscipro online platform User can upload any of the above-mentioned documents or provide requirements inputs directly by typing or entering the features into the aiscipro system .
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>The document is processed with an artificial intelligence based natural language processor. In this step the identification of required software features is automated. The identified features are then categorized into four groups based on the complexity & effort needed for development of the features.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>
In the second configuration the user can select and configure the system parameters such as programming language ,database operating system and any other desired tools and frame works .
In the third step system level software architecture and code level detailed design could be customized on the automatically generated system design.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>Once the customized design is resubmitted then ,based on the features, an estimate is provided to the aiscipro platform user.</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>A total cost estimate is presented to the User based on the number of features and the complexity. The User can also view the detailed break up of the costs if so desired.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>The User is offered to go ahead with automatic building (development or creation) of software or to drop out at this stage.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>The fourth step is initiated when the User opts to go ahead and build the software. In this step, based on the features required, software is automatically designed, architected, built automatically with systems approach, domain driven design, normalized systems concepts, model driven development & automatic programming tools.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>Once the software is built, a preview is provided to the User to check if all the features as expected by the User are available in the software created automatically on the platform.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>Users are further provided an option to drop out at this stage by discarding the software or pay for the software and download the software.

</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>Fifth step is initiated on opting to download the software. In this step the User is redirected to the payments gateway page and on successful payment the user is provided with the software (including the source code, design document, architecture, user manual, installation guide, quality assurance reports,   etc). 
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>The platform may also offer the user to host the auto built software online at additional charge.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}>Thus in 5 steps a user can have a software developed for their requirements.
</p>
 <p style={{fontSize: "medium", textAlign: "justify" }}></p>
 <p style={{fontSize: "medium", textAlign: "justify" }}></p>
     </div>
     <div className="VisualLowcodeProgramming" style={{marginTop:"-200px"}}>
   <h4  style ={{ color: "black", textAlign: "center" }}>Visual Low-code Programming</h4>
    <p style={{fontSize: "medium", textAlign: "justify" }}>At this level, the user can add custom libraries, packages, code etc to the auto developed software. An integrated development environment (IDE) like environment is provided for the users to include the custom code or features with minimal coding.
</p>
<img style={{ "height": "550px","width":"580px"}} src={require('../../../../assets/images/Visuallowimage.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 5 Orbit :</p>
<img style={{ "height": "400px","width":"500px"}} src={require('../../../../assets/images/Visuallow2.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>The invention is an online platform that automatically creates software in 9 steps.
</p><p style={{fontSize: "medium", textAlign: "left" }}>Level 5 Spin:</p>
<img style={{ "height": "200px","width":"850px"}} src={require('../../../../assets/images/Visuallow3.png')}class="center" alt="..."/>



<p style={{fontSize: "medium", textAlign: "justify" }}>A nine step automation across all stages of software development such as requirements gathering, systems specifications, standardization, concept design, modelling system & component architecture, coding & integration, quality assurance, build, deploy, operate, maintenance, enhancement and further redeployment, operations and maintenance .
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Software requirements are usually provided in document formats such as scope of work (SOW) or Request For Proposal (RFP) or Business Requirements Specification (BRS) or Requirements document etc.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the first step, the aiscipro online platform User can upload any of the above-mentioned documents or provide requirements inputs directly by typing or entering the features into the aiscipro system .</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The document is processed with an artificial intelligence based natural language processor. In this step the identification of required software features is automated. The identified features are then categorized into four groups based on the complexity & effort needed for development of the features.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the second configuration the user can select and configure the system parameters such as programming language, database operating system and any other desired tools and frameworks .
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the third step system level software architecture and code level detailed design could be customized on the automatically generated system design.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the customized design is resubmitted then, based on the features, an estimate is provided to the aiscipro platform user. 
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A total cost estimate is presented to the User based on the number of features and the complexity. The User can also view the detailed break up of the costs if so desired.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
The User is offered to go ahead with automatic building (development or creation) of software or to drop out at this stage.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The fourth step is initiated when the User opts to go ahead and build the software. In this step, based on the features required, software is automatically designed, architected, built automatically with systems approach, domain driven design, normalized systems concepts, model driven development & automatic programming tools.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the software is built, a preview is provided to the User to check if all the features as expected by the User are available in the software created automatically on the platform.
Fifth step to preview the auto generated software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The sixth step is provided to the user, in case the user wants any changes or customizations required, of the developed code and which will also be provisioned to the user for manual change.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The seventh step is initiated to deploy on opting to download the software. In this step the User is redirected to the payments gateway page and on successful payment the user is provided with the software (including the source code, design document, architecture, user manual, installation guide, quality assurance reports, etc.). 

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The platform may also offer the user to host the auto-built software online at additional charge.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Users are further provided an option to drop out at this stage by discarding the software or pay for the software and download the software.

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the eighth step after super admin view, if the user wants to enhance the code post deployment can enhance this option and build accordingly.

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the ninth step after re-enhancing the design as per the user requirement they can re-deploy and download the application.

</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Thus in 9 steps a user can have a software developed and can download the software .
</p>


    </div>
    <div className="ConfigurationProgramming" style={{marginTop:"-210px"}}>
  
  <h4 style ={{ color: "black", textAlign: "center" }}>  Configuration Programming</h4>
  <p style={{fontSize: "medium", textAlign: "justify" }}>In this orbit, the user can configure the system and architectural components such as      programming language, persistence mechanism, UI libraries, etc.
</p>
  <img style={{ "height": "400px","width":"500px"}} src={require('../../../../assets/images/Config1.jpg')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 4 Orbit :</p>
<img style={{ "height": "300px","width":"400px"}} src={require('../../../../assets/images/Config2.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>The invention is an online platform that automatically creates software in 7 steps.

</p><p style={{fontSize: "medium", textAlign: "left" }}>Level 4 Spin:</p>
<img style={{ "height": "200px","width":"850px"}} src={require('../../../../assets/images/Config3.png')}class="center" alt="..."/>
<p style={{fontSize: "medium", textAlign: "justify" }}>A seven step automation across all stages of software development such as requirements gathering, systems specifications, standardization, concept design, modelling system & component architecture, coding & integration, quality assurance, build, deploy, operate, maintenance, enhancement and further redeployment, operations and maintenance.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Software requirements are usually provided in document formats such as scope of work (SOW) or Request For Proposal (RFP) or Business Requirements Specification (BRS) or Requirements document etc.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>
In the first step, the aiscipro online platform User can upload any of the above-mentioned documents or provide requirements inputs directly by typing or entering the features into the aiscipro system .
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The document is processed with an artificial intelligence based natural language processor. In this step the identification of required software features is automated. The identified features are then categorized into four groups based on the complexity & effort needed for development of the features.</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the second configuration the user can select and configure the system parameters such as programming language, database operating system and any other desired tools and frame works .
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>In the third step system level software architecture and code level detailed design could be customized on the automatically generated system design.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the customized design is resubmitted then, based on the features, an estimate is provided to the aiscipro platform user. </p>
<p style={{fontSize: "medium", textAlign: "justify" }}>A total cost estimate is presented to the User based on the number of features and the complexity. The User can also view the detailed break up of the costs if so desired.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The User is offered to go ahead with automatic building (development or creation) of software or to drop out at this stage.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The fourth step is initiated when the User opts to go ahead and build the software. In this step, based on the features required, software is automatically designed, architected, built automatically with systems approach, domain driven design, normalized systems concepts, model driven development & automatic programming tools.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Once the software is built, a preview is provided to the User to check if all the features as expected by the User are available in the software created automatically on the platform.
Fifth step to preview the auto generated software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The sixth step is provided to the user, in case the user wants any changes or customizations required, of the developed code and which will also be provisioned to the user for manual change.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The seventh step is initiated to deploy on opting to download the software. In this step the User is redirected to the payments gateway page and on successful payment the user is provided with the software (including the source code, design document, architecture, user manual, installation guide, quality assurance reports, etc.). 
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>The platform may also offer the user to host the auto-built software online at additional charge.
Users are further provided an option to drop out at this stage by discarding the software or pay for the software and download the software.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}>Thus in 7 steps a user can have a software developed for their requirements.
</p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
  </div>
  <div className=" CICDProgramming" style={{marginTop:"-210px"}}>
   
    <h4 style ={{ color: "black", textAlign: "center" }}> CI CD Programming</h4>
    <p style={{fontSize: "medium", textAlign: "justify" }}>This level provides for cloud configuration, integration with external systems, and deployment for operations.
</p>
<p style={{fontSize: "medium", textAlign: "left" }}>Level 6 Orbit .:</p>
    <img style={{ "height": "400px","width":"500px"}} src={require('../../../../assets/images/Visuallowimage.jpg')}class="center" alt="..."/>
    <p style={{fontSize: "medium", textAlign: "left" }}>Level 6 Spin:</p>
<img style={{ "height": "300px","width":"350px"}} src={require('../../../../assets/images/Visuallow2.png')}class="center" alt="..."/>



<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
<p style={{fontSize: "medium", textAlign: "justify" }}></p>
    </div>


    <div className="container-xs">
        
        <a wideMobile href="https://platform.aiscipro.com/login">
          {" "}
          <button type="button"  style={{marginLeft:"260px",width:"130px",borderRadius:"25px",backgroundColor:"blue"}} >
            <b style={{fontSize:"15px",color:"white"}}>Get Started</b>
          </button>
          {" "}
        </a>
       

       
      </div>
     </div>
   
  )
}

export default Orbital