import React from 'react'

function Downloads() {
  return (
    <div className="title-pages">
     <h3>Downloads</h3> 
      </div>
  )
}

export default Downloads