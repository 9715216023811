import React from 'react'

function Blog15() {
  return (
    <div className='blog'>
        {/* <p style ={{ color: "blue", textAlign: "center" }}>Why is the term “architecture” such a confounding word in the software industry?... </p> */}
        <p style={{fontSize: "medium", textAlign: "left" }}>The “architecture” of a software system is one of the most confounding terms in the software
industry. The term architecture evokes an organized diagram of component layout in the mind of
most. This image is borrowed from the Civil Engineering field where it has significantly
contributed for clarity on structural aspects.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>However, Software Engineering is quite different from Civil or Mechanical or other fields of
engineering in quite a few fundamental ways. We will dwell on these fundamental differences in
a separate blog. Here we will focus on the titled topic.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>The term architecture in Software Engineering context is not understood with adequate clarity.
The word can easily mislead to be intelligible however most of the times it is not understood
adequately enough. Even experts in the field have misapplied it in many contexts. Exacerbating
the situation is the authenticity it has received over historical use.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>The word architecture has lost its significance due to its overuse and misuse. The irreverent use
of the term has made, an important conceptual word “architecture”, lose its relevancy and has
turned it into a meaningless ignorable jargon.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>In the software industry, “architecture” could mean one or more of the following
● allocation of functionality across subsystems
● source code distribution in packages and their interaction
● component deployed in different systems and the communication protocol between them
● just a block diagram of a system with parts identified as blocks</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>Nevertheless, Architecture is important in any software as an ideal architecture can make the
system more suitable, better adaptable for future changes, and easier to understand &amp;
communicate.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>In aiscipro, we believe that the confusion around the term architecture in software is more due
to its great significance across the software system both at the vertical as well as horizontal
levels.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>Furthermore, one of the primary causes for complexity in software is due to lack of clarity in the
various architectural aspects observed at these different levels. Human mind is not good at
holding or visualizing multiple abstraction levels at the same time. We cannot think or imagine in
parallel.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>We attempt to bring clarity by disentangling the architectures at the horizontal &amp; vertical levels.
At aiscipro, we recognize three dimensions of software architecture in horizontal level viz,</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>
1. Source Code Architecture
2. Deployment Architecture
3. Service Architecture
</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>Source Code Architecture: The organization of the source code into various components such
as classes, modules, packages, etc. with the dependencies, both source code dependencies as
well as execution flow dependencies. For instance, dependencies between source code
modules can lead to changes in one module forcing changes or recompilation of others. Such
decisions are again design decisions leading to an architecture.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>Deployment Architecture: One module or component may run on a server while another may
run on a different server or client. Such decisions form deployment design leading to
deployment architecture. Typically, the deployable components are packed as DLLs, Jar files,
etc.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>Service level: We can break down the entire system as a group of services that communicate
control and data through packets. In this architecture, the organization of the system is into
service lines. Each component provides a service irrespective of where they are deployed and
what source code design they follow.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>For example, below are various combinations of architecture at Source Code, Deployment, &amp;
Service levels conventionally seen in software applications.</p>

<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog15d1.png')}class="center" alt="..."/>

<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog15d3.png')}class="center" alt="..."/>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog15d3.png')}class="center" alt="..."/>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog15d4.png')}class="center" alt="..."/>
<img style={{ "height": "350px","width":"535px"}} src={require('../Blogimages/blog15d5.png')}class="center" alt="..."/>












        <p style={{fontSize: "medium", textAlign: "left" }}>The above shown architectures are all at horizontal levels. The same patterns of architecture
can repeat vertically as a high level architecture, component level architecture, low level
architecture, detailed skeletal architecture and so on.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}>So the next time somebody says “architecture”, the first thing that we need to clarify is the type
of architecture (horizontal) and the granularity (vertical) at which it is being spoken. Once we
clarify the context and qualify the design, it will lead to a quicker understanding of the point,
better appreciation of the design under discussion and a much richer interaction vocabulary.</p>
        <p style={{fontSize: "medium", textAlign: "left" }}></p>
    </div>
  )
}

export default Blog15;