import React from 'react'

function Pricing() {
  return (
    <>
 <div className="title-pages">
 
   <h3>Pricing</h3>
</div>

    </>
  )
}

export default Pricing